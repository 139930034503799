// import pages
import * as Sentry from '@sentry/browser';
import React from 'react';
import { createRoot } from 'react-dom/client';

import '../sass/style.scss';

import App from './App';

Sentry.init({
  dsn: window.SENTRY_DSN,
  release: window.COMMIT_SHA,
  environment: window.SENTRY_ENVIRONMENT
});

const root = createRoot(document.getElementById('app'));
root.render(<App />);
