import React from 'react';
import styled from 'styled-components';
import breakpoints from '../../utils/breakpoints';
import Picker from '../Picker';
import { getCanvasDimensions } from '../../utils/loadFromCanvas';
import { 
  MAX_GRID_COLUMNS,
  MAX_GRID_ROWS,
  ADMIN_GRID_COLUMNS,
  ADMIN_GRID_ROWS
} from '../../../../js/constants';

const LoadSetup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 1.5rem;
  padding: 1rem 0;
  padding-top: 0;
  margin-top: 0;
`;

const PickerWrapper = styled.div`
  padding: 0 0.5rem;
  padding-top: 0.5rem;
  margin: 0 auto 0 auto;
  width: 100%;
  max-width: 400px;
`;

const PickerTitle = styled.h2`
  display: block;
  text-align: center;
  margin: 0;
  font-size: 1.1rem;
  top: 0;
  padding: 0 0 0.5rem 0;
  color: whitesmoke;
`;

const PickerInfoIcon = styled.i`
  position: relative;
  background-color: #2f5382;
  color: white;
  border-radius: 9999px;
  top: 3px;
  padding: 0.2rem;
  margin-left: 0.4rem;
`;

const getImageDimensions = (canvasDimensions, pSize, frameAmount) => {
  const pixelsWidth = Math.round((canvasDimensions.w / pSize) * 100) / 100;
  const pixelsHeight =
    Math.round((canvasDimensions.h / pSize / frameAmount) * 100) / 100;
  return {
    original: { w: canvasDimensions.w, h: canvasDimensions.h },
    result: { w: pixelsWidth, h: pixelsHeight }
  };
};

const ImageSetupSection = ({
  canvasRef,
  frameCount,
  setFrameCount,
  numColors,
  setNumColors,
  pixelSize,
  setPixelSize,
  setResultDimensions,
  imgSetupValidation,
  disableDimensions,
  rows,
  setRows,
  setColumns,
  columns,
  numColorsPickerDisabled,
  isStaff
}) => {
  const getPickerAction = (property, setProperty) => (type, behaviour) => {
    const newPickerCount = property.value + behaviour;

    if (property.id === 'numColors') {
      return setProperty(newPickerCount);
    }
    if (property.id === 'rows') {
      return setRows(newPickerCount);
    }
    if (property.id === 'columns') {
      return setColumns(newPickerCount);
    }

    const pixelValue = newPickerCount;
    const frameValue = frameCount;
    setProperty(newPickerCount);
    setResultDimensions(
      getImageDimensions(getCanvasDimensions(canvasRef), pixelValue, frameValue)
        .result
    );
    imgSetupValidation(getCanvasDimensions(canvasRef), pixelValue, frameValue);
  };

  const numColorsPickerAction = getPickerAction(
    { value: numColors, id: 'numColors' },
    setNumColors
  );
  const pixelSizePickerAction = getPickerAction(
    { value: pixelSize, id: 'pixel' },
    setPixelSize
  );
  const rowsPickerAction = getPickerAction(
    { value: rows, id: 'rows' },
    setRows
  );
  const columnsPickerAction = getPickerAction(
    { value: columns, id: 'columns' },
    setColumns
  );

  return (
    <LoadSetup>
      {!numColorsPickerDisabled && (
        <PickerWrapper>
        <PickerTitle>
          Number of Colors
          <span data-tooltip="The number of colors you'd like your embroidery design to have">
            <PickerInfoIcon className="icon-help" />
          </span>
        </PickerTitle>
        <Picker
          type="colors-count"
          value={numColors}
          action={numColorsPickerAction}
          max={12}
          min={1}
          disabled={numColorsPickerDisabled}
        />
      </PickerWrapper>
      )}      
      
      {!disableDimensions && (
      <>
      <PickerWrapper>
        <PickerTitle>
          Rows
          <span data-tooltip="Adjust the number of rows if your pixel art does not align with grid.">
            <PickerInfoIcon className="icon-help" />
          </span>
        </PickerTitle>
        <Picker
          type="rows"
          value={rows}
          action={rowsPickerAction}
          max={isStaff ? ADMIN_GRID_ROWS : MAX_GRID_ROWS}
          min={8}
        />
      </PickerWrapper>
      <PickerWrapper>
        <PickerTitle>
          Columns
          <span data-tooltip="Adjust the number of columns if your pixel art does not align with grid.">
            <PickerInfoIcon className="icon-help" />
          </span>
        </PickerTitle>
        <Picker
          type="columns"
          value={columns}
          action={columnsPickerAction}
          max={isStaff ? ADMIN_GRID_COLUMNS : MAX_GRID_COLUMNS}
          min={8}
        /> 
      </PickerWrapper>
      </>
      )}
    </LoadSetup>
  );
};

export default ImageSetupSection;
