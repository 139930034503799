import React, {useEffect} from 'react';
import { Provider } from 'react-redux';
import App from './App';

const Root = ({ store, dispatch }) => {

    useEffect(() => {
        document.title = "PixelStitch - Create"
     }, []);

    return(
        <App store={store} dispatch={dispatch} />
    );
}


export default Root;
