// Basic header component with navigation links
import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import logoHeader from '../../../assets/images/logo_header.svg'

const Header = () => (
    <Navbar bg="light" expand="lg" className='px-3'>
        <Navbar.Brand as={Link} to="/" className='main-header'>
            <img src={logoHeader} alt="PixelStitch Logo" className='logo-header' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
                <Nav.Link as={Link} to="/create">
                    Create
                </Nav.Link>
                <Nav.Link as={Link} to="/featured">
                    Featured
                </Nav.Link>
                <Nav.Link href='/#how-it-works'>
                    How it works
                </Nav.Link>
                <Nav.Link href='/#about'>
                    About
                </Nav.Link>
                <Nav.Link as={Link} to="/faq">
                    FAQs
                </Nav.Link>
                <Nav.Link href='/contact'>
                    Contact
                </Nav.Link>
                <Nav.Link className="snipcart-checkout snipcart-summary" to="/cart">
                    Cart
                </Nav.Link>
            </Nav>
        </Navbar.Collapse>
    </Navbar>
);

export default Header;