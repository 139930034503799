import React from 'react';
import ZoomHoverImg from './ZoomHoverImg';

const FeatureCard = ({imgSrc, title, desc}) => {
    // is this a mobile device?
    const isMobile = window.innerWidth < 900;

    return (
        <div className="card shadow-sm">
            <div className="position-relative">
                {isMobile ?
                (
                    <img 
                    className="card-img-top"
                    alt="featured product"
                    src={imgSrc} />
                )
                :
                (<ZoomHoverImg imgSource={imgSrc} />)
                }
                 
            </div>

            <div className="card-body flex flex-column">
                <h5 className="card-title">{title}</h5>
                <p className="card-text mb-1">{desc}</p>
            </div>
        </div>
    );
};

export default FeatureCard;
