import React, {useState, useEffect} from 'react';
import ProductInfoDialog from './ProductInfoDialog';
import ChoiceChips from './ChoiceChips';
import ZoomHoverImg from './ZoomHoverImg';
import { useNavigate } from 'react-router-dom';

const ProductCard = ({
    product,
    columns,
    rows,
    designId,
    numberOfColors,
    queryParams,
    enableDoubleOptions,
    borderColor,
    previewDataUrl,
    previewDataUrlDouble,
    addEditButton,
    setDrawing,
    frames
}) => {
    // if there is a sku and it is not part of this product, return
    if (queryParams.highlight === 'true' && queryParams.sku && ! product.colors.some((color) => color.skus.some((sku) => sku.id == queryParams.sku))) {
        return null;
    }
    const [loading, setLoading] = useState(true);
    const [copied, setCopied] = useState(false);
    const stitchSizes = enableDoubleOptions ? [5, 4, 3, 2.5, 2, 1.5] : [2.5, 2, 1.5];
    const [stitchSize, setStitchSize] = useState(null);
    const useDoublePreview = stitchSize > 2.5;
    const [selectedColorIndex, setSelectedColorIndex] = useState(0);
    const [selectedSize, setSelectedSize] = useState(product.colors[0].skus[0].size);
    const [selectedPrice, setSelectedPrice] = useState(product.colors[0].skus[0].price);
    const [selectedSku, setSelectedSku] = useState(product.colors[0].skus[0].id);
    // is this a product with positions?
    const hasPositions = product.slug === 'hoodie' || product.slug === 'crewneck';
    const [selectedPosition, setSelectedPosition] = useState(hasPositions ? 'Left' : null); // ['left', 'center']
    const positions = hasPositions ? ['Left', 'Center'] : null;
    const svgClass = selectedPosition === 'Center' ? `${
        product.slug
    }-center-svg` : `${
        product.slug
    }-svg`;
    const [viewSizingModal, setViewSizingModal] = useState(false);
    const hasProductInfo = product.sizing_image_url || product.colors[selectedColorIndex].extra_images.length > 0;
    const navigate = useNavigate();

    const tooBig = (size, axis) => {
        return product.max_width_percentage < designPercentage(product.px_per_mm, size, axis, product.img_width);
    }

    useEffect(() => {
        if (queryParams.sku) {
            setSelectedSku(queryParams.sku);
            // go through each color and see if the sku is part of that color, then set color
            for (const [index, color] of product.colors.entries()) {
                if (color.skus.some((sku) => sku.id == queryParams.sku)) {
                    setSelectedColorIndex(index);
                    break;
                }
            }
            // go through each size and see if the sku is part of that size, then set size
            for (const color of product.colors) {
                if (color.skus.some((sku) => sku.id == queryParams.sku)) {
                    setSelectedSize(color.skus.find((sku) => sku.id == queryParams.sku).size);
                    break;
                }
            }
        }
        if (queryParams.size && stitchSizes.includes(parseFloat(queryParams.size))) {
            const size = parseFloat(queryParams.size);
            if (!tooBig(size, columns) && !tooBig(size, rows)) {
                setStitchSize(size);
            }
        }
        if (queryParams.position && positions?.includes(queryParams.position)){
            setSelectedPosition(queryParams.position);
        }
    }, []);
    
    const copyToClipboard = () => {
        window.gtag("event", "copied_share_link", {
            event_category: "engagement",
            event_label: `share_link`,
          });

        const link = location.origin + `/embroider/${designId}?sku=${selectedSku}&s=${stitchSize}&p=${selectedPosition ? selectedPosition : ''}&h=true`
       

        // use the web share api
        if (navigator.share) {
            navigator.share({
                title: 'PixelStitch',
                text: 'Check out this custom embroidery!',
                url: location.origin + `/embroider/${designId}?sku=${selectedSku}&s=${stitchSize}&p=${selectedPosition ? selectedPosition : ''}&h=true`
            })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
        }
        else{
            try{
                navigator.clipboard.writeText(link);
            }
            catch(err){
                console.log(link, err);
            }
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 2000);
        }
    }

    const sendGoogleEvent = (selectedSku) => {
        window.gtag("event", "added_item_to_cart", {
            event_category: "engagement",
            event_label: `SKU ${selectedSku}`,
          });
    }

    // is this a mobile device?
    const isMobile = window.innerWidth < 900;

    const mmToInches = (mmPerCrossStitch, crossStitches) => {
        const inches = (mmPerCrossStitch * crossStitches) / 25.4;
        return inches.toFixed(2) + '"';
    }

    const handleColorChange = (index) => {
        setSelectedColorIndex(index);
        // try to keep the same size selected, unless there is no stock
        if (product.colors[index].skus.some((sku) => sku.size === selectedSize && sku.in_stock)) {
            setSelectedSize(selectedSize);
        } else {
            setSelectedSize(product.colors[index].skus[0].size);
        }
        setSelectedPrice(product.colors[index].skus[0].price);
        setSelectedSku(product.colors[index].skus[0].id);
    }

    const editDesign = () => {
        window.gtag("event", "edit_design", {
            event_category: "engagement",
            event_label: `design_id_${designId}`,
            });
            
        setDrawing(frames, columns, rows);
        navigate('/create');
    }

    function designPercentage(pixelsPerMm, mmPerCrossStitch, crossStitches, hatWidthPx = 1100) {
        const designWidthPx = pixelsPerMm * mmPerCrossStitch * crossStitches;
        const percentage = (designWidthPx / hatWidthPx) * 100;
        return percentage;
    }

    // go through mm sizes from largest to smallest, and use the first one that fits
    if (!stitchSize) {
        for (const size of stitchSizes) {
            if (!tooBig(size, columns) && !tooBig(size, rows)) {
                if (stitchSize !== size) {
                    setStitchSize(size);
                }
                break;
            }
        }
    }
    


    // if the selected size is not in stock, select the first size that is
    if (!product.colors[selectedColorIndex].skus.some((sku) => sku.size === selectedSize && sku.in_stock)) {
        setSelectedSize(product.colors[selectedColorIndex].skus.find((sku) => sku.in_stock).size);
    }

    // Example
    const widthPercentage = designPercentage(product.px_per_mm, stitchSize, columns, product.img_width);

    const areThereSizes = product.colors[selectedColorIndex].skus.length > 1;

    const imgPreview = (
        <img src={previewDataUrl}
        className={`${svgClass} emb-drop-shadow`}
        style={{
            width: `${widthPercentage}%`
        }}
        onLoad={() => setLoading(false)}
        />
    )

    const imgDoublePreview = (
        <img src={previewDataUrlDouble}
        className={`${svgClass} emb-drop-shadow`}
        style={{
            width: `${widthPercentage}%`
        }}
        onLoad={() => setLoading(false)}
        />
    )

    const spinner = (
        <div className={`simple-spinner-card display`}>
            <div className="circle" />
        </div>
    );

    return (<div className={
        `card product-card ${
            !stitchSize && 'product-disabled'
        }`
    }>
        <div className="position-relative"> {
            isMobile ? (<><img className="card-img-top img-border-radius"
                    alt={
                        `${
                            product.product_name
                        } - ${
                            product.colors[selectedColorIndex].color_name
                        }`
                    }
                    src={
                        product.colors[selectedColorIndex].image_url
                    }/>
                    {loading && spinner}
                    {useDoublePreview ? imgDoublePreview : imgPreview}
                
                </>) :
            (<ZoomHoverImg imgSource={
                    product.colors[selectedColorIndex].image_url
                }
                imgPreview={
                    (
                        <>
                        {loading && spinner}
                        {useDoublePreview ? imgDoublePreview : imgPreview}
                        </>
                    )
                }/>)
        }
        {hasProductInfo && (
            <button className='sizing-button btn btn-secondary' onClick={() => setViewSizingModal(true)}>
                <span>Product Info</span>
                {/* <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 64 64"><path d="M2.96,45.48l10.15,12.37l47.93-39.33L50.89,6.15L2.96,45.48z M58.22,18.24l-44.83,36.8l-7.61-9.28l1.53-1.26l3.81,4.64  l1.55-1.27l-3.81-4.64l1.46-1.2l1.59,1.93l1.55-1.27l-1.59-1.93l2.13-1.75l3.81,4.64l1.55-1.27l-3.81-4.64l1.46-1.2l1.59,1.93  l1.55-1.27l-1.59-1.93l1.15-0.94l3.81,4.64l1.55-1.27l-3.81-4.64l1.46-1.2l1.59,1.93l1.55-1.27l-1.59-1.93l1.69-1.38l3.81,4.64  l1.55-1.27l-3.81-4.64l1.46-1.2l1.59,1.93l1.55-1.27l-1.59-1.93l1.47-1.21l3.81,4.64l1.55-1.27l-3.81-4.64l1.46-1.19l1.59,1.93  l1.55-1.27l-1.59-1.93l1.61-1.32l3.81,4.64l1.55-1.27l-3.81-4.64l1.46-1.2l1.59,1.93l1.55-1.27l-1.59-1.93l1.78-1.46l3.81,4.64  l1.55-1.27l-3.81-4.64l1.46-1.19l1.59,1.93l1.55-1.27l-1.59-1.93l1.64-1.35L58.22,18.24z"/></svg> */}
            </button>
        )}
        {addEditButton && (
            <button className='edit-button btn btn-secondary' onClick={() => editDesign()}>
                <span>Edit <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 32 32" x="0px" y="0px"><title>96</title><path d="M11,6a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V5A1,1,0,0,0,11,6Z"/><path d="M16.71,6.71l2-2a1,1,0,1,0-1.42-1.42l-2,2a1,1,0,0,0,1.42,1.42Z"/><path d="M5.29,15.29l-2,2a1,1,0,0,0,1.42,1.42l2-2a1,1,0,0,0-1.42-1.42Z"/><path d="M5,12a1,1,0,0,0,0-2H3a1,1,0,0,0,0,2Z"/><path d="M5.71,4.29A1,1,0,0,0,4.29,5.71l2,2A1,1,0,0,0,7.71,6.29Z"/><path d="M29.71,24.29,14.41,9a2,2,0,0,0-2.82,0L9,11.59a2,2,0,0,0,0,2.82l15.29,15.3a1,1,0,0,0,1.42,0l4-4A1,1,0,0,0,29.71,24.29ZM13,10.41,16.59,14,14,16.59,10.41,13ZM25,27.59,15.41,18,18,15.41,27.59,25Z"/></svg></span>
            </button>
        )}
        </div>

        <div className="card-body flex flex-column">
            <h5 className="card-title"> {
                product.product_name
            }
                {
                    product.description && (
                        <span className="card-text card-description mb-1">- {product.description}</span>
                    )
                }                
            </h5>
            {/* Radio buttons that switch between fill and cross-stitch */}
            <form className='pb-2'>
                <div className="form-check form-check-inline product-radio">
                    <input className="form-check-input" type="radio" name="stitchSize"
                        id={
                            `${
                                product.product_name
                            }-1.5-${designId}`
                        }
                        value={1.5}
                        checked={
                            stitchSize === 1.5
                        }
                        disabled={
                            tooBig(1.5, columns) || tooBig(1.5, rows)
                        }
                        onChange={
                            () => setStitchSize(1.5)
                        }/>
                    <label className="form-check-label"
                        htmlFor={
                            `${
                                product.product_name
                            }-1.5-${designId}`
                    }> {
                        mmToInches(1.5, columns)
                    }</label>
                </div>
                <div className="form-check form-check-inline product-radio">
                    <input className="form-check-input" type="radio" name="stitchSize"
                        id={
                            `${
                                product.product_name
                            }-2-${designId}`
                        }
                        value={2}
                        checked={
                            stitchSize === 2
                        }
                        disabled={
                            tooBig(2, columns) || tooBig(2, rows)
                        }
                        onChange={
                            () => setStitchSize(2)
                        }/>
                    <label className="form-check-label"
                        htmlFor={
                            `${
                                product.product_name
                            }-2-${designId}`
                    }> {
                        mmToInches(2, columns)
                    }</label>
                </div>
                <div className="form-check form-check-inline product-radio">
                    <input className="form-check-input" type="radio" name="stitchSize"
                        id={
                            `${
                                product.product_name
                            }-2.5-${designId}`
                        }
                        value={2.5}
                        checked={
                            stitchSize === 2.5
                        }
                        disabled={
                            tooBig(2.5, columns) || tooBig(2.5, rows)
                        }
                        onChange={
                            () => setStitchSize(2.5)
                        }/>
                    <label className="form-check-label"
                        htmlFor={
                            `${
                                product.product_name
                            }-2.5-${designId}`
                    }> {
                        mmToInches(2.5, columns)
                    }</label>
                </div>
                {
                    previewDataUrlDouble && (
                        <>
                         <div className="form-check form-check-inline product-radio">
                            <input className="form-check-input" type="radio" name="stitchSize"
                                id={
                                    `${
                                        product.product_name
                                    }-1.5-2x-${designId}`
                                }
                                value={4}
                                checked={
                                    stitchSize === 3
                                }
                                disabled={
                                    tooBig(1.5, columns*2) || tooBig(1.5, rows*2)
                                }
                                onChange={
                                    () => setStitchSize(3)
                                }/>
                            <label className="form-check-label"
                                htmlFor={
                                    `${
                                        product.product_name
                                    }-1.5-2x-${designId}`
                            }> {
                                mmToInches(1.5, columns*2)
                            } (2x)</label>
                        </div>
                        <div className="form-check form-check-inline product-radio">
                            <input className="form-check-input" type="radio" name="stitchSize"
                                id={
                                    `${
                                        product.product_name
                                    }-2-2x-${designId}`
                                }
                                value={4}
                                checked={
                                    stitchSize === 4
                                }
                                disabled={
                                    tooBig(2, columns*2) || tooBig(2, rows*2)
                                }
                                onChange={
                                    () => setStitchSize(4)
                                }/>
                            <label className="form-check-label"
                                htmlFor={
                                    `${
                                        product.product_name
                                    }-2-2x-${designId}`
                            }> {
                                mmToInches(2, columns*2)
                            } (2x)</label>
                        </div>
                        <div className="form-check form-check-inline product-radio">
                            <input className="form-check-input" type="radio" name="stitchSize"
                                id={
                                    `${
                                        product.product_name
                                    }-2.5-2x-${designId}`
                                }
                                value={5}
                                checked={
                                    stitchSize === 5
                                }
                                disabled={
                                    tooBig(2.5, columns*2) || tooBig(2.5, rows*2)
                                }
                                onChange={
                                    () => setStitchSize(5)
                                }/>
                            <label className="form-check-label"
                                htmlFor={
                                    `${
                                        product.product_name
                                    }-2.5-2x-${designId}`
                            }> {
                                mmToInches(2.5, columns*2)
                            } (2x)</label>
                        </div>
                        </>
                    )

                }
            </form>

            {/* if this has positions, show radio options for each position */}
            {
            positions && (<form className='pb-2'> {
                positions.map((position) => (<div className="form-check form-check-inline product-radio">
                    <input className="form-check-input" type="radio" name="position"
                        id={
                            `${
                                product.product_name
                            }-${position}-${designId}`
                        }
                        value={position}
                        checked={
                            selectedPosition === position
                        }
                        onChange={
                            () => setSelectedPosition(position)
                        }/>
                    <label className="form-check-label"
                        htmlFor={
                            `${
                                product.product_name
                            }-${position}-${designId}`
                    }> {position}</label>
                </div>))
            } </form>)
        }
            {/* Color Selection */}
            <div> {
                product.colors.map((color, index) => (<span key={index}
                    style={
                        {
                            display: 'inline-block',
                            width: '30px',
                            height: '30px',
                            background: color.hex_code,
                            borderRadius: '50%',
                            margin: '8px',
                            cursor: 'pointer',
                            transition: 'all 0.3s',
                            transform: selectedColorIndex === index ? 'scale(1.2)' : '',
                            boxShadow: selectedColorIndex === index ? '0 0 0 3px #007BFF' : ''
                        }
                    }
                    onClick={
                        () => handleColorChange(index)
                }></span>))
            } </div>
            {/* Size Selection */}
            <div className="mb-3"> {
                areThereSizes && (<ChoiceChips selectedChoice={selectedSize}
                    // set the selected size and associated sku and price
                    onChoiceChange={
                        (size) => {
                            const sizeIndex = product.colors[selectedColorIndex].skus.findIndex((sku) => sku.size === size);
                            setSelectedSize(size);
                            setSelectedPrice(product.colors[selectedColorIndex].skus[sizeIndex].price);
                            setSelectedSku(product.colors[selectedColorIndex].skus[sizeIndex].id);
                        }
                    }
                    choices={
                        product.colors[selectedColorIndex].skus.map((sku) => ({
                            key: sku.size,
                            disabled: !sku.in_stock
                        }))
                    }/>)
            } </div>
            <div className="flex justify-content-between align-items-center mt-auto">
                <a href="#"
                    className={
                        `snipcart-add-item btn btn-primary ${
                            (!stitchSize || !previewDataUrl) && 'add-to-cart-disabled'
                        }`
                    }
                    data-item-id={selectedSku}
                    data-item-url={
                        `/api/rest/snipcart/products/${selectedSku}/`
                    }
                    data-item-image={
                        product.colors[selectedColorIndex].image_url
                    }
                    data-item-name={
                        product.product_name
                    }
                    data-item-description={
                        product.description
                    }
                    data-item-price={selectedPrice}
                    data-item-weight={product.weight_grams}
                    data-item-custom1-name='Size'
                    data-item-custom1-type={selectedSize ? 'readonly' : 'hidden'}
                    data-item-custom1-value={selectedSize ? selectedSize : 'N/A'}
                    data-item-custom2-name='Number of Colors'
                    data-item-custom2-type='readonly'
                    data-item-custom2-options="1|2|3|4|5|6|7[+2.00]|8[+4.00]|9[+6.00]|10[+8.00]|11[+10.00]|12[+12.00]"
                    data-item-custom2-value={numberOfColors}
                    data-item-custom3-name='Design ID'
                    data-item-custom3-type='hidden'
                    data-item-custom3-value={designId}
                    data-item-custom4-name='Border Color'
                    data-item-custom4-type='hidden'
                    data-item-custom4-value={borderColor}
                    data-item-custom5-name='Placement'
                    data-item-custom5-type={
                        selectedPosition ? 'readonly' : 'hidden'
                    }
                    data-item-custom5-value={
                        selectedPosition ? selectedPosition : 'N/A'
                    }
                    data-item-custom6-name='Design Size'
                    data-item-custom6-type='readonly'
                    data-item-custom6-value={mmToInches(stitchSize, columns)}
                    data-item-custom7-name='Color'
                    data-item-custom7-type='readonly'
                    data-item-custom7-value={product.colors[selectedColorIndex].color_name}
                    data-item-custom8-name='Design url'
                    data-item-custom8-type='hidden'
                    data-item-custom8-value={location.origin + `/render/${designId}/`}
                    data-item-custom9-name='Share url'
                    data-item-custom9-type='hidden'
                    data-item-custom9-value={location.origin + `/embroider/${designId}?sku=${selectedSku}&s=${stitchSize}&p=${selectedPosition ? selectedPosition : ''}&h=true`}                 
                    onClick={() => sendGoogleEvent(selectedSku)}
                > {
                    stitchSize ? 'Add to Cart' : 'Design Too Large'
                } </a>
                {/* share button */}
                <button className='share-button btn btn-secondary' onClick={() => copyToClipboard()}>
                    { copied ? (
                        <span className="copied-text">Copied!</span>
                    ) : (
                        <>
                            <span>Share</span>
                            <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 100 100" x="0px" y="0px"><path d="M75.18,61.45a16.43,16.43,0,0,0-13.54,7.13L39.72,56.18a16.13,16.13,0,0,0,0-12.3l21.9-12.46a16.42,16.42,0,1,0-2.92-9.32A16.07,16.07,0,0,0,59,24.87L35.65,38a16.46,16.46,0,1,0,0,24.07L59,75.13a16.06,16.06,0,0,0-.28,2.77A16.46,16.46,0,1,0,75.19,61.45Zm0-48.75a9.41,9.41,0,1,1-9.4,9.41h0A9.4,9.4,0,0,1,75.18,12.7ZM24.49,59.4a9.4,9.4,0,1,1,9.4-9.4h0A9.41,9.41,0,0,1,24.49,59.4ZM75.18,87.3a9.41,9.41,0,1,1,9.4-9.4h0A9.4,9.4,0,0,1,75.18,87.3Z"/></svg>
                        </>
                    )
                    }
                    
                </button>
                
                {
                stitchSize && (<span className="card-text product-price font-lg">
                    ${
                    numberOfColors > 6 ? (parseFloat(((numberOfColors - 6)) * 2) + parseFloat(selectedPrice)).toFixed(2) : selectedPrice
                } </span>)
            } </div>
        </div>
        <ProductInfoDialog sizing_image_url={product.sizing_image_url} extra_images={product.colors[selectedColorIndex].extra_images} viewSizingModal={viewSizingModal} setViewSizingModal={setViewSizingModal} detailedDescription={product.detailed_description}/>

    </div>);
};

export default ProductCard;
