import React, { useEffect, useRef } from 'react';
import PixelCell from './PixelCell';

const PixelGrid = ({ cells, drawHandlers, classes, nbrColumns, hoveredCell }) => {
  const gridRef = useRef(null);

  useEffect(() => {
    const gridElement = gridRef.current;
    if (gridElement) {
      const touchMoveHandler = (ev) => {
        drawHandlers.onTouchMove(ev);
      };
      
      gridElement.addEventListener('touchmove', touchMoveHandler, { passive: false });

      return () => {
        gridElement.removeEventListener('touchmove', touchMoveHandler);
      };
    }
  }, [drawHandlers]);

  return (
    <div ref={gridRef} className={classes}>
      {cells.map(cell => (
        <PixelCell
          key={cell.id}
          cell={cell}
          id={cell.id}
          drawHandlers={drawHandlers}
          onFocus={(id, ev) => drawHandlers.onMouseOver(id, ev)}
          nbrColumns={nbrColumns}
          hoveredCell={hoveredCell}
        />
      ))}
    </div>
  );
};

export default PixelGrid;