// faq page
import React, {useEffect} from 'react';

const FAQ = () => {

    // scroll to top on page load
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='faq-page'>
            <div className='container faq'>
                <div className="row">
                    <div className="col-md-12 position-relative">
                        <h1 className="text-left faq-main-header">FAQs</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 position-relative">
                        <h2 className="text-left faq-header">How long will it take after placing my order for it to be processed and shipped?</h2>
                        <p className="text-left">Orders are typically processed and shipped within 3-5 business days. These processing times may increase around holidays. You will receive an email with tracking details once your order has been shipped.</p>
                        <h2 className="text-left faq-header">I’m not sure what size to order, are there sizing charts available?</h2>
                        <p className="text-left">Pixel Stitch letter sizes are unisex and may fit differently compared to other brands Additional product information, including materials, sizing dimensions, and additional photos can be found by clicking the “Product Info” button on each of the product cards.</p>
                        <p className="text-left">Pixel Stitch will not refund or exchange custom orders due to sizing issues after delivery.</p>
                        <p className="text-left">If you would like to request additional information or have any questions regarding product sizes, please send us a message using the form on the Pixel Stitch contact page here.</p>
                        <h2 className="text-left faq-header">What is your refund/return policy?</h2>
                        <p className="text-left">We offer free returns and exchanges if there is an issue with with your order, all returns and exchange requests must be filed within 30 days of receiving the item(s). We will provide a prepaid shipping label and either issue a full refund or ship a replacement upon arrival.</p>
                        <p className="text-left">Issues that qualify for free returns and exchanges include but are not limited to:</p>
                        <ul className='faq-list'>
                            <li>Damage to items sustained before or during shipping such as stains, holes, tears, etc..</li>
                            <li>Errors in design embroidery such as incorrect design placement, design size, missed stitches, and broken thread.</li>
                            <li>The product delivered is a different color, size, or type than the one ordered.</li>
                        </ul>
                        <p className="text-left">Issues that DO NOT qualify for free returns and exchanges include but are not limited to:</p>
                        <ul className='faq-list'>
                            <li>Accidental purchase of the wrong item, size, color, or quantity noticed after shipping. If you notice a problem with your order and it hasn’t shipped, please fill out the form on the Pixel Stitch contact page, or send an email to <a href="mailto:support@pixel-stitch.io">support@pixel-stitch.io</a>
                            </li>
                            <li>The size delivered is correct but does not fit as expected. All sizes Pixel Stitch letter sizes are unisex, please use the “product info” button to verify width and length dimensions before ordering.</li>
                            <li>Damage such as holes, stains, or tears caused by product wear and use after delivery.</li>
                            <li>Damage to embroidery due to improper care. Please review proper embroidery care and washing instructions to keep your design looking as good as new.</li>
                        </ul>
                        <p className="text-left">If you experience a problem with your order, please fill out our contact form here with your reason for return/exchange, order number, and desired resolution (refund or replacement).</p>
                        <h2 className="text-left faq-header">Are there any tips or best practices to keep in mind when creating pixel art for embroidery?</h2>
                        <p className="text-left">Yes! While Pixel Stitch makes creating your custom embroidery designs easier than ever, there are a few tips to keep in mind.</p>
                        <p className="text-left"><strong>Save Your Work</strong> - The Pixel Stitch Designer makes it quick and easy to save multiple versions of your work</p>
                        <p className="text-left"><strong>Avoid Unnecessary Detail</strong> - We know it’s tempting to try and fit every little detail into a design, but we recommend simplifying as much as possible to maximize the readability of your design when embroidered.</p>
                        <p className="text-left"><strong>Color Choice</strong> - The best results are usually achieved using the lowest number of highly saturated, contrasting colors. The texture and depth you get from embroidery can also reduce the perceived contrast between similar colors under certain lighting conditions. Using as few colors as possible will help ensure that your design is easily recognizable at all times.</p>
                        <p className="text-left"><strong>Design for the Product</strong> - It’s important to consider what kind of product you want your design to go on. Certain products such as hats are limited in the amount of design space available. It’s a good idea to check that your design fits the product you want to put it on once you have the rough dimensions worked out.</p>
                        <p className="text-left"><strong>Add a Border</strong> - The Pixel Stitch editor has a convenient border feature that can be enabled using the checkbox located under “Active Colors”. After checking the box, you can select a border color from the colors already being used in the design or add a completely new one. This feature will create a stitched border around your design.</p>
                        <p className="text-left"><strong>Replacing Active Colors</strong> - Another handy feature of the Pixel Stitch editor is active color management. Selecting a color swatch under “Active Colors” on the right side of the menu before selecting the eyedropper tool will allow you to sample another color on the design. This can be used to combine multiple shades of the same color or change an existing color without having to manually replace every pixel.</p>
                        <h2 className="text-left faq-header">Are there any limits to acceptable design content?</h2>
                        <p className="text-left">Pixel Stitch reserves the right to refuse the sale of custom orders. Designs containing harmful, violent, sexually explicit, or content found to be otherwise objectionable upon review may be declined after ordering. This policy also extends to content that violates or infringes on the intellectual property rights of others.</p>
                        <p className="text-left">You will receive a full refund and notification if your order is refused. For more information, please review the Pixel Stitch content guidelines.</p>
                        <h2 className="text-left faq-header">I have a question not covered by the FAQ or would like to get in contact for another reason, what is the best way to contact Pixel Stitch?</h2>
                        <p className="text-left">The form on the Pixel Stitch contact page is the quickest and easiest way to get in touch. You can expect a response within 24 hours after submitting a message Monday-Friday.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FAQ;