import React from 'react';
import { connect } from 'react-redux';
import { switchTool, removeColorFromGrid } from '../store/actions/actionCreators';
import { ACTIVE_EYEDROPPER } from '../store/reducers/drawingToolStates';
import { pixelStoreContext } from '../../../js/contexts';

const ActivePaletteControls = ({
  activePaletteColor,
  eyedropperOn,
  switchActiveEyedropper,
  removeColorFromGrid,
  showHelp,
}) => (
  <div className="d-flex justify-content-center pt-1">
    <div data-tooltip={showHelp ? 'Replace the current color with the next one you select' : null}>
      <button
        type="button"
        aria-label="Eyedropper Active Palette Color"
        className={`eyedropper-active-palette${eyedropperOn ? ' active-palette-selected' : ''}
            ${!activePaletteColor && ' active-palette-buttons-disabled'} `}
        onClick={switchActiveEyedropper}
      ></button>
    </div>
    <div data-tooltip={showHelp ? 'Delete the current color from the grid' : null}>
      <button
        type="button"
        aria-label="Delete Active Palette Color"
        className={`delete-active-palette ${
          !activePaletteColor && 'active-palette-buttons-disabled'
        }`}
        onClick={() => removeColorFromGrid(activePaletteColor)}
      >
        <div>
        <svg alt="Delete Active Palette" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
        </svg>
        </div>
      </button>
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  eyedropperOn: state.present.get('drawingTool') === ACTIVE_EYEDROPPER,
});

const switchActiveEyedropperAction = switchTool(ACTIVE_EYEDROPPER);
const mapDispatchToProps = (dispatch) => ({
  switchActiveEyedropper: () => dispatch(switchActiveEyedropperAction),
  removeColorFromGrid: (color) => dispatch(removeColorFromGrid(color)),
});

const ActivePaletteControlsContainer = connect(mapStateToProps, mapDispatchToProps, null, {
  context: pixelStoreContext,
})(ActivePaletteControls);
export default ActivePaletteControlsContainer;
