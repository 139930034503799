import React from 'react';

const PredefinedPaletteOption = ({colors, selectPalette}) => {
    return (
        <button
            type="button"
            className="sidebar__button__palette"
            onClick={() => {
                selectPalette(colors);
            }}
        >
            <div className='d-flex flex-row pb-2'>
                {colors.map((color, index) => {
                    return <div key={index} className='color-div' style={{backgroundColor: color}}></div>
                })}
            </div>
            <span>SELECT</span>
        </button>
    );
};

export default PredefinedPaletteOption;