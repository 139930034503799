import React, { useState } from 'react';
import { createSelectorHook } from 'react-redux';
import Preview from './Preview';
import CellsInfo from './CellsInfo';
import { pixelStoreContext } from '../../../js/contexts';
// import cross stitch svg
import crossStitch from '../css/icons/cross-stitch.svg';

const PreviewBox = props => {
  const useSelector = createSelectorHook(pixelStoreContext);
  const [animate, setAnimate] = useState(false);
  const [isNormalSize, setNormalSize] = useState(true);
  const [crossStitchView, setCrossStitchView] = useState(false);
  const frames = useSelector(state => state.present.get('frames'));
  const duration = useSelector(state => state.present.get('duration'));
  const frameList = frames.get('list');
  const activeFrameIndex = frames.get('activeIndex');
  const columns = frames.get('columns');
  const rows = frames.get('rows');
  const { helpOn, callback } = props;
  const animMessage = `${animate ? 'Pause' : 'Play'} the animation`;
  const zoomMessage = `Zoom ${isNormalSize ? '1.5' : '0.5'}`;
  const animTooltip = helpOn ? animMessage : null;
  const zoomTooltip = helpOn ? zoomMessage : null;
  const smPixelSize = 3;
  const bgPixelSize = 6;

  return (
    <div className="preview-box">
      <div className="buttons">
        {/* <div data-tooltip={animTooltip}>
          <button
            type="button"
            className={animate ? 'pause' : 'play'}
            onClick={() => setAnimate(!animate)}
            aria-label="Animation control"
          />
        </div> */}
        <div className='preview-button-group'>
        <div data-tooltip={zoomTooltip}>
          <button
            type="button"
            className={isNormalSize ? 'screen-full' : 'screen-normal'}
            aria-label="Zoom button"
            onClick={() => {
              setNormalSize(!isNormalSize);
            }}
          />
        </div>
        {/* button to show cross stitch view (preview type html) */}
        <div data-tooltip={helpOn ? crossStitchView ? 'Show square preview' : 'Show cross stitch preview' : null}>
          <button
            type="button"
            className="cross-stitch"
            aria-label="Cross stitch view"
            onClick={() => {
              setCrossStitchView(!crossStitchView);
            }}
          >
            {/* render the cross stitch icon or a square */}
            {crossStitchView ? 
              (
                <div className="square"></div>
              )
            : (
              <img src={crossStitch } alt="cross stitch icon" />
            )
            }
            
          </button>
        </div>
        </div>
        
        <div
          data-tooltip={
            helpOn ? "Cursor's location on the grid" : null
          }
        >
          <CellsInfo />
        </div>
        {/* <div data-tooltip={helpOn ? 'Show a preview of your project' : null}>
          <button
            type="button"
            className="frames"
            aria-label="Active modal"
            onClick={callback}
          />
        </div> */}
      </div>
      <div className="preview-box__container">
        <Preview
          frames={frameList}
          columns={columns}
          rows={rows}
          cellSize={isNormalSize ? smPixelSize : bgPixelSize}
          duration={duration}
          activeFrameIndex={activeFrameIndex}
          animate={animate}
          animationName="wip-animation"
          type={'html'}
          stitchType={crossStitchView ? 'cross-stitch' : 'fill'}
        />
      </div>
    </div>
  );
};

export default PreviewBox;
