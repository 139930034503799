import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import {Link} from 'react-router-dom';
import {createDispatchHook, createSelectorHook} from 'react-redux';

import {fetchRestCheck} from '../store/embroidery';
import {mainStoreContext} from '../contexts';
import FeatureCard from '../components/FeatureCard';
import HowItWorks from '../components/HowItWorks';

import example1 from '../../assets/images/example1.jpg';
import example2 from '../../assets/images/example2.jpg';
import example3 from '../../assets/images/example3.jpg';

import editorSVG from '../../assets/images/editor.svg';

import logoSplash from '../../assets/images/logo_header.svg';

import { TiltEffect } from '../components/Tilt';

const Home = () => {
    const useDispatch = createDispatchHook(mainStoreContext);
    const useSelector = createSelectorHook(mainStoreContext);
    const dispatch = useDispatch();
    const restCheck = useSelector((state) => state.restCheck);
    useEffect(() => {
        const action = fetchRestCheck();
        dispatch(action);
    }, [dispatch]);

    useEffect(() => {
      document.title = "PixelStitch"
   }, []);

    // const [showBugComponent, setShowBugComponent] = useState(false);

    return (
        <> {/* <div id="django-background">
        If you are seeing the green Django logo on a white background and this text color is
        #092e20, frontend static files serving is working
      </div>
      <div id="django-logo-wrapper">
        <div>
          Below this text, you should see an img tag with the white Django logo on a green
          background
        </div>
        <img alt="Django Negative Logo" src={DjangoImgSrc} />
      </div>
      <div>Result: {restCheck?.data?.payload?.result}</div>
      <Button variant="outline-dark" onClick={() => setShowBugComponent(true)}>
        Click to test if Sentry is capturing frontend errors! (Should only work in Production)
      </Button>
      {showBugComponent && showBugComponent.field.notexist}
      <br/> */}

            <div id="home-background">
                <div className='home-splash-padding'>
                    
                    <TiltEffect>
                    <div className="home-splash-text">
                        {/* <h1 className="text-center main-title">PixelStitch</h1> */}
                        <img src={logoSplash} alt="Pixel Stitch Logo" className='logo-splash' />
                        <p className="text-center main-paragraph">
                            Embroider your pixel art on a variety of products.
                            Click below to get started!
                        </p>
                        <p className="text-center">
                            <Link to="/create">
                                <Button variant="outline-dark" className='dyo-button pixelate'>Design Your Own</Button>
                            </Link>
                        </p>
                      </div>
                    </TiltEffect>
                    
                </div>
                

            </div>
            <div className='home-section-wrapper'>
            <div className="container text-left home-first-section container-padding">
                  <div className="row">
                    <div className="col-md-12">
                      <h2 className="text-left home-header">Welcome to Pixel Stitch - Where Your Pixels Come to Life</h2>
                      <p>
                        At Pixel Stitch, we believe in turning your digital art into tangible treasures. Our unique platform empowers you to create custom, high-quality embroidered products with a pixel-art twist. Whether you're an avid pixel artist, a photo enthusiast, or a creative soul, we've got you covered.
                      </p>
                    </div>
                  </div>
            </div>
            
            <HowItWorks />
                
                <div className="container text-left home-section container-padding">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="text-left">Featured Designs</h2>
                            {/* <p></p> */}
                        </div>
                    </div>
                </div>
                <div className="container text-center home-section-featured container-padding">
                    <div className="row">

                        <div className="col-lg-4 col-md-4 col-xs-4 pb-4">
                          <a href='embroider/42aebdbd-c54d-4664-88f3-22495acad93f?sku=1&p=Center&s=2&h=true'>
                            <FeatureCard imgSrc={example3} title={"The Great Pixel Wave"} desc={''}/>
                          </a>
                          
                        </div>
                        <div className="col-lg-4 col-md-4 col-xs-4 pb-4">
                        <a href="embroider/f5ffa7c3-03f4-4f5a-b1e5-ae685c750d40?sku=40&s=1.5&p=&h=true">
                          <FeatureCard imgSrc={example2} title={"Goodbye Quarter"} desc={''}/>
                        </a>
                        </div>
                        <div className="col-lg-4 col-md-4 col-xs-4 pb-4">
                          <a href="embroider/0b252aa4-6dd7-4317-98ae-b11a6ba0beca?sku=27&s=2.5&p=Left&h=true">
                          <FeatureCard imgSrc={example1} title={"Fido"} desc={''}/>
                        </a>
                        </div>
                    </div>
                </div>
                <div className='home-section-editor'>
                  <h2 className="text-center">Import or Create Your Own Designs</h2>
                  <div className='editor-tilt-wrapper'>
                  <Link to="/create">
                    <TiltEffect>
                      <img src={editorSVG} alt="Pixel Stitch Editor"/>
                    </TiltEffect>
                  </Link>
                  </div>
                  <p className="text-center">
                      <Link to="/create">
                          <Button variant="outline-light" className='dyo-button pixelate-light'>Go to Editor</Button>
                      </Link>
                  </p>
                </div>
                <div className="container text-left home-section home-section-about container-padding">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="text-left home-header" id="about">About Us</h2>
                            <p>
                              Founded in 2023 by two friends with a passion for embroidery- Pixel Stitch is a small, family-operated business in the USA. Our heart and soul go into creating premium products we believe you'll cherish. We prioritize an outstanding customer experience and are always available to support you. We trust you'll find as much joy in Pixel Stitch as we do!
                            </p>
                        </div>
                    </div>
                  </div>
            </div>

            {/* <button className="snipcart-add-item"
        data-item-id={1}
        data-item-image={"example.com/image.png"}
        data-item-name={"test"}
        data-item-price={3.99}
      >
        Add to Cart
      </button> */} </>
    );
};

export default Home;
