import {
  getImageData,
  getImageCssClassOutput,
  getAnimationKeyframes,
  getAnimationCssClassOutput
} from 'box-shadow-pixels';

const PIXELART_CSS_CLASS_NAME = 'pixelart-to-css';

const styleBorder = (grid, i, opt) => {
  // return a string for the appropriate border style for the given position 
  // (top, right, bottom, left)
  // if the pixel is transparent, return an empty string
  // if the pixel is not transparent, return a border style string
  // the grid is one long array, so we need to check if we are on the right edge
  // are we on the top edge?
  // add as many border styles as needed
  let borderStyle = '';
  const borderColor = opt.borderColor || "#ffffffff"
  if (opt.borderActive){
    // only add border line if there is a border there (i.e. on the edge or next to a transparent pixel)
    // make sure we are not a transparent pixel
    if (grid.get(i) === '' || grid.get(i) === 'rgba(0,0,0,0)'){
      return borderStyle;
    }

    // are we on the top edge?
    if (i < opt.c){
      borderStyle += `border-top: 1px solid ${borderColor};`;
    }
    // are we on the bottom edge?
    if (i > grid.size - opt.c){
      borderStyle += `border-bottom: 1px solid ${borderColor};`;
    }
    // are we on the right edge?
    if (i % opt.c === opt.c - 1){
      borderStyle += `border-right: 1px solid ${borderColor};`;
    }
    // are we on the left edge?
    if (i % opt.c === 0){
      borderStyle += `border-left: 1px solid ${borderColor};`;
    }
    // are we on the bottom-left corner?
    if (i === grid.size - opt.c){
      borderStyle += `border-bottom: 1px solid ${borderColor};`;
    }

    // handle borders with transparent pixels
    // is the pixel to the left transparent?
    if (grid.get(i-1) === '' || grid.get(i-1) === 'rgba(0,0,0,0)'){
      borderStyle += `border-left: 1px solid ${borderColor};`;
    }
    // is the pixel to the right transparent?
    if (grid.get(i+1) === '' || grid.get(i+1) === 'rgba(0,0,0,0)'){
      borderStyle += `border-right: 1px solid ${borderColor};`;
    }
    // is the pixel above transparent?
    if (grid.get(i-opt.c) === '' || grid.get(i-opt.c) === 'rgba(0,0,0,0)'){
      borderStyle += `border-top: 1px solid ${borderColor};`;
    }
    // is the pixel below transparent?
    if (grid.get(i+opt.c) === '' || grid.get(i+opt.c) === 'rgba(0,0,0,0)'){
      borderStyle += `border-bottom: 1px solid ${borderColor};`;
    }
  }
  return borderStyle;
}

export const getImageDataTest = (grid, opt) => {
  const xCoord = (i) => ((i % opt.c) * opt.pSize) + opt.pSize;
  const yCoord = (i) => (parseInt(i / opt.c, 10) * opt.pSize) + opt.pSize;
  const blurRadius = opt.blurRadius ? `${opt.blurRadius}px` : 0;
  const spreadRadius = opt.spreadRadius ? `${opt.spreadRadius}px` : 0;

  switch (opt.format) {
    case 'array': {
      return grid.reduce((bsArray, color, i) => {
        if (color !== '') {          
          bsArray.push({
            x: xCoord(i),
            y: yCoord(i),
            color,
            blurRadius,
            spreadRadius
          });
        }
        return bsArray;
      }, []);
    }
    case 'html': {
      return grid.reduce((htmlString, color, i) => {
        if (color !== '' && color !== 'rgba(0,0,0,0)') {
          if (!opt.stitch || opt.stitch === "cross-stitch"){
            return `${htmlString}
            <div class="cross-stitch-container" style="position: absolute; width: ${opt.pSize}px; height: ${opt.pSize}px; top: ${yCoord(i)}px; left: ${xCoord(i)}px;
            ${styleBorder(grid, i, opt)}
            ">
                <div class="cross-stitch-diagonal" style="background-color: ${color}; transform: rotate(45deg);"></div>
                <div class="cross-stitch-diagonal" style="background-color: ${color}; transform: rotate(-45deg);"></div>
            </div>`;
          }
          else if (opt.stitch === "fill"){
            return `${htmlString}
            <div class="cross-stitch-container" style="position: absolute; width: ${opt.pSize}px; height: ${opt.pSize}px; top: ${yCoord(i)}px; left: ${xCoord(i)}px;background-color: ${color};
            ${styleBorder(grid, i, opt)}
            ">
            </div>`;
          }
        }
        return htmlString;
      }, '');
    }
    case 'svg': {
      return grid.reduce((svgString, color, i) => {
        if (color === 'rgba(0,0,0,0)') {
          return svgString;
        }
        if (color !== '') {
          const gap_fix = opt.stitch === "fill" ? 1.1 : 1.0;
          const enlargedSize = opt.pSize * gap_fix; // Enlarge to cover gaps
          const adjustedX = xCoord(i) - (enlargedSize - opt.pSize) / 2;
          const adjustedY = yCoord(i) - (enlargedSize - opt.pSize) / 2;
          
          let stitch;
          if (!opt.stitch || opt.stitch === "cross-stitch"){
            stitch = `
              <line x1="${adjustedX}" y1="${adjustedY}" x2="${adjustedX + enlargedSize}" y2="${adjustedY + enlargedSize}" 
                    stroke="${color}" stroke-width="0.5" />
              <line x1="${adjustedX + enlargedSize}" y1="${adjustedY}" x2="${adjustedX}" y2="${adjustedY + enlargedSize}" 
                    stroke="${color}" stroke-width="0.5" />
              `;
          }
          else if (opt.stitch === "fill"){
            stitch = `<rect x="${adjustedX}" y="${adjustedY}" width="${enlargedSize}" height="${enlargedSize}" fill="${color}" />`;
          }
    
          return `${svgString}${stitch}`;
        }
        return svgString;
      }, `<svg width="100%" viewBox="1 1 ${opt.c * opt.pSize} ${parseInt(grid.size / opt.c, 10) * opt.pSize}" xmlns="http://www.w3.org/2000/svg">`) + '</svg>';
    }
    case 'fancySVG': {
      return grid.reduce((svgString, color, i) => {
          if (color === 'rgba(0,0,0,0)') {
              return svgString;
          }
          if (color !== '') {
              const addHighlight = Math.random() > 0.8; // 20% chance to add a highlight
              const highlightIntensity = Math.random() * 0.2 -0.075; // Random number between 0.1 and 0.3
              const addShadow = Math.random() > 0.2; // 80% chance to add a shadow
              const shadowIntensity = Math.random() * 0.2 + 0.2; // Random number between 0.1 and 0.3
              const xWiggle = Math.random() * 0.2 - 0.1; // Random number between -0.1 and 0.1
              const yWiggle = Math.random() * 0.2 - 0.1; // Random number between -0.1 and 0.1
              const xWiggle2 = Math.random() * 0.2 - 0.1; // Random number between -0.1 and 0.1
              const yWiggle2 = Math.random() * 0.2 - 0.1; // Random number between -0.1 and 0.1
              const gap_fix = opt.stitch === "fill" ? 1.1 : 1.0;
              const enlargedSize = opt.pSize * gap_fix;
              const adjustedX = xCoord(i) - (enlargedSize - opt.pSize) / 2;
              const adjustedY = yCoord(i) - (enlargedSize - opt.pSize) / 2;
              
              let stitch;
              let highlight;
              let shadow;
              if (!opt.stitch || opt.stitch === "cross-stitch"){
                  stitch = `
                      <line x1="${adjustedX+xWiggle}" y1="${adjustedY+yWiggle}" x2="${adjustedX + enlargedSize}" y2="${adjustedY + enlargedSize}" 
                          stroke="${color}" stroke-width="0.5" />
                      <line x1="${adjustedX + enlargedSize+xWiggle2}" y1="${adjustedY+yWiggle2}" x2="${adjustedX}" y2="${adjustedY + enlargedSize}" 
                          stroke="${color}" stroke-width="0.5" />
                  `;
  
                  // Highlight for the cross-stitch (upper-left portion)
                  if (addHighlight) {
                      const highlightLength = enlargedSize * 1; // Adjust this for highlight size
                      highlight = `
                          <line x1="${adjustedX}" y1="${adjustedY}" x2="${adjustedX + highlightLength -0.5}" y2="${adjustedY + highlightLength -0.5}" 
                              stroke="#ffffff" stroke-width="0.5" opacity="${highlightIntensity}" />
                      `;
                  }
                  // Highlight for the cross-stitch (upper-left portion)
                  if (addShadow) {
                    shadow = `
                        <line x1="${adjustedX+1}" y1="${adjustedY}" x2="${adjustedX+0.7}" y2="${adjustedY+0.3}" 
                            stroke="#000000" stroke-width="0.5" opacity="${shadowIntensity}" />
                        <line x1="${adjustedX+0.3}" y1="${adjustedY+0.7}" x2="${adjustedX}" y2="${adjustedY+1}" 
                            stroke="#000000" stroke-width="0.5" opacity="${shadowIntensity}" />
                    `;
                  }
                  const borderColor = opt.borderColor || "#ffffffff"
                    // if we are adding a border
                  if(opt.borderActive){
                    // only add border line if there is a border there (i.e. on the edge or next to a transparent pixel)

                    // the grid is one long array, so we need to check if we are on the right edge
                    // are we on the top edge?
                    if (i < opt.c){
                      stitch += `
                        <line x1="${adjustedX}" y1="${adjustedY}" x2="${adjustedX + enlargedSize}" y2="${adjustedY}" 
                            stroke="${borderColor}" stroke-width="0.5" />
                      `;
                    }
                    // are we on the bottom edge?
                    if (i > grid.size - opt.c){
                      stitch += `
                        <line x1="${adjustedX}" y1="${adjustedY + enlargedSize}" x2="${adjustedX + enlargedSize}" y2="${adjustedY + enlargedSize}" 
                            stroke="${borderColor}" stroke-width="0.5" />
                      `;
                    }
                    // are we on the right edge?
                    if (i % opt.c === opt.c - 1){
                      stitch += `
                        <line x1="${adjustedX + enlargedSize}" y1="${adjustedY}" x2="${adjustedX + enlargedSize}" y2="${adjustedY + enlargedSize}" 
                            stroke="${borderColor}" stroke-width="0.5" />
                      `;
                    }
                    // are we on the left edge?
                    if (i % opt.c === 0){
                      stitch += `
                        <line x1="${adjustedX}" y1="${adjustedY}" x2="${adjustedX}" y2="${adjustedY + enlargedSize}" 
                            stroke="${borderColor}" stroke-width="0.5" />
                      `;
                    }
                    // are we on the bottom-left corner?
                    if (i === grid.size - opt.c){
                      stitch += `
                        <line x1="${adjustedX}" y1="${adjustedY + enlargedSize}" x2="${adjustedX + enlargedSize}" y2="${adjustedY + enlargedSize}" 
                            stroke="${borderColor}" stroke-width="0.5" />
                      `;
                    }

                    // handle borders with transparent pixels
                    // is the pixel to the left transparent?
                    if (grid.get(i-1) === '' || grid.get(i-1) === 'rgba(0,0,0,0)'){
                      stitch += `
                        <line x1="${adjustedX}" y1="${adjustedY}" x2="${adjustedX}" y2="${adjustedY + enlargedSize}" 
                            stroke="${borderColor}" stroke-width="0.5" />
                      `;
                    }
                    // is the pixel to the right transparent?
                    if (grid.get(i+1) === '' || grid.get(i+1) === 'rgba(0,0,0,0)'){
                      stitch += `
                        <line x1="${adjustedX + enlargedSize}" y1="${adjustedY}" x2="${adjustedX + enlargedSize}" y2="${adjustedY + enlargedSize}" 
                            stroke="${borderColor}" stroke-width="0.5" />
                      `;
                    }
                    // is the pixel above transparent?
                    if (grid.get(i-opt.c) === '' || grid.get(i-opt.c) === 'rgba(0,0,0,0)'){
                      stitch += `
                        <line x1="${adjustedX}" y1="${adjustedY}" x2="${adjustedX + enlargedSize}" y2="${adjustedY}" 
                            stroke="${borderColor}" stroke-width="0.5" />
                      `;
                    }
                    // is the pixel below transparent?
                    if (grid.get(i+opt.c) === '' || grid.get(i+opt.c) === 'rgba(0,0,0,0)'){
                      stitch += `
                        <line x1="${adjustedX}" y1="${adjustedY + enlargedSize}" x2="${adjustedX + enlargedSize}" y2="${adjustedY + enlargedSize}" 
                            stroke="${borderColor}" stroke-width="0.5" />
                      `;
                    }

              }

              } else if (opt.stitch === "fill"){
                  stitch = `
                      <rect x="${adjustedX}" y="${adjustedY}" width="${enlargedSize}" height="${enlargedSize}" fill="${color}" />
                  `;
  
                  // Highlight for the fill stitch (upper-left portion)
                  if (addHighlight) {
                      const highlightSize = enlargedSize * 0.3; // Adjust this for highlight size
                      highlight = `
                          <rect x="${adjustedX}" y="${adjustedY}" width="${highlightSize}" height="${highlightSize}" fill="#ffffff" />
                      `;
                  }
              }
  
              return `${svgString}${stitch}${highlight || ''}${shadow || ''}`;
          }
          return svgString;
      }, `<svg width="100%" viewBox="0.5 0.5 ${(opt.c * opt.pSize) + 1} ${(parseInt(grid.size / opt.c, 10) * opt.pSize) + 1}" xmlns="http://www.w3.org/2000/svg">`) + '</svg>';
  }
    default: {
      return grid.reduce((bsString, color, i) => {
        if (color !== '') {
          return `${bsString} ${xCoord(i)}px ${yCoord(i)}px ${blurRadius} ${spreadRadius} ${color},`;
        }
        return bsString;
      }, '').slice(1, -1);
    }
  }
}

export function generatePixelDrawCss(frame, columns, cellSize, type, stitchType) {
  return getImageDataTest(frame.get('grid'), {
    format: type,
    pSize: cellSize,
    c: columns,
    stitch: stitchType,
    borderActive: frame.get('borderActive'),
    borderColor: frame.get('borderColor')
  });
}

export function getCssImageClassOutput(frame, columns, cellSize) {
  return getImageCssClassOutput(frame.get('grid'), {
    format: 'string',
    pSize: cellSize,
    c: columns,
    cssClassName: PIXELART_CSS_CLASS_NAME
  });
}

export function exportAnimationData(frames, columns, cellSize, duration) {
  return getAnimationCssClassOutput(frames, {
    pSize: cellSize,
    c: columns,
    duration,
    cssClassName: PIXELART_CSS_CLASS_NAME
  });
}

export function generateAnimationCSSData(frames, columns, cellSize) {
  return getAnimationKeyframes(frames, {
    pSize: cellSize,
    c: columns
  });
}
