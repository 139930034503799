import React from 'react';
import { connect } from 'react-redux';
import { resetGrid } from '../store/actions/actionCreators';
import { pixelStoreContext } from '../../../js/contexts';

const Reset = ({ resetGridDispatch }) => (
  <button type="button" className="reset" onClick={resetGridDispatch}>
    CLEAR
  </button>
);

const mapDispatchToProps = dispatch => ({
  resetGridDispatch: () => dispatch(resetGrid())
});

const ResetContainer = connect(
  null,
  mapDispatchToProps,
  null,
  { context: pixelStoreContext }
)(Reset);
export default ResetContainer;
