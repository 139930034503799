import React, { useState, useCallback, useRef, useEffect } from 'react';
import { debounce } from 'lodash';
import { createDispatchHook, createSelectorHook } from 'react-redux';
import { switchTool, setCustomColor } from '../store/actions/actionCreators';
import { COLOR_PICKER, PENCIL } from '../store/reducers/drawingToolStates';
import { pixelStoreContext } from '../../../js/contexts';

export const hexToRgb = hex => {
  const hexValue = hex.replace('#', '');
  const r = parseInt(hexValue.substring(0, 2), 16);
  const g = parseInt(hexValue.substring(2, 4), 16);
  const b = parseInt(hexValue.substring(4, 6), 16);
  return `rgba(${r},${g},${b},1)`;
};

export const rgbToHex = rgb => {
  const rgbValue = rgb.replace('rgba(', '').replace(')', '').split(',');
  const toHex = value => {
    const hex = parseInt(value, 10).toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  };
  const r = toHex(rgbValue[0]);
  const g = toHex(rgbValue[1]);
  const b = toHex(rgbValue[2]);
  return `#${r}${g}${b}`;
};

const ColorPickerContainer = React.memo(({ isMobile }) => {
  const useDispatch = createDispatchHook(pixelStoreContext);
  const useSelector = createSelectorHook(pixelStoreContext);
  const colorPickerRef = useRef(null);
  const colorPickerOn = useSelector(
    state => state.present.get('drawingTool') === COLOR_PICKER
  );
  const paletteColor = useSelector(state => {
    const palette = state.present.get('palette');
    const currentColorPosition = palette.get('position');
    return palette.getIn(['grid', currentColorPosition, 'color']);
  });

  const dispatch = useDispatch();
  const switchColorPickerAction = switchTool(COLOR_PICKER);
  const setPencilToolAction = switchTool(PENCIL);

  // Add a state to hold the temporary color
  const [tempColor, setTempColor] = useState(paletteColor ? rgbToHex(paletteColor) : '#000000');

  // Debounce function
  const debounceColorChange = useCallback(
    debounce(color => {
      dispatch(setCustomColor(color));
      dispatch(setPencilToolAction);
    }, 250),
    []
  );

  useEffect(() => {
    if (colorPickerOn) {
      colorPickerRef.current.click();
    }
  }, [colorPickerOn]);

  const onPickerChange = color => {
    const rgbColor = hexToRgb(color);
    setTempColor(color);
    debounceColorChange(rgbColor);
  };

  return (
    <div className="color-picker">
      <button
        type="button"
        aria-label="Color Picker Tool"
        className={`${colorPickerOn ? ' selected' : ''}`}
      >
        <input
          type="color"
          onChange={e => onPickerChange(e.target.value)}
          value={paletteColor ? rgbToHex(paletteColor): "#000000"}
          ref={colorPickerRef}
        />
      </button>
    </div>
  );
});

export default ColorPickerContainer;
