import React, { useState, useRef, useEffect } from 'react';
import { useSelector, createSelectorHook } from 'react-redux';
import styled, { css } from 'styled-components';
import Button from '../common/Button';
import ValidationMessage from './ValidationMessage';
import ImageDimensions from './ImageDimensions';
import PredefinedPaletteOption from '../PredefinedPaletteOption';
import ImageSetupSection from './ImageSetup';
import breakpoints from '../../utils/breakpoints';
import drawFileImageToCanvas from '../../utils/ImageToCanvas';
import generateFrames, { getCanvasDimensions } from '../../utils/loadFromCanvas';
import RgbQuant from 'rgbquant';
import { getOptions } from '../../utils/ImageToCanvas';
import { palettes } from '../../utils/palettes';
import { mainStoreContext } from '../../../../js/contexts';
import {
  DEFAULT_GRID_COLUMNS,
  DEFAULT_GRID_ROWS,
  MAX_GRID_COLUMNS,
  MAX_GRID_ROWS,
  ADMIN_GRID_COLUMNS,
  ADMIN_GRID_ROWS
} from '../../../../js/constants';
import ImportYourOwnContainer from '../ImportYourOwn';

const Container = styled.div`
  text-align: center;
  padding: 1rem 0;
`;

const Title = styled.h2`
  display: block;
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 2em;
  font-weight: 700;
  top: 0;
  color: whitesmoke;
  padding-top: 2rem;
`;

const LoadedImageContainer = styled.div`
  display: none;
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 1rem 0 1rem;
  width: 100%;

  @media only screen and (${breakpoints.device.md}) {
    width: 50%;
    margin: 0 auto;
  }

  ${(props) =>
    props.imageLoaded &&
    css`
      display: block;
    `}
`;

const CanvasWrapper = styled.div`
  margin: 0 auto 2rem;
  margin-bottom: 0.75rem;
  width: 100%;
  height: 320px;
  padding-right: 6px;
`;

const PickerInfoIcon = styled.i`
  position: relative;
  background-color: #2f5382;
  color: white;
  border-radius: 9999px;
  top: 3px;
  padding: 0.2rem;
  margin-left: 0.4rem;
`;

const LoadFromFile = (props) => {
  // This is the canvas we show to the user
  const canvasRef = useRef(null);
  // This is the canvas we are using as a reference
  const originalCanvasRef = useRef(null);
  // This is the canvas we are actually going to import
  const importCanvasRef = useRef(null);
  const [frameCount, setFrameCount] = useState(1);
  const [numColors, setNumColors] = useState(6);
  const [pixelSize, setPixelSize] = useState(1);
  const [rows, setRows] = useState(DEFAULT_GRID_ROWS);
  const [columns, setColumns] = useState(DEFAULT_GRID_COLUMNS);
  const [pixelArtImportMode, setPixelArtImportMode] = useState(true);
  const [imageDimensions, setImageDimensions] = useState({ w: 0, h: 0 });
  const [resultDimensions, setResultDimensions] = useState({ w: 0, h: 0 });
  const [showSideBar, setShowSideBar] = useState(false);
  const [selectedPalette, setSelectedPalette] = useState([]);
  const [usePredefinedPalette, setUsePredefinedPalette] = useState(false);
  const [validationError, setValidationError] = useState({
    show: false,
    title: '',
    message: '',
    widthError: false,
    heightError: false,
  });
  const [imageLoaded, setImageLoaded] = useState(false);
  const useSelector = createSelectorHook(mainStoreContext);
  const isStaff = useSelector((state) => state.restCheck?.whoami?.payload?.is_staff);

  const isMobile = window.innerWidth < 900;

  useEffect(() => {
    if (props.uploadedFile) {
      const file = props.uploadedFile;
      onLoadImage({ target: { files: [file] } });
    }
  }, [props.uploadedFile]);

  useEffect(() => {
    if (imageLoaded && originalCanvasRef) {
      const canvas = originalCanvasRef.current;
      const context = canvas.getContext('2d');
      const canvas2 = importCanvasRef.current;
      const context2 = canvas2.getContext('2d');
      context2.imageSmoothingEnabled = false;
      context2.canvas.width = columns;
      context2.canvas.height = rows;
      context2.drawImage(canvas, 0, 0, context2.canvas.width, context2.canvas.height);

      var q = new RgbQuant(getOptions(numColors, selectedPalette));

      q.sample(canvas2);

      // const pal = q.palette(true);
      const out = q.reduce(canvas2);

      // replace canvas image with Uint8Array (out)

      const data = context2.getImageData(0, 0, canvas2.width, canvas2.height);
      context2.clearRect(0, 0, canvas2.width, canvas2.height);
      const newdata = new Uint8ClampedArray(out);
      data.data.set(newdata);
      context2.putImageData(data, 0, 0);
      if (!pixelArtImportMode) {
        // shrink down to default rows and columns and then back up to canvas size with no aliasing
        context2.imageSmoothingEnabled = false;
        context2.drawImage(canvas2, 0, 0, DEFAULT_GRID_COLUMNS, DEFAULT_GRID_ROWS);
        context2.drawImage(
          canvas2,
          0,
          0,
          DEFAULT_GRID_COLUMNS,
          DEFAULT_GRID_ROWS,
          0,
          0,
          canvas2.width,
          canvas2.height
        );
        const canvas3 = canvasRef.current;
        const context3 = canvas3.getContext('2d');
        context3.clearRect(0, 0, canvas3.width, canvas3.height);
        context3.drawImage(
          canvas2,
          0,
          0,
          canvas2.width,
          canvas2.height,
          0,
          0,
          canvas3.width,
          canvas3.height
        );
      } else {
        // clear the preview canvas
        // draw the import canvas to the preview canvas
        const canvas3 = canvasRef.current;
        const context3 = canvas3.getContext('2d');
        context3.clearRect(0, 0, canvas3.width, canvas3.height);
        // calculate the width and height of the canvas based on aspect ratio
        if (rows <= columns) {
          context3.canvas.width = 320;
          context3.canvas.height = 320 * (rows / columns);
        } else {
          context3.canvas.width = 320 * (columns / rows);
          context3.canvas.height = 320;
        }
        context3.imageSmoothingEnabled = false;
        context3.drawImage(
          canvas2,
          0,
          0,
          canvas2.width,
          canvas2.height,
          0,
          0,
          canvas3.width,
          canvas3.height
        );
        context3.strokeStyle = '#000000';
        context3.lineWidth = 0.5;
        context3.beginPath();
        for (var i = 0; i <= columns; i++) {
          context3.moveTo(i * (canvas3.width / columns), 0);
          context3.lineTo(i * (canvas3.width / columns), canvas3.height);
        }
        for (var j = 0; j <= rows; j++) {
          context3.moveTo(0, j * (canvas3.height / rows));
          context3.lineTo(canvas3.width, j * (canvas3.height / rows));
        }
        context3.stroke();
      }
    }
  }, [numColors, rows, columns, selectedPalette, imageLoaded, imageDimensions]);

  const showValidationMessage = (validation) => {
    setValidationError({
      show: false,
      title: '',
      message: '',
      widthError: false,
      heightError: false,
    });
    if (validation.show) {
      setValidationError({
        show: true,
        title: validation.title,
        message: validation.message,
        widthError: validation.widthError,
        heightError: validation.heightError,
      });
    }
  };

  const imgSetupValidation = (contextDimensions, size, frameAmount) => {
    const widthPixelsFit = contextDimensions.w % size === 0;
    const heightPixelsFit = (contextDimensions.h / frameAmount) % size === 0;

    const pixelsWidth = contextDimensions.w / size;
    const pixelsHeight = contextDimensions.h / size / frameAmount;

    const maxWidthReached = pixelsWidth > (isStaff ? ADMIN_GRID_COLUMNS : MAX_GRID_COLUMNS);
    const maxHeightReached = pixelsHeight > (isStaff ? ADMIN_GRID_ROWS : MAX_GRID_ROWS);

    if (!widthPixelsFit || !heightPixelsFit) {
      showValidationMessage({
        show: true,
        title: 'Error',
        message: 'No valid frame size. Width and height must be exact.',
        widthError: !widthPixelsFit,
        heightError: !heightPixelsFit,
      });
      return false;
    }

    if (maxWidthReached || maxHeightReached) {
      showValidationMessage({
        show: true,
        title: 'Error - Dimension limit reached',
        message: `Frame size dimensions must no exceed ${isStaff ? ADMIN_GRID_COLUMNS : MAX_GRID_COLUMNS}px width by ${isStaff ? ADMIN_GRID_ROWS : MAX_GRID_ROWS}px height. Please increase the pixel size or divide your image in different frames.`,
        widthError: maxWidthReached,
        heightError: maxHeightReached,
      });
      return false;
    }

    showValidationMessage({
      show: false,
      widthError: false,
      heightError: false,
    });
    return true;
  };

  const onLoadImage = (ev) => {
    const file = ev.target.files[0];

    setNumColors(6);
    setPixelSize(pixelArtImportMode ? 1 : 10);

    if (canvasRef) {
      const imageLoadedData = drawFileImageToCanvas(
        file,
        canvasRef.current,
        (imgDimensions) => {},
        pixelArtImportMode,
        6, // initial number of colors we'd suggest
        'preview'
      );
      // set the canvas we want as a reference
      drawFileImageToCanvas(
        file,
        originalCanvasRef.current,
        (imgDimensions) => {},
        pixelArtImportMode,
        null,
        'reference',
        true
      );
      // set the canvas we want to import from
      // set the canvas we want as a reference
      drawFileImageToCanvas(
        file,
        importCanvasRef.current,
        (imgDimensions) => {
          setImageLoaded(true);
          setImageDimensions(imgDimensions);
          setResultDimensions(imgDimensions);
          imgSetupValidation(
            getCanvasDimensions(importCanvasRef),
            pixelArtImportMode ? pixelSize : 10,
            frameCount
          );
          setRows(imgDimensions.h);
          setColumns(imgDimensions.w);
        },
        pixelArtImportMode,
        6,
        'import'
      );
      if (imageLoadedData.errorType) {
        setImageLoaded(false);
        showValidationMessage({
          show: true,
          title: 'Error',
          message:
            imageLoadedData.errorType === 'notImage'
              ? 'Not a valid image file.'
              : 'There was an error while loading the file.',
          widthError: false,
          heightError: false,
        });
      } else {
        showValidationMessage({ show: false });
      }
    }
  };

  const onCreateProject = () => {
    const { actions, close } = props;
    const canvas = importCanvasRef.current;
    const context = canvas.getContext('2d');
    const createProjectPixelSize = pixelArtImportMode ? pixelSize : 10;

    window.gtag("event", "import_image_to_canvas", {
      event_category: "engagement",
        // image dimensions
        event_label: `${context.canvas.width}x${context.canvas.height}`,
    });

    // if they are using a custom palette, send another event
    if (selectedPalette.length > 0 && usePredefinedPalette) {
      window.gtag("event", "custom_palette", {
        event_category: "engagement",
        event_label: selectedPalette.toString()
      });
    }


    if (
      imgSetupValidation(
        {
          w: context.canvas.width,
          h: context.canvas.height,
        },
        createProjectPixelSize,
        frameCount
      )
    ) {
      const CanvasByPixelSize = {
        width: context.canvas.width / createProjectPixelSize,
        height: context.canvas.height / createProjectPixelSize,
      };
      const frames = generateFrames(context, frameCount, createProjectPixelSize);

      actions.setDrawing(
        frames,
        [],
        createProjectPixelSize,
        CanvasByPixelSize.width,
        Math.floor(CanvasByPixelSize.height / frameCount)
      );
      close();
    }
  };

  return (
    <>
      {showSideBar && (
        <div className="sidebar slide-in-right">
          <div className="d-flex flex-column">
            <button type="button" className="sidebar__button" onClick={() => setShowSideBar(false)}>
              <span>CLOSE</span>
            </button>
            {palettes.map((palette, index) => {
              return (
                <PredefinedPaletteOption
                  key={index}
                  colors={palette}
                  selectPalette={(palette) => {
                    isMobile && setShowSideBar(false);
                    setSelectedPalette(palette);
                    setNumColors(palette.length);              
                  }}
                />
              );
            })}
          </div>
        </div>
      )}
      <Container>
        <div className="close-import-file-container">
          <Button
            type="button"
            className="close-import-file"
            onClick={props.close}
            ariaLabel="Go back to editor"
          >
            <span>&lt; BACK TO EDITOR</span>
          </Button>
        </div>
        <Title>Pixel Art Converter</Title>
        <div className="import-your-own-container">
          <ImportYourOwnContainer
            mobile={true}
            handleFileChange={onLoadImage}
            overrideText="Import a different image."
          />
        </div>

        <LoadedImageContainer imageLoaded={imageLoaded}>
          <CanvasWrapper>
            <div
              style={{
                width: '320px',
                height: '320px',
                position: 'relative',
                margin: '0 auto',
                display: 'flex',
              }}
            >
              <canvas
                className="block m-auto canvas-preview"
                width="320"
                height="320"
                ref={canvasRef}
              />
            </div>
          </CanvasWrapper>
          <div className="d-none">
            <canvas width="320" height="320" ref={importCanvasRef} />
          </div>
          <div className="d-none">
            <canvas width="320" height="320" ref={originalCanvasRef} />
          </div>
          {/* {pixelArtImportMode && (
        <ImageDimensions
          imageDimensions={imageDimensions}
          resultDimensions={resultDimensions}
          validationError={validationError}
        />
        )} */}
          {selectedPalette.length > 0 && (
              <div className="d-flex flex-row pb-2 selected-palette">
                {selectedPalette.map((color, index) => {
                  return (
                    <div key={index} className="color-div" style={{ backgroundColor: color }}></div>
                  );
                })}
              </div>
            )}
          <div className="palette-select">
            <label htmlFor="palette">
              Color Palette
              <span data-tooltip="Use the original colors of the image (auto) or a predefined palette.">
                <PickerInfoIcon className="icon-help" />
              </span>
              </label>
            {/* radio select between auto and use a palette (sets usePredefinedPalette) */}
            <div className="radio palette-select__radio">
              <label>
                <input
                  type="radio"
                  value="auto"
                  checked={!usePredefinedPalette}
                  onChange={() => {
                    setUsePredefinedPalette(false);
                    setSelectedPalette([]);
                    setShowSideBar(false);
                  }}
                />
                Auto
              </label>
            </div>
            <div className="radio palette-select__radio">
              <label>
                <input
                  type="radio"
                  value="predefined"
                  checked={usePredefinedPalette}
                  onChange={() => setUsePredefinedPalette(true)}
                />
                Use a Predefined Palette
              </label>
            </div>
            
            {usePredefinedPalette && (
              <button
                type="button"
                aria-label="Select a palette"
                className={`palette-select__button ${
                  !usePredefinedPalette ? 'palette-select__button--disabled' : ''
                }`}
                onClick={() => setShowSideBar(!showSideBar)}
                disabled={!usePredefinedPalette}
              >
                <span>SELECT PALETTE</span>
              </button>
            )}
          </div>
          <ImageSetupSection
            canvasRef={importCanvasRef}
            frameCount={frameCount}
            setFrameCount={setFrameCount}
            numColors={numColors}
            setNumColors={setNumColors}
            pixelSize={pixelArtImportMode ? pixelSize : 10}
            setPixelSize={setPixelSize}
            setResultDimensions={setResultDimensions}
            imgSetupValidation={imgSetupValidation}
            disableDimensions={!pixelArtImportMode}
            rows={rows}
            setRows={setRows}
            setColumns={setColumns}
            columns={columns}
            numColorsPickerDisabled={usePredefinedPalette}
            isStaff={isStaff}
          />

          <button
            // variant={validationError.show ? 'action' : 'proceed'}
            onClick={onCreateProject}
            aria-label="Create a project from the loaded image"
            disabled={validationError.widthError || validationError.heightError}
            className="import-button"
          >
            <span>IMPORT IMAGE</span>
          </button>
          {validationError.show && <ValidationMessage value={validationError} />}
        </LoadedImageContainer>
      </Container>
    </>
  );
};
export default LoadFromFile;
