import { List, Map } from 'immutable';

export const doubleGrid = (frames, columns, rows, borderColor, borderActive) => {
    let doubleGrid = frames.get(0).get('grid');
    // cast the List to an array
    doubleGrid = doubleGrid.toArray();
    // the grid is one large list that is rows * columns long, we
    // need to split it into rows
    const splitGrid = [];
    for (let i = 0; i < rows; i++) {
        splitGrid.push(doubleGrid.slice(i*columns, (i+1)*columns));
    }
    // now we need to double the rows and columns
    const doubleRows = rows * 2;
    const doubleColumns = columns * 2;
    // now we need to double the rows
    const doubleSplitGrid = [];
    for (let i = 0; i < doubleRows; i++) {
        const row = [];
        for (let j = 0; j < doubleColumns; j++) {
            row.push(splitGrid[Math.floor(i/2)][Math.floor(j/2)]);
        }
        doubleSplitGrid.push(row);
    }
    // now we need to flatten the grid
    doubleGrid = doubleSplitGrid.flat();

    // convert the grid back to a List
    doubleGrid = List(doubleGrid);
    // create a new map with the double grid
    let doubleMap = Map().set('grid', doubleGrid);

    if (borderActive) {
        // add the border color
        doubleMap = doubleMap.set('borderColor', borderColor).set('borderActive', borderActive);
    }

    return doubleMap;
}