import RgbQuant from "rgbquant";
import { DEFAULT_GRID_COLUMNS, DEFAULT_GRID_ROWS } from "../../../js/constants";
export const getOptions = (numColors, palette) => {
  // convert list of 'rbg(r, g, b)' strings to [[r,g,b],[r,g,b]...] format
  if (palette) {
    palette = palette.map((color) => {
      const rgb = color.replace(/[^\d,]/g, '').split(',');
      return rgb.map((num) => parseInt(num));
    });
  }

    return {
      colors: numColors,       // desired palette size
      method: 1,               // histogram method, 2: min-population threshold within subregions; 1: global top-population
      initColors: 4096,        // # of top-occurring colors  to start with (if method = 1)
      minHueCols: 0,           // # of colors per hue group to evaluate regardless of counts, to retain low-count hues
      dithKern: null,          // dithering kernel name, see available kernels in docs below
      dithDelta: 0,            // dithering threshhold (0-1) e.g: 0.05 will not dither colors with <= 5% difference
      dithSerp: false,         // enable serpentine pattern dithering
      palette: palette ? palette : [],             // a predefined palette to start with in r,g,b tuple format: [[r,g,b],[r,g,b]...]
      reIndex: false,          // affects predefined palettes only. if true, allows compacting of sparsed palette once target palette size is reached. also enables palette sorting.
      useCache: true,          // enables caching for perf usually, but can reduce perf in some cases, like pre-def palettes
      cacheFreq: 10,           // min color occurance count needed to qualify for caching
      colorDist: "euclidean",  // method used to determine color distance, can also be "manhattan"
  };
}

const drawFileImageToCanvas = (file, canvas, imageLoadedCallback, pixelArtImportMode, numColors, canvasType, recordEvent=false) => {
  let rows=DEFAULT_GRID_ROWS
  let columns=DEFAULT_GRID_COLUMNS
  
  if (file && file.type.match('image.*')) {
    const reader = new FileReader();
    const context = canvas.getContext('2d');
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.style.display = 'none';
    if(pixelArtImportMode) {
      img.onload = function() {
        if (recordEvent){
          window.gtag("event", "import_image", {
            event_category: "engagement",
              // image dimensions
              event_label: `${img.width}x${img.height}`,
          });
        }

        if (img.width <= DEFAULT_GRID_COLUMNS && img.height <= DEFAULT_GRID_ROWS) {
          columns = img.width;
          rows = img.height;
        }

        if (canvasType == 'preview'){
          // context.canvas.width = img.width;
          // context.canvas.height = img.height;
          // context.drawImage(img, 0, 0, context.canvas.width, context.canvas.height);

          // shrink down to 32x32 and then back up to canvas size with no aliasing
          // create a new canvas 

          // clear the existing canvas
          context.clearRect(0, 0, canvas.width, canvas.height);


          var canvas2 = document.createElement('canvas');
          canvas2.width = img.width;
          canvas2.height = img.height;
          var ctx = canvas2.getContext('2d');
          ctx.imageSmoothingEnabled = false;
          context.imageSmoothingEnabled = false;
          ctx.drawImage(img, 0, 0, canvas2.width, canvas2.height);
          context.drawImage(canvas2, 0, 0, canvas2.width, canvas2.height, 0, 0, canvas.width, canvas.height);


          var q = new RgbQuant(getOptions(numColors));
    
          q.sample(canvas);
      
          // const pal = q.palette(true);
          const out = q.reduce(canvas);
      
          // replace canvas image with Uint8Array (out)
      
          const data = context.getImageData(0, 0, canvas.width, canvas.height);
          context.clearRect(0, 0, canvas.width, canvas.height);
          const newdata = new Uint8ClampedArray(out);
          data.data.set(newdata);
          context.putImageData(data, 0, 0);

          // shrink down to columns x rows and then back up to canvas size with no aliasing
          const canvas3 = document.createElement('canvas');
          canvas3.width = columns;
          canvas3.height = rows;
          const ctx3 = canvas3.getContext('2d');
          ctx3.imageSmoothingEnabled = false;
          context.imageSmoothingEnabled = false;
          ctx3.drawImage(canvas, 0, 0, canvas3.width, canvas3.height);
          // clear the existing canvas
          context.clearRect(0, 0, canvas.width, canvas.height);
          context.drawImage(canvas3, 0, 0, canvas3.width, canvas3.height, 0, 0, canvas.width, canvas.height);


          // add a grid to the preview for the rows and columns
          context.strokeStyle = '#000000';
          context.lineWidth = 0.5;
          context.beginPath();
          for (var i = 0; i <= columns; i++) {
            context.moveTo(i * (canvas.width / columns), 0);
            context.lineTo(i * (canvas.width / columns), canvas.height);
          }
          for (var j = 0; j <= rows; j++) {
            context.moveTo(0, j * (canvas.height / rows));
            context.lineTo(canvas.width, j * (canvas.height / rows));
          }
          context.stroke();
          imageLoadedCallback({ w: img.width, h: img.height });
        }
        else if (canvasType == 'reference'){
          // clear the existing canvas
          context.clearRect(0, 0, canvas.width, canvas.height);
          context.imageSmoothingEnabled = false;
          context.canvas.width = img.width;
          context.canvas.height = img.height;
          context.drawImage(img, 0, 0, context.canvas.width, context.canvas.height);
          imageLoadedCallback({ w: img.width, h: img.height });
        }
        else if (canvasType == 'import'){
          // clear the existing canvas
          context.clearRect(0, 0, canvas.width, canvas.height);
          context.imageSmoothingEnabled = false;
          context.canvas.width = columns;
          context.canvas.height = rows;
          context.drawImage(img, 0, 0, context.canvas.width, context.canvas.height);


          var q = new RgbQuant(getOptions(numColors));
    
          q.sample(canvas);
      
          // const pal = q.palette(true);
          const out = q.reduce(canvas);
      
          // replace canvas image with Uint8Array (out)
      
          const data = context.getImageData(0, 0, canvas.width, canvas.height);
          context.clearRect(0, 0, canvas.width, canvas.height);
          const newdata = new Uint8ClampedArray(out);
          data.data.set(newdata);
          context.putImageData(data, 0, 0);
          imageLoadedCallback({ w: columns, h: rows });
        }
        
        
      };
    }
    // Mosaic mode-- i.e. importing a photo to be converted to a mosaic
    else {
      img.onload = function() {
      // clear the canvas
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.imageSmoothingEnabled = false;
      context.drawImage(img, 0, 0, context.canvas.width, context.canvas.height);
      // This is a hack specifically for rendering a 32x32 image with 
      // the number of colors specified.
      if (numColors != null) {
        
       
        var q = new RgbQuant(getOptions(numColors));
    
        q.sample(canvas);
    
        // const pal = q.palette(true);
        const out = q.reduce(canvas);
    
        // replace canvas image with Uint8Array (out)
    
        const data = context.getImageData(0, 0, canvas.width, canvas.height);
        const newdata = new Uint8ClampedArray(out);
        data.data.set(newdata);
        context.putImageData(data, 0, 0);
        // shrink down to 32x32 and then back up to canvas size with no aliasing
        context.imageSmoothingEnabled = false;
        context.drawImage(canvas, 0, 0, DEFAULT_GRID_COLUMNS, DEFAULT_GRID_ROWS);
        context.drawImage(canvas, 0, 0, DEFAULT_GRID_COLUMNS, DEFAULT_GRID_ROWS, 0, 0, canvas.width, canvas.height);
      }
      imageLoadedCallback({ w: canvas.width, h: canvas.height });
    };
    }
    reader.readAsDataURL(file);
    reader.onload = function(evt) {
      if (evt.target.readyState === FileReader.DONE) {
        img.src = evt.target.result;
      }
    };
    
    return {};
  }
  return { errorType: 'notImage' };
};

export default drawFileImageToCanvas;
