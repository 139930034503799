import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../store/actions/actionCreators";
import { pixelStoreContext } from "../../../js/contexts";
import LoadFromFile from "./loadFromFile";

const ImportMode = ({actions, frames, columns, uploadedFile, close}) => {
    
    return (
        <div className="fade-in-slide-right">
            <LoadFromFile
                frames={frames}
                columns={columns}
                actions={actions}
                uploadedFile={uploadedFile}
                close={close}
            />
        </div>
    );
}

const mapStateToProps = state => {
    const frames = state.present.get('frames');
    const activeFrameIndex = frames.get('activeIndex');
    return {
        frames: frames.get('list'),
        activeFrameIndex,
        activeFrame: frames.getIn(['list', activeFrameIndex]),
        paletteGridData: state.present.getIn(['palette', 'grid']),
        columns: frames.get('columns'),
        rows: frames.get('rows'),
        cellSize: state.present.get('cellSize'),
        duration: state.present.get('duration')
    };
};
    
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actionCreators, dispatch)
});
    
const ImportModeContainer = connect(mapStateToProps, mapDispatchToProps, null, {context: pixelStoreContext})(ImportMode);
export default ImportModeContainer;
      