import React from 'react';
import CognitoForm from '@tylermenezes/cognitoforms-react';
import SimpleSpinnerForever from '../../pixel-app/src/components/SimpleSpinnerForever';

const Contact = () => (
    <div className='contact-page'>
        <div className="container fade-in-slide-right">
            <div className="row">
                <div className="col-md-12 position-relative">
                    <h1 className="text-left product-selection-header">Get in Touch</h1>
                    <div className="text-center contact-spinner">
                        <SimpleSpinnerForever />
                    </div>
                    <CognitoForm
                    formId={1}
                    accountId={`_glwLjsQOke4wG3LqaEhiQ`}
                    //   prefill={{
                    //     Name: {
                    //       First: 'John',
                    //       Last: 'Peter',
                    //     }
                    //   }}
                    //   css="* { color: red !important }"
                    />
                    
                </div>
            </div>
        </div>
    </div>
);

export default Contact;