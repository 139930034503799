import React, { useState, useEffect } from 'react';

const Mosaic = ({ rows = 4, cols = 16, pixelSize = 50 }) => {
  const [grid, setGrid] = useState([]);

  useEffect(() => {
    const newGrid = [];
    for (let i = 0; i < rows; i++) {
      const row = [];
      for (let j = 0; j < cols; j++) {
        row.push(getRandomColor());
      }
      newGrid.push(row);
    }
    setGrid(newGrid);
  }, [rows, cols]);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <>
    <div style={{ display: 'grid', gridTemplateColumns: `repeat(${cols}, ${pixelSize}px)` }}>
      {grid.map((row, rowIndex) => (
        row.map((color, colIndex) => (
          <div
            key={`${rowIndex}-${colIndex}`}
            style={{
              width: pixelSize,
              height: pixelSize,
              backgroundColor: color,
              
            }}
          ></div>
        ))
      ))}
    </div>
    {/* This div should be in the center of the grid */}
    <div
    style={{
      position: 'absolute',
      top: '52%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    }}
    >
        <h3 style={{backgroundColor:'#ffffffd4', padding:'90px 118px 61px',borderRadius: '32px', fontSize:'1.2em', fontFamily:'minecraftiaregular'}}>Embroidered</h3>
    </div>
    </>
  );
};

export default Mosaic;
