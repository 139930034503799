import { List, Map, fromJS } from 'immutable';
import shortid from 'shortid';
import * as types from '../actions/actionTypes';
import getTimeInterval from '../../utils/intervals';
import { MAX_GRID_COLUMNS, MAX_GRID_ROWS, MIN_GRID_COLUMNS, MIN_GRID_ROWS } from '../../../../js/constants';
import { clamp } from 'lodash';

const createGrid = numCells => {
  let newGrid = List();
  // Set every cell with the initial color
  for (let i = 0; i < numCells; i++) {
    newGrid = newGrid.push('rgba(0,0,0,0)');
  }
  return newGrid;
};

const resizeGrid = (grid, gridProperty, increment, dimensions) => {
  const totalCells = dimensions.rows * dimensions.columns;
  let newGrid = grid;

  if (gridProperty === 'columns') {
    // Resize by columns
    if (increment > 0) {
      // Add a row at the end
      for (let i = totalCells; i > 0; i -= dimensions.columns) {
        newGrid = newGrid.insert(i, 'rgba(0,0,0,0)');
      }
    } else {
      for (let i = totalCells; i > 0; i -= dimensions.columns) {
        newGrid = newGrid.splice(i - 1, 1);
      }
    }
  } else if (gridProperty === 'rows') {
    // Resize by rows
    if (increment > 0) {
      // Add a row at the end
      for (let i = 0; i < dimensions.columns; i++) {
        newGrid = newGrid.push('rgba(0,0,0,0)');
      }
    } else {
      // Remove the last row
      for (let i = 0; i < dimensions.columns; i++) {
        newGrid = newGrid.splice(-1, 1);
      }
    }
  }

  return newGrid;
};

const create = (cellsCount, intervalPercentage) =>
  Map({
    grid: createGrid(cellsCount),
    interval: intervalPercentage,
    key: shortid.generate()
  });

const resetIntervals = frameList =>
  frameList.map((frame, index) =>
    Map({
      grid: frame.get('grid'),
      interval: getTimeInterval(index, frameList.size),
      key: frame.get('key')
    })
  );
const getFrame = (frames, frameId) => {
  const frameList = frames.get('list');
  const frame = frameList.get(frameId);
  return Map({
    grid: frame.get('grid'),
    interval: frame.get('interval'),
    key: shortid.generate()
  });
};

const initFrames = (action = {}) => {
  const options = action.options || {};
  const columns = parseInt(options.columns, 10) || 16;
  const rows = parseInt(options.rows, 10) || 16;
  const list = resetIntervals(List([create(columns * rows)]));
  const hoveredIndex = undefined;
  return Map({
    list,
    columns,
    rows,
    activeIndex: 0,
    hoveredIndex
  });
};

const changeActiveFrame = (frames, action) => {
  const activeIndex = action.frameIndex;
  return frames.merge({ activeIndex });
};

const reorderFrame = (frames, action) => {
  const frameList = frames.get('list');
  const { selectedIndex, destinationIndex } = action;
  const targetIsBefore = selectedIndex < destinationIndex;
  const insertPosition = destinationIndex + (targetIsBefore ? 1 : 0);
  const deletePosition = selectedIndex + (targetIsBefore ? 0 : 1);
  const list = resetIntervals(
    frameList
      .splice(insertPosition, 0, getFrame(frames, selectedIndex))
      .splice(deletePosition, 1)
  );

  return frames.merge({
    list,
    activeIndex: destinationIndex
  });
};

const createNewFrame = frames => {
  const frameList = frames.get('list');
  const list = resetIntervals(
    frameList.push(create(frameList.getIn([0, 'grid']).size, 100))
  );
  return frames.merge({
    list,
    activeIndex: frameList.size
  });
};

const deleteFrame = (frames, action) => {
  const { frameId } = action;
  const frameList = frames.get('list');
  if (frameList.size <= 1) {
    return frames;
  }
  const activeIndex = frames.get('activeIndex');
  const reduceFrameIndex = activeIndex >= frameId && activeIndex > 0;
  return frames.merge(
    {
      list: resetIntervals(frameList.splice(frameId, 1))
    },
    reduceFrameIndex ? { activeIndex: frameList.size - 2 } : {}
  );
};

const duplicateFrame = (frames, action) => {
  const { frameId } = action;
  const frameList = frames.get('list');
  const list = resetIntervals(
    frameList.splice(frameId, 0, getFrame(frames, frameId))
  );
  return frames.merge({
    list,
    activeIndex: frameId + 1
  });
};

const changeDimensions = (frames, { gridProperty, increment, limitGrid }) => {

  const dimensions = {
    columns: frames.get('columns'),
    rows: frames.get('rows')
  };

  // prevent the grid from being larger than 64x32 and smaller than 8x8
  if (limitGrid){
    if (gridProperty === 'columns' && dimensions.columns + increment > MAX_GRID_COLUMNS) {
      return frames;
    } else if (gridProperty === 'columns' && dimensions.columns + increment < MIN_GRID_COLUMNS) {
      return frames;
    } else if (gridProperty === 'rows' && dimensions.rows + increment > MAX_GRID_ROWS) {
      return frames;
    } else if (gridProperty === 'rows' && dimensions.rows + increment < MIN_GRID_ROWS) {
      return frames;
    }
  }

  const list = frames.get('list').map(frame =>
    Map({
      grid: resizeGrid(frame.get('grid'), gridProperty, increment, dimensions),
      interval: frame.get('interval'),
      key: frame.get('key')
    })
  );
  return frames.merge({
    list,
    [gridProperty]: frames.get(gridProperty) + increment
  });
};

const setFrames = (frames, action) => {
  const { columns, rows, hoveredIndex } = action;
  const frameList = fromJS(action.frames);
  // reformat all the rgba strings to be rgba(r,g,b,a)
  const newFrameList = frameList.map(frame => {
    const grid = frame.get('grid').map(cell => {
      // get rid of all spaces, parens, and letters
      if (cell === '') {
        return 'rgba(0,0,0,0)';
      }
      const rgba = cell.replace(/[^\d,]/g, '').split(',');
      const r = rgba[0];
      const g = rgba[1];
      const b = rgba[2];

      let a = 1;
      // clamp alpha to 0-1
      if (rgba.length > 3) {
        a = clamp(rgba[3].split(')')[0], 0, 1);
      }
      return `rgba(${r},${g},${b},${a})`;
    });
    return frame.set('grid', grid);
  });

  return fromJS({
    list: newFrameList,
    columns,
    rows,
    activeIndex: 0,
    hoveredIndex
  });
};

const changeHoveredCell = (frames, cell) =>
  frames.merge({ hoveredIndex: cell });

export default function(frames = initFrames(), action) {
  switch (action.type) {
    case types.SET_INITIAL_STATE:
    case types.NEW_PROJECT:
      return initFrames(action);
    case types.SET_DRAWING:
      return setFrames(frames, action);
    case types.CHANGE_ACTIVE_FRAME:
      return changeActiveFrame(frames, action);
    case types.REORDER_FRAME:
      return reorderFrame(frames, action);
    case types.CREATE_NEW_FRAME:
      return createNewFrame(frames);
    case types.DELETE_FRAME:
      return deleteFrame(frames, action);
    case types.DUPLICATE_FRAME:
      return duplicateFrame(frames, action);
    case types.CHANGE_DIMENSIONS:
      return changeDimensions(frames, action);
    case types.CHANGE_HOVERED_CELL:
      return changeHoveredCell(frames, action.cell);
    default:
      return frames;
  }
}
