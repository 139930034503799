import React, { useState } from "react";

const ProductInfoDialog = ({ sizing_image_url, extra_images, viewSizingModal, setViewSizingModal, detailedDescription }) => {
    if (!sizing_image_url && extra_images.length === 0) return null;

    const [currentImage, setCurrentImage] = useState(0);

    return (
        <dialog className="sizing-modal-dialog" open={viewSizingModal} onClick={() => setViewSizingModal(false)}>
                <div className='sizing-modal-wrapper' onClick={(e) => e.stopPropagation()}>
                    <div className='sizing-modal-layout'>
                        {extra_images.length > 0 && (
                            <div className="sizing-modal-sidebar">
                                {
                                    extra_images.map((image, index) => (
                                        <img key={index} className='sizing-modal-sidebar-img' src={image} alt="Product Image" onClick={() => setCurrentImage(index)} />
                                    ))
                                }
                            </div>
                        )}
                        
                        <div className="sizing-modal-content">
                            <div className="sizing-modal-body sizing-chart-container text-center relative">
                                <div className="sizing-modal-img-wrapper">
                                    <div style={{maxHeight: '100%'}}>
                                    {
                                        (currentImage == 0 && sizing_image_url) ? (
                                            <img className='sizing-chart' src={sizing_image_url} alt="Sizing Chart" />
                                        ) : (
                                            <img className='sizing-chart' src={extra_images[currentImage]} alt="Product Image" />
                                        )
                                    }
                                    </div>
                                </div>
                                <div className="sizing-modal-desc text-left">
                                    <h2 className="">
                                        Description
                                    </h2>
                                    <p className="">
                                        {detailedDescription}
                                    </p>
                                </div>
                            </div>
                            <button type="button" className="sizing-modal-close btn btn-primary" aria-label="Close" onClick={() => setViewSizingModal(false)} >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
        </dialog>
    );
}


export default ProductInfoDialog;