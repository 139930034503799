import React, { useEffect, useState } from 'react';
import { connect, createDispatchHook, createSelectorHook, useSelector } from 'react-redux';
import { setEmbroidery, uploadImage } from '../../../js/store/embroidery';
import renderCanvasGIF from '../utils/canvasGIF';
import { useNavigate } from 'react-router-dom';
import { mainStoreContext, pixelStoreContext } from '../../../js/contexts';
import { doubleGrid } from '../../../js/utils/doubleGrid';
import { DOUBLE_DENSITY_THRESHOLD_COLUMNS as DDTC, DOUBLE_DENSITY_THRESHOLD_ROWS as DDTR } from '../../../js/constants';
import { hexToRgb } from './ColorPicker';

// Passing through mainDispatch from Root to share state between apps
// This is, hopefully, the only place where we need to hand off mainDispatch
const Embroider = ({ frames, activeFrame, grid, columns, rows, borderColor, borderActive }) => {
  const navigate = useNavigate();
  const dispatch = createDispatchHook(mainStoreContext);
  const useSelector = createSelectorHook(mainStoreContext);
  const isStaff = useSelector((state) => state.restCheck?.whoami?.payload?.is_staff);
  const mainDispatch = dispatch();
  const [fetchingFile, setFetchingFile] = useState(false);
  const [mmPerStitch, setMmPerStitch] = useState(2);

  const handleClick = () => {

    // Make sure there are less than 13 colors in the grid
    const activeColors = grid.reduce((acc, color) => {
      if (!acc.includes(color) && color !== '' && color !== 'rgba(0,0,0,0)') {
        acc.push(color);
      }
      return acc;
    }, []);

    let borderNote = "";
    // also add the border color if it is active and not already in the active colors
    if (borderActive && borderColor && !activeColors.includes(hexToRgb(borderColor))){
      activeColors.push(hexToRgb(borderColor));
      borderNote = "\n\nNote that the border color is included in the count.";
    }

    if (activeColors.length > 12) {
      alert(`Too many colors!\nPlease remove or combine some colors before embroidering.${borderNote}`);
      return;
    }
    if (activeColors.length === 0) {
      alert(`It doesn't look like there is anything to embroider! Try importing an image or drawing on the canvas.`);
      return;
    }
    handleSetEmbroidery(activeColors.length);
    navigate('/embroider');
  };

  const handleSetEmbroidery = (numColors) => {
    window.gtag("event", "clicked_embroider", {
      event_category: "engagement",
      event_label: `${columns}x${rows} ${numColors} colors`,
    });

    const border = borderActive ? borderColor : null;

    mainDispatch(setEmbroidery({ grid, columns, rows, borderColor: border }));
    const dataUrl = renderCanvasGIF({
      type: 'single',
      frames: frames,
      activeFrame: activeFrame,
      columns,
      rows,
      duration: 0,
      skipDownload: true,
    });
    mainDispatch(uploadImage({ dataUrl, rows, columns, borderColor: border }));
  };

  const handleExport = () => {
    handleSetEmbroidery();
    navigate('/export');
  };

  const handlePESClick = () => {
    // send a post request to /pes with the grid, rows, and columns as
    // a form
    const formData = new FormData();
    if (parseFloat(mmPerStitch) > 2.5 && columns <= DDTC && rows <= DDTR) {
      const dGrid = doubleGrid(frames, columns, rows).get('grid');
      formData.append('grid', JSON.stringify(dGrid));
      formData.append('rows', rows*2);
      formData.append('columns', columns*2);
      formData.append('mm', mmPerStitch / 2);
    }
    else{
      formData.append('grid', JSON.stringify(grid));
      formData.append('rows', rows);
      formData.append('columns', columns);
      formData.append('mm', mmPerStitch);
    }
    
    formData.append('border_color', borderActive ? borderColor : null);
    
    setFetchingFile(true);
    // we get a response as an octet stream file attachment
    fetch('/pes/', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.blob())
      .then((blob) => {
        setFetchingFile(false);
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `embroidery.pes`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return true;
      })
      .catch((error) => {
        setFetchingFile(false);
        console.log(error);
      });
  };

  return (
    <>
      <button type="button" className="embroider" onClick={handleClick}>
        EMBROIDER
      </button>
      {isStaff && (
        <>
          <button type="button" className="download-pes" onClick={handlePESClick}>
            {fetchingFile ? 'FETCHING...' : 'DOWNLOAD PES'}
          </button>
          <div className="embroidery-options">
            <label htmlFor="mm-per-stitch">mm per stitch</label>
            <select className="form-select" aria-label="Default select example" id="mm-per-stitch"
                onChange={
                    (e) => setMmPerStitch(e.target.value)
            }>
                <option value="1.5">1.5mm</option>
                <option value="2" selected>2mm</option>
                <option value="2.5">2.5mm</option>
                {columns <= DDTC && rows <= DDTR && (
                  <>
                    <option value="3">1.5mm x 2</option>
                    <option value="4">2mm x 2</option>
                    <option value="5">2.5mm x 2</option>
                  </>
                )}
                
            </select>
          </div>

          <button type="button" className="reset mt-4" onClick={handleExport}>
            EXPORT PHOTOS
          </button>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const frames = state.present.get('frames');
  const activeFrameIndex = frames.get('activeIndex');
  return {
    frames: frames.get('list'),
    activeFrame: frames.getIn(['list', activeFrameIndex]),
    grid: state.present.get('frames').get('list').get(0).get('grid'),
    borderColor: state.present.get('frames').get('list').get(0).get('borderColor'),
    borderActive: state.present.get('frames').get('list').get(0).get('borderActive'),
    columns: state.present.get('frames').get('columns'),
    rows: state.present.get('frames').get('rows'),
  };
};

const EmbroiderContainer = connect(mapStateToProps, null, null, { context: pixelStoreContext })(
  Embroider
);
export default EmbroiderContainer;
