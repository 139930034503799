import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../store/actions/actionCreators';
import { pixelStoreContext } from '../../../js/contexts';
import imageUploadSVG from '../css/icons/image-upload.svg';

const ImportYourOwn = ({mobile, handleFileChange, overrideText}) => {

  return (
    <>
    <input id="file" type="file" aria-hidden="true" placeholder="Upload an image" className="hidden" onChange={handleFileChange}/>
    <label className="import-your-own" htmlFor="file">
    <div className='d-flex'>
        <img src={imageUploadSVG} className='image-upload-icon'/>
        </div>
        <p>
          {overrideText ? overrideText : mobile ? "Import your own image!" : "Import your own image!"}
        </p>
    </label>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch)
});

const ImportYourOwnContainer = connect(
  null,
  mapDispatchToProps,
  null,
  { context: pixelStoreContext }
)(ImportYourOwn);
export default ImportYourOwnContainer;
