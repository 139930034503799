import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { List } from 'immutable';
import api from './api';
import { clamp } from 'lodash';

// Thunks
export const fetchRestCheck = createAsyncThunk('restCheck/fetch', async () => {
  const res = await api.get('/api/rest/rest-check/');
  return res.data;
});

// fetchWhoami
export const fetchWhoami = createAsyncThunk('whoami/fetch', async () => {
  const res = await api.get('/api/rest/whoami/');
  return res.data;
});

// Reducer
export const restCheckReducer = createSlice({
  name: 'restCheck',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRestCheck.pending, (state) => {
      state.data = {
        isLoading: false,
      };
    });
    builder.addCase(fetchRestCheck.fulfilled, (state, action) => {
      state.data = {
        isLoading: false,
        payload: action.payload,
      };
    });
    builder.addCase(fetchRestCheck.rejected, (state, action) => {
      state.data = {
        isLoading: false,
        error: action.error,
      };
    });
    builder.addCase(fetchWhoami.pending, (state) => {
      state.whoami = {
        isLoading: false,
      };
    });
    builder.addCase(fetchWhoami.fulfilled, (state, action) => {
      state.whoami = {
        isLoading: false,
        payload: action.payload,
      };
    });
    builder.addCase(fetchWhoami.rejected, (state, action) => {
      state.whoami = {
        isLoading: false,
        error: action.error,
      };
    });
  },
}).reducer;

// post to /upload/ given a dataUrl, rows, and columns
export const uploadImage = createAsyncThunk('uploadImage/fetch', async ({dataUrl, rows, columns, borderColor}) => {
  const formData = new FormData();
  formData.append('image', dataUrl);
  formData.append('rows', rows);
  formData.append('columns', columns);
  formData.append('border_color', borderColor || "");
  const res = await api.post('/upload/', formData);
  return res.data;
});

// get dataUrl from /get-image/ given a uuid
export const getImage = createAsyncThunk('getImage/fetch', async (uuid, { dispatch }) => {
  const res = await api.get(`/get-image/${uuid}/`);
  const [grid, rows, columns] = await getGridFromDataUrl(res.data.data_url, res.data.rows, res.data.columns);
  dispatch(setEmbroidery({ grid, rows: rows, columns: columns, borderColor: res.data.border_color }));
  return {
    data_url: res.data.data_url,
    rows: rows,
    columns: columns,
    borderColor: res.data.border_color,
  };
});

// getGridFromDataUrl
export const getGridFromDataUrl = async (dataUrl, rows, columns) => {
  const img = new Image();
  img.src = dataUrl;
  await img.decode();
  const canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;
  if(!columns) columns = img.width;
  if(!rows) rows = img.height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0);
  const imageData = ctx.getImageData(0, 0, img.width, img.height);
  let grid = List();
  for (let i = 0; i < rows; i++) {
    for (let j = 0; j < columns; j++) {
      const index = (i * columns + j) * 4;
      const r = imageData.data[index];
      const g = imageData.data[index + 1];
      const b = imageData.data[index + 2];
      const a = clamp(imageData.data[index + 3] / 255, 0, 1);
      grid = grid.push("rgba(" + r + "," + g + "," + b + "," + a + ")");
    }
  }
  return [grid, rows, columns];
};

// Reducer for embroidery
export const embroiderySlice = createSlice({
  name: 'embroidery',
  initialState: {
    embroiderySelected: false,
    embroideryGrid: null,
    columns: 0,
    rows: 0,
    borderColor: null,
    preview: null,
    previewDouble: null,
    previewId: null,
  },
  reducers: {
    setEmbroidery: (state, action) => {
      state.embroiderySelected = true;
      state.embroideryGrid = action.payload.grid;
      state.columns = action.payload.columns;
      state.rows = action.payload.rows;
      state.borderColor = action.payload.borderColor;
      state.preview = null;
      state.previewId = null;
      state.previewDouble = null;
    },
    resetEmbroidery: state => {
      state.embroiderySelected = false;
      state.embroideryGrid = null;
      state.columns = 0;
      state.rows = 0;
      state.borderColor = null;
    },
    setPreview: (state, action) => {
      state.preview = action.payload.data_url || state.preview
      state.previewId = action.payload.id || state.previewId
      state.previewDouble = action.payload.data_url_double === false ? null : action.payload.data_url_double || state.previewDouble
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadImage.pending, (state) => {
      state.upload = {
        isLoading: true,
      };
    });
    builder.addCase(uploadImage.fulfilled, (state, action) => {
      state.upload = {
        isLoading: false,
        payload: action.payload,
      };
    });
    builder.addCase(uploadImage.rejected, (state, action) => {
      state.upload = {
        isLoading: false,
        error: action.error,
      };
    });
    builder.addCase(getImage.pending, (state) => {
      state.image = {
        isLoading: true,
      };
    });
    builder.addCase(getImage.fulfilled, (state, action) => {
      state.image = {
        isLoading: false,
        payload: action.payload,
      };
    });
    builder.addCase(getImage.rejected, (state, action) => {
      state.image = {
        isLoading: false,
        error: action.error,
      };
    });
  }
});

// Exporting the actions
export const { setEmbroidery, resetEmbroidery, setProducts, setPreview } = embroiderySlice.actions;

// export the reducer
export const embroideryReducer = embroiderySlice.reducer;
