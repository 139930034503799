import React, {useState} from 'react';
import renderCanvasGIF from '../utils/canvasGIF';
import { createDispatchHook } from 'react-redux';
import { mainStoreContext } from '../../../js/contexts'
import { uploadImage } from '../../../js/store/embroidery';

const DownloadDrawing = props => {
  const dispatch = createDispatchHook(mainStoreContext);
  const mainDispatch = dispatch();
  const [dataUrl, setDataUrl] = useState(null);

  const upload = (data, rows, columns) => {
    mainDispatch(uploadImage({dataUrl:data, rows, columns}));
  }

  const DOWNLOAD_MESSAGE = 'Downloading...';
  const download = type => {
    const { frames, activeFrame, columns, rows, duration } = props;
    props.actions.sendNotification(DOWNLOAD_MESSAGE);
    const url = renderCanvasGIF({
      type,
      frames,
      activeFrame,
      columns,
      rows,
      duration
    });
    setDataUrl(url);
  };

  return (
    <>
    <button
      type="button"
      className="download-btn"
      onClick={() => {
        download();
      }}
    >
      DOWNLOAD
    </button>
    {/* <button
      type="button"
      className="download-btn"
      onClick={() => {
        upload(dataUrl, props.rows, props.columns);
      }}
    >
      UPLOAD
    </button> */}
    </>
  );
};

export default DownloadDrawing;
