export const palettes = [
    [
        "rgb(138, 183, 222)",
        "rgb(61, 124, 196)",
        "rgb(51, 71, 96)",
        "rgb(255, 174, 26)",
        "rgb(255, 126, 69)"
    ],

    [
        "rgb(252, 216, 240)",
        "rgb(255, 185, 219)",
        "rgb(138, 183, 222)",
        "rgb(168, 221, 233)",
        "rgb(190, 162, 214)",
        "rgb(182, 122, 174)"
    ],

    [
        "rgb(75, 114, 66)",
        "rgb(186, 220, 125)",
        "rgb(138, 100, 67)",
        "rgb(233, 230, 215)",
        "rgb(86, 73, 71)",
        "rgb(49, 77, 131)"
    ],

    [
        "rgb(189, 106, 46)",
        "rgb(222, 158, 87)",
        "rgb(255, 128, 114)",
        "rgb(134, 79, 61)",
        "rgb(145, 148, 119)",
        "rgb(228, 173, 126)"
    ],

    [
        "rgb(47, 78, 130)",
        "rgb(120, 167, 221)",
        "rgb(193, 222, 233)",
        "rgb(194, 211, 222)",
        "rgb(191, 167, 209)"
    ],

    [
        "rgb(211, 52, 69)",
        "rgb(255, 120, 56)",
        "rgb(255, 184, 84)",
        "rgb(255, 206, 54)",
        "rgb(255, 242, 142)",
        "rgb(59, 69, 87)"
    ],

    [
        "rgb(205, 99, 77)",
        "rgb(120, 126, 82)",
        "rgb(0, 163, 144)",
        "rgb(103, 106, 105)",
        "rgb(141, 104, 63)",
        "rgb(198, 219, 146)"
    ],

    [
        "rgb(134, 79, 61)",
        "rgb(124, 84, 73)",
        "rgb(144, 102, 84)",
        "rgb(143, 111, 89)",
        "rgb(145, 137, 112)",
        "rgb(126, 149, 98)",
        "rgb(55, 115, 88)",
        "rgb(80, 104, 79)"
    ],

    [
        "rgb(255, 236, 215)",
        "rgb(82, 117, 180)",
        "rgb(80, 104, 79)",
        "rgb(255, 88, 74)",
        "rgb(60, 62, 65)",
        "rgb(244, 243, 248)"
    ],

    [
        "rgb(255, 255, 255)",
        "rgb(52, 54, 55)",
        "rgb(221, 57, 72)",
        "rgb(255, 227, 92)",
        "rgb(0, 123, 185)",
        "rgb(129, 191, 91)"
    ],

    [
        "rgb(255, 185, 219)",
        "rgb(250, 146, 193)",
        "rgb(255, 122, 144)",
        "rgb(255, 120, 56)",
        "rgb(149, 61, 94)",
        "rgb(105, 57, 61)"
    ],

    [
        "rgb(228, 173, 126)",
        "rgb(167, 198, 214)",
        "rgb(228, 183, 221)",
        "rgb(78, 156, 176)",
        "rgb(204, 201, 204)",
        "rgb(96, 72, 107)"
    ],

    [
        "rgb(239, 222, 211)",
        "rgb(0, 146, 94)",
        "rgb(176, 60, 82)",
        "rgb(128, 116, 173)",
        "rgb(75, 108, 157)",
        "rgb(182, 122, 174)"
    ],

    [
        "rgb(75, 76, 89)",
        "rgb(131, 128, 108)",
        "rgb(167, 198, 214)",
        "rgb(96, 72, 107)",
        "rgb(82, 117, 180)",
        "rgb(51, 71, 96)"
    ],

    [
        "rgb(255, 179, 79)",
        "rgb(194, 211, 222)",
        "rgb(210, 116, 162)",
        "rgb(174, 81, 65)",
        "rgb(78, 156, 176)",
        "rgb(255, 186, 198)"
    ],

    [
        "rgb(92, 79, 77)",
        "rgb(107, 165, 201)",
        "rgb(255, 184, 84)",
        "rgb(157, 92, 76)",
        "rgb(217, 154, 159)",
        "rgb(225, 219, 211)"
    ],

    [
        "rgb(51, 68, 107)",
        "rgb(233, 230, 215)",
        "rgb(65, 142, 170)",
        "rgb(129, 76, 89)",
        "rgb(191, 144, 168)",
        "rgb(0, 128, 116)"
    ],

    [
        "rgb(255, 191, 67)", "rgb(230, 70, 109)", "rgb(255, 179, 187)", "rgb(230, 201, 188)"
    ],

    [
        "rgb(0, 146, 94)", "rgb(141, 207, 107)", "rgb(255, 185, 219)", "rgb(179, 154, 170)"
    ],

    [
        "rgb(182, 127, 72)", "rgb(179, 142, 125)", "rgb(232, 196, 127)", "rgb(235, 185, 119)"
    ],

    [
        "rgb(255, 191, 67)", "rgb(250, 232, 112)", "rgb(255, 234, 133)", "rgb(239, 255, 95)"
    ],

    [
        "rgb(141, 207, 107)", "rgb(255, 250, 211)", "rgb(203, 235, 124)", "rgb(250, 146, 193)"
    ],

    [
        "rgb(255, 122, 144)", "rgb(255, 208, 118)", "rgb(249, 112, 80)", "rgb(255, 165, 195)"
    ],

    [
        "rgb(255, 214, 233)",
        "rgb(255, 185, 219)",
        "rgb(230, 164, 172)",
        "rgb(255, 165, 195)",
        "rgb(255, 191, 67)"
    ],

    [
        "rgb(176, 76, 60)",
        "rgb(134, 63, 83)",
        "rgb(129, 55, 73)",
        "rgb(105, 72, 65)",
        "rgb(255, 196, 178)"
    ],

    [
        "rgb(160, 192, 201)",
        "rgb(107, 165, 201)",
        "rgb(162, 189, 217)",
        "rgb(138, 183, 222)",
        "rgb(84, 153, 195)"
    ],

    [
        "rgb(232, 196, 127)",
        "rgb(255, 234, 133)",
        "rgb(141, 207, 107)",
        "rgb(255, 250, 211)",
        "rgb(255, 184, 84)"
    ],

    [
        "rgb(255, 164, 146)", "rgb(253, 147, 120)", "rgb(255, 156, 101)", "rgb(255, 184, 84)"
    ],

    [
        "rgb(30, 81, 163)", "rgb(51, 68, 107)", "rgb(56, 104, 148)", "rgb(77, 64, 106)"
    ],

    [
        "rgb(239, 222, 211)",
        "rgb(229, 296, 227)",
        "rgb(179, 154, 170)",
        "rgb(182, 160, 209)",
        "rgb(191, 167, 209)"
    ],

    [
        "rgb(255, 255, 255)",
        "rgb(232, 224, 211)",
        "rgb(219, 182, 153)",
        "rgb(180, 172, 145)",
        "rgb(131, 128, 108)"
    ],

    [
        "rgb(215, 164, 127)",
        "rgb(144, 102, 84)",
        "rgb(145, 137, 112)",
        "rgb(120, 83, 67)",
        "rgb(95, 76, 69)"
    ],

    [
        "rgb(0, 104, 139)", "rgb(0, 104, 139)", "rgb(61, 124, 196)", "rgb(77, 64, 106)"
    ],

    [
        "rgb(255, 232, 9)", "rgb(205, 160, 83)", "rgb(140, 105, 176)", "rgb(102, 81, 155)"
    ],

    [
        "rgb(255, 166, 139)", "rgb(182, 121, 64)", "rgb(134, 79, 61)", "rgb(183, 119, 72)"
    ],

    [
        "rgb(194, 122, 82)", "rgb(176, 76, 60)", "rgb(151, 120, 78)", "rgb(151, 120, 78)"
    ],

    [
        "rgb(160, 192, 201)",
        "rgb(107, 165, 201)",
        "rgb(162, 189, 217)",
        "rgb(138, 183, 222)",
        "rgb(84, 153, 195)",
        "rgb(61, 124, 196)",
        "rgb(51, 104, 178)",
        "rgb(30, 81, 163)",
        "rgb(51, 68, 107)",
        "rgb(56, 104, 148)"
    ],

    [
        "rgb(224, 197, 147)", "rgb(206, 135, 0)", "rgb(228, 183, 221)", "rgb(175, 57, 95)"
    ],

    [
        "rgb(205, 160, 83)",
        "rgb(140, 105, 176)",
        "rgb(102, 81, 155)",
        "rgb(255, 166, 139)",
        "rgb(182, 121, 64)"
    ],

    [
        "rgb(182, 121, 64)",
        "rgb(193, 186, 177)",
        "rgb(60, 62, 65)",
        "rgb(179, 165, 139)",
        "rgb(206, 155, 89)",
        "rgb(247, 230, 199)",
        "rgb(255, 186, 192)",
        "rgb(254, 246, 229)"
    ],

    [
        "rgb(211, 52, 69)", "rgb(107, 165, 201)", "rgb(222, 239, 257)", "rgb(52, 54, 55)"
    ],

    [
        "rgb(75, 76, 89)",
        "rgb(96, 68, 110)",
        "rgb(175, 57, 95)",
        "rgb(246, 131, 72)",
        "rgb(255, 242, 142)",
        "rgb(138, 183, 222)",
        "rgb(140, 158, 172)"
    ],

    [
        "rgb(86, 73, 71)",
        "rgb(156, 96, 142)",
        "rgb(255, 186, 192)",
        "rgb(175, 131, 57)",
        "rgb(222, 158, 87)",
        "rgb(233, 230, 215)",
        "rgb(132, 164, 91)"
    ],

    [
        "rgb(52, 54, 55)",
        "rgb(75, 76, 89)",
        "rgb(65, 142, 170)",
        "rgb(129, 76, 89)",
        "rgb(92, 88, 153)",
        "rgb(191, 210, 229)",
        "rgb(204, 201, 204)"
    ],

    [
        "rgb(95, 76, 69)",
        "rgb(215, 164, 127)",
        "rgb(255, 255, 255)",
        "rgb(212, 205, 182)",
        "rgb(182, 225, 219)",
        "rgb(229, 184, 104)",
        "rgb(255, 242, 142)",
        "rgb(253, 147, 120)"
    ],


    [
        "rgb(255, 255, 255)",
        "rgb(52, 54, 55)",
        "rgb(129, 118, 73)",
        "rgb(255, 185, 219)",
        "rgb(138, 183, 222)",
        "rgb(239, 222, 211)",
        "rgb(134, 63, 83)"
    ],

    [
        "rgb(255, 184, 84)",
        "rgb(253, 147, 120)",
        "rgb(194, 122, 82)",
        "rgb(255, 245, 158)",
        "rgb(160, 192, 201)",
        "rgb(239, 222, 211)"
    ],

    [
        "rgb(168, 221, 233)",
        "rgb(38, 170, 152)",
        "rgb(221, 234, 234)",
        "rgb(55, 115, 88)",
        "rgb(47, 78, 130)"
    ],

    [
        "rgb(180, 172, 145)",
        "rgb(77, 64, 103)",
        "rgb(204, 201, 204)",
        "rgb(152, 133, 126)",
        "rgb(202, 190, 183)",
        "rgb(206, 93, 83)"
    ],

    [
        "rgb(105, 72, 65)",
        "rgb(176, 124, 92)",
        "rgb(227, 55, 66)",
        "rgb(255, 255, 255)",
        "rgb(250, 147, 190)",
        "rgb(154, 90, 70)"
    ],
];
