import React from 'react';
import CookieConsent from 'react-cookie-consent';
import PreviewBox from './PreviewBox';
import PixelCanvasContainer from './PixelCanvas';
import ColorPickerContainer from './ColorPicker';
import ModalContainer from './Modal';
import DimensionsContainer from './Dimensions';
import KeyBindings from './KeyBindings';
import EraserContainer from './Eraser';
import BucketContainer from './Bucket';
import MoveContainer from './Move';
import EyedropperContainer from './Eyedropper';
import PaletteGridContainer from './PaletteGrid';
import ResetContainer from './Reset';
import EmbroiderContainer from './Embroider';
import SaveDrawingContainer from './SaveDrawing';
import NewProjectContainer from './NewProject';
import SimpleNotificationContainer from './SimpleNotification';
import SimpleSpinnerContainer from './SimpleSpinner';
import UndoRedoContainer from './UndoRedo';
import initialSetup from '../utils/startup';
import drawHandlersProvider from '../utils/drawHandlersProvider';
import ActivePaletteGridContainer from './ActivePaletteGrid';
import BulkEraseContainer from './BulkErase';
import ImportYourOwnContainer from './ImportYourOwn';
import ImportModeContainer from './ImportMode';

import Joyride, { STATUS } from 'react-joyride';

import { completeTutorial, isTutorialCompleted } from '../utils/storage';

export default class App extends React.Component {
  constructor() {
    super();
    this.state = {
      modalType: null,
      modalOpen: false,
      helpOn: true,
      isMobile: !window.matchMedia('(min-width: 731px)').matches,
      importMode: false,
      uploadedFile: null,
      run: !isTutorialCompleted(localStorage),
      steps: [
        {
          target: 'body',
          content: 'Welcome to the Pixel Stitch editor. This is where you can create your own custom embroidered pixel art!',
          placement: 'center',
        },
        {
          target: '#canvas',
          content: 'This is the canvas- your interactive editing area. Your embroidery will reflect the pixel art created here.',
        },
        {
          target: '#import-your-own',
          content: 'You can import an image file here. Pixel art works best, but you can import any image you like! Note that this will replace the current drawing.',
        },
        {
          target: '#tools',
          content: 'Here are a few common editing tools. Hover over these after the tour if you need a refresher.',
        },
        {
          target: '#palette',
          content: 'This is your color palette. The currently selected color is highlighted in white. You can modify any of these colors by using the color selector tool above.',
        },
        {
          target: '#active-palette',
          content: 'This is your active palette. It shows the colors that are currently used in your drawing. You can select these colors the same way you would in the palette. Additionally, you can use the eyedropper and delete buttons to easily combine or remove any colors from your canvas.',
        },
        {
          target: '#active-palette',
          content: 'You can add a border to your embroidery design by checking the "Add Border" box. This will stitch a small border around your design using a color of your choosing.',
        },
        {
          target: '#dimensions',
          content: 'You can change the size of your canvas here. Note that the canvas limit is 64 columns by 32 rows (64x32).',
        },
        {
          target: '#embroider',
          content: 'When you are ready, click this button to embroider your art! You can always come back and edit your design. Have fun!',
        }
      ],
    };
    Object.assign(this, drawHandlersProvider(this));
    this.handleResize = this.handleResize.bind(this); // Bind the handleResize method to this context
    this.handleFileChange = this.handleFileChange.bind(this);
    this.closeImportMode = this.closeImportMode.bind(this);
  }

  componentDidMount() {
    const { store, dispatch } = this.props;
    // only do setup once on load, we want it to use the state
    // if it is already there
    // Probably a better way to do this
    if (store.getState().past.length === 0) {
      initialSetup(dispatch, localStorage);
    }
    const handler = (e) => this.setState({ isMobile: !e.matches });
    window.matchMedia('(min-width: 731px)').addEventListener('change', handler);
    // auto scroll to top on page load
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    window.removeEventListener('change', this.handleResize);
  }

  handleResize() {
    const currentSize = !window.matchMedia('(min-width: 731px)').matches;
    if (currentSize !== this.state.isMobile) {
      this.setState({ isMobile: currentSize });
    }
  }

  handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (status === STATUS.FINISHED){
      window.gtag("event", "finished_tutorial", {
        event_category: "engagement",
        event_label: `Tutorial finished`,
      });
    }

    if (finishedStatuses.includes(status)) {
      completeTutorial(localStorage);
      this.setState({ run: false });
    }
  };

  changeModalType(type) {
    this.setState({
      modalType: type,
      modalOpen: true,
    });
  }

  handleFileChange(e) {
    const file = e.target.files[0];
    this.setState({
      // modalType: 'load',
      // modalOpen: true,
      uploadedFile: file,
      importMode: true,
    });
  }

  closeModal() {
    this.setState({
      modalOpen: false,
    });
  }

  setRun() {
    // send gtag event
    window.gtag("event", "started_tutorial", {
      event_category: "engagement",
      event_label: `Tutorial started`,
    });
    this.setState({
      run: true,
    });
  }

  closeImportMode() {
    this.setState({
      importMode: false,
    });
  }

  toggleHelp() {
    const { helpOn } = this.state;
    this.setState({ helpOn: !helpOn });
  }

  setImportMode(mode) {
    this.setState({ importMode: mode });
  }

  showHelp() {
    return this.state.helpOn && !this.state.isMobile;
  }

  editorView(modalType, modalOpen, isMobile) {
    return (
      <div className="app__background">
        <div
          className="app__main fade-in-slide-right"
          onMouseUp={this.onMouseUp}
          onTouchEnd={this.onMouseUp}
          onTouchCancel={this.onMouseUp}
        >
          <SimpleSpinnerContainer />
          <SimpleNotificationContainer fadeInTime={1000} fadeOutTime={1500} duration={1500} />
          <div className="app__central-container">
            <div className="app__mobile--container">
              <div className="app__mobile--group">
                {isMobile && (
                  <>
                    <div id="import-your-own">
                      <ImportYourOwnContainer
                        mobile={true}
                        handleFileChange={this.handleFileChange}
                      />
                    </div>
                    <PreviewBox
                      helpOn={this.showHelp()}
                      callback={() => {
                        this.changeModalType('preview');
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            {isMobile && (
              <div className="center col-2-4" id="canvas">
                <PixelCanvasContainer drawHandlersFactory={this.drawHandlersFactory} />
              </div>
            )}
            <div className="left col-1-4">
              <div className="app__left-side">
                <div className="app__mobile--container max-width-container">
                  <div className="app__mobile--group">
                    {isMobile && (
                      <div id="tools">
                        <div data-tooltip={this.showHelp() ? 'Undo (CTRL+Z) Redo (CTRL+Y)' : null}>
                          <UndoRedoContainer />
                        </div>
                        <div className="app__tools-wrapper grid-3">
                        <div
                            data-tooltip={
                              this.showHelp()
                                ? 'Select a new color (P)'
                                : null
                            }
                            className="mobile-button-no-border"
                          >
                            <ColorPickerContainer isMobile />
                          </div>
                          
                          <div
                            data-tooltip={
                              this.showHelp() ? 'Sample a color from your drawing (O)' : null
                            }
                            className="mobile-button"
                          >
                            <EyedropperContainer />
                          </div>
                          <div
                            data-tooltip={
                              this.showHelp() ? 'Fill an area based on color similarity (B)' : null
                            }
                            className="mobile-button"
                          >
                            <BucketContainer />
                          </div>
                          <div
                            data-tooltip={this.showHelp() ? 'Remove colors (E)' : null}
                            className="mobile-button"
                          >
                            <EraserContainer />
                          </div>
                          <div
                            data-tooltip={
                              this.showHelp()
                                ? 'Remove an area based on color similarity (R)'
                                : null
                            }
                            className="mobile-button"
                          >
                            <BulkEraseContainer />
                          </div>
                          <div
                            data-tooltip={
                              this.showHelp() ? 'Move your drawing around the canvas (M)' : null
                            }
                            className="mobile-button"
                          >
                            <MoveContainer />
                          </div>
                        </div>
                      </div>
                    )}
                    {!isMobile && (
                      <>
                        <div id="import-your-own">
                          <ImportYourOwnContainer handleFileChange={this.handleFileChange} />
                        </div>
                        <div data-tooltip={this.showHelp() ? 'New project' : null}>
                          <NewProjectContainer />
                        </div>
                        <div className="app__load-save-container">
                          <button
                            type="button"
                            className="app__load-button"
                            onClick={() => {
                              this.changeModalType('load');
                            }}
                            data-tooltip={
                              this.showHelp() ? 'Load projects you stored before' : null
                            }
                          >
                            SAVED
                          </button>
                          <div data-tooltip={this.showHelp() ? 'Save your project' : null}>
                            <SaveDrawingContainer />
                          </div>
                        </div>
                        <div id="tools">
                          <div data-tooltip={this.showHelp() ? 'Undo (CTRL+Z) Redo (CTRL+Y)' : null}>
                            <UndoRedoContainer />
                          </div>
                          <div className="app__tools-wrapper grid-3">
                          <div
                              data-tooltip={
                                this.showHelp()
                                  ? 'Select a new color (P)'
                                  : null
                              }
                            >
                              <ColorPickerContainer />
                            </div>
                            <div
                              data-tooltip={
                                this.showHelp() ? 'Sample a color from your drawing (O)' : null
                              }
                            >
                              <EyedropperContainer />
                            </div>
                            <div
                              data-tooltip={
                                this.showHelp() ? 'Fill an area based on color similarity (B)' : null
                              }
                            >
                              <BucketContainer />
                            </div>
                            <div data-tooltip={this.showHelp() ? 'Remove colors (E)' : null}>
                              <EraserContainer />
                            </div>
                            <div
                              data-tooltip={
                                this.showHelp()
                                  ? 'Remove an area based on color similarity (R)'
                                  : null
                              }
                            >
                              <BulkEraseContainer />
                            </div>
                            <div
                              data-tooltip={
                                this.showHelp() ? 'Move your drawing around the canvas (M)' : null
                              }
                            >
                              <MoveContainer />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="app__mobile--group" id="palette">
                    <PaletteGridContainer />
                  </div>
                  {isMobile && (
                    <>
                    <div className="app__mobile--group">
                      <ActivePaletteGridContainer showHelp={this.state.helpOn} />
                    </div>
                    <div className="app__mobile--group max-width-container-centered {">
                    <div
                      data-tooltip={this.showHelp() ? 'Embroider your art!' : null}
                      className="max-width-container-centered"
                      id="embroider"
                    >
                      <EmbroiderContainer />
                    </div>
                  </div>
                  </>
                  )}
                </div>
                <div className="app__mobile--container max-width-container">
                  {isMobile && (
                    <div
                      data-tooltip={this.showHelp() ? 'Number of columns and rows' : null}
                      className="max-width-container-centered"
                      id="dimensions"
                    >
                      <DimensionsContainer />
                    </div>
                  )}
                  <div className="app__mobile--group">
                    <div className="app__social-container">
                      <div
                        data-tooltip={
                          this.showHelp() ? 'Download your creation as a PNG' : null
                        }
                        className='mt-3'
                      >
                        <button
                          type="button"
                          aria-label="Download"
                          className="app__download-button"
                          onClick={() => {
                            this.changeModalType('download');
                          }}
                        >
                          DOWNLOAD
                        </button>
                      </div>
                      <div
                        data-tooltip={
                          this.showHelp() ? 'Take a tour of the pixel art editor' : null
                        }
                        className='mt-3'
                      >
                        <button
                          type="button"
                          aria-label="Tour"
                          className="app__tour-button"
                          onClick={() => {
                            this.setRun();
                          }}
                        >
                          TUTORIAL
                        </button>
                      </div>
                      {!isMobile && (
                        <div className="app__help-container">
                          <div data-tooltip="Toggle help tooltips">
                            <button
                              type="button"
                              aria-label="Help"
                              className={`app__toggle-help-button
                          ${this.showHelp() ? ' selected' : ''}`}
                              onClick={() => {
                                this.toggleHelp();
                              }}
                            />
                          </div>
                          <div data-tooltip={this.showHelp() ? 'Show keyboard shortcuts' : null}>
                            <KeyBindings
                              onClick={() => {
                                this.changeModalType('keybindings');
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!isMobile && (
              <div className="center col-2-4" id="canvas">
                <PixelCanvasContainer drawHandlersFactory={this.drawHandlersFactory} />
              </div>
            )}

            <div className="right col-1-4">
              <div className="app__right-side">
                <div className="app__mobile--container">
                  <div className="app__mobile--group">
                    {!isMobile && (
                      <PreviewBox
                        helpOn={this.showHelp()}
                        callback={() => {
                          this.changeModalType('preview');
                        }}
                        
                      />
                    )}

                    <div
                      data-tooltip={this.showHelp() ? 'Reset the canvas' : null}
                      className="max-width-container-centered"
                      id="my-other-step"
                    >
                      <ResetContainer />
                    </div>
                    {!isMobile && (
                      <div
                        data-tooltip={this.showHelp() ? 'Number of columns and rows' : null}
                        className="max-width-container-centered"
                        id="dimensions"
                      >
                        <DimensionsContainer />
                      </div>
                    )}

                    {isMobile && (
                      <>
                        <div data-tooltip={this.showHelp() ? 'New project' : null}>
                          <NewProjectContainer />
                        </div>
                        <div className="app__load-save-container">
                          <button
                            type="button"
                            className="app__load-button"
                            onClick={() => {
                              this.changeModalType('load');
                            }}
                            data-tooltip={
                              this.showHelp() ? 'Load projects you stored before' : null
                            }
                          >
                            SAVED
                          </button>
                          <div data-tooltip={this.showHelp() ? 'Save your project' : null}>
                            <SaveDrawingContainer />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {!isMobile && (
                    <>
                    <div className="app__mobile--group">
                      <ActivePaletteGridContainer showHelp={this.state.helpOn} />
                    </div>
                     <div className="app__mobile--group max-width-container-centered {">
                     <div
                       data-tooltip={this.showHelp() ? 'Embroider your art!' : null}
                       className="max-width-container-centered"
                        id="embroider"
                     >
                       <EmbroiderContainer />
                     </div>
                   </div>
                   </>
                  )}
                 
                </div>
              </div>
            </div>
          </div>
          {/* <div className="css-container">
          <CssDisplayContainer />
        </div> */}
          <CookieConsent
            location="bottom"
            buttonText="Got it!"
            cookieName="pixelartcssCookiesAccepted"
            style={{
              background: '#313131',
              fontSize: '13px',
              textAlign: 'center',
            }}
            buttonStyle={{
              background: '#bbbbbb',
              color: '#4e503b',
              fontSize: '13px',
            }}
            contentStyle={{
              flex: '1 0 200px',
              margin: '15px',
            }}
            expires={150}
          >
            By continuing to use this website you are giving consent to cookies being used. Thank
            you.
            <a
              href="/privacy"
              target="_blank"
              rel="noopener noreferrer nofollow"
              style={{ textDecoration: 'none', color: '#5786c1' }}
            >
              {' '}
              Learn more
            </a>
          </CookieConsent>
          <ModalContainer
            type={modalType}
            isOpen={modalOpen}
            close={() => {
              this.closeModal();
            }}
            open={() => {
              this.changeModalType(modalType);
            }}
            uploadedFile={this.state.uploadedFile}
          />
        </div>
      </div>
    );
  }

  render() {
    const { modalType, modalOpen, isMobile, importMode, run, steps } = this.state;

    return (
      <div>
        <Joyride
          steps={steps}
          continuous
          run={run}
          callback={this.handleJoyrideCallback}
          showSkipButton={true}
          locale={{
            last: 'Start Creating!',
          }}
        />
        {importMode ? (
          <ImportModeContainer
            close={this.closeImportMode}
            uploadedFile={this.state.uploadedFile}
          />
        ) : (
          this.editorView(modalType, modalOpen, isMobile)
        )}
      </div>
    );
  }
}
