import React, { useState, useEffect, useRef, createRef } from 'react';
import { generatePixelDrawCss, generateAnimationCSSData } from '../utils/cssParse';
import Animation from './Animation';

const Preview = (props) => {
  const { activeFrameIndex, storedData } = props;
  const { frames } = storedData || props;
  const grid = frames.get(activeFrameIndex);
  const [debouncedGrid, setDebouncedGrid] = useState(grid);
  const [debouncedColumns, setDebouncedColumns] = useState(props.columns);

  const canvasRef = useRef(null);

  useEffect(() => {
    const { columns, cellSize, type } = storedData || props;

    if (useCanvas) {
      const svgString = generatePixelDrawCss(grid, columns, cellSize, type, props.stitchType);
      const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
      const URL = window.URL || window.webkitURL || window;
      const blobURL = URL.createObjectURL(svgBlob);

      const img = new Image();
      img.onload = function () {
        if (canvasRef.current) {
        const ctx = canvasRef.current.getContext('2d');
        ctx.drawImage(img, 0, 0);
        }
        URL.revokeObjectURL(blobURL); // Clean up memory
      };
      img.src = blobURL;
    }
  }, [canvasRef]); // Add other dependencies if necessary

  // Debounce logic
  useEffect(() => {
    // if columns changed then render immediately
    if (props.columns !== debouncedColumns) {
      setDebouncedGrid(grid);
      setDebouncedColumns(props.columns);
      return;
    }

    const debounceTimeout = setTimeout(() => {
      setDebouncedGrid(grid);
    }, 200);

    return () => clearTimeout(debounceTimeout);
  }, [grid, props.columns]);

  const generatePreview = () => {
    // Use debouncedGrid instead of grid for calculations
    const { duration, animationName, type, stitchType } = props;
    const { columns, cellSize, animate, svgClassName, width } = storedData || props;
    const animation = frames.size > 1 && animate;
    let animationData;  
    if (animation) {
      animationData = generateAnimationCSSData(frames, columns, cellSize);
    } else {
      if (type === 'html') {
        const styles = {
          previewWrapper: {
            height: cellSize,
            width: cellSize,
            position: 'absolute',
            top: '-5px',
            left: '-5px',
          },
        };
        const htmlString = generatePixelDrawCss(debouncedGrid, columns, cellSize, 'html', stitchType);
        return (
          <div style={styles.previewWrapper} dangerouslySetInnerHTML={{ __html: htmlString }} />
        );
      } else if (type === 'svg' || type === 'fancySVG') {
        const svgString = generatePixelDrawCss(debouncedGrid, columns, cellSize, type, stitchType);
        return <div className={svgClassName} style={{width: width}} dangerouslySetInnerHTML={{ __html: svgString }} />;
      } else {
        const styles = {
          previewWrapper: {
            height: cellSize,
            width: cellSize,
            position: 'absolute',
            top: '-5px',
            left: '-5px',
          },
        };
        const cssString = generatePixelDrawCss(debouncedGrid, columns, cellSize, 'string');
        styles.previewWrapper.boxShadow = cssString;
        styles.previewWrapper.MozBoxShadow = cssString;
        styles.previewWrapper.WebkitBoxShadow = cssString;
        return (
          <div style={animation ? null : styles.previewWrapper}>
            {animation ? (
              <Animation duration={duration} boxShadow={animationData} name={animationName} />
            ) : null}
          </div>
        );
      }
    }
  };

  const { columns, rows, cellSize, skipWrapper, type, svgClassName, width, useCanvas } = storedData || props;
  const style = {
    width: columns * cellSize,
    height: rows * cellSize,
    position: 'relative',
  };

  if (useCanvas)
  {
    return (<canvas ref={canvasRef} className={svgClassName}
    width={columns > rows ? 2000 : 2000 * (columns / rows)}
    height={columns > rows ? 2000 * (rows / columns) : 2000}
    style={{
      width: width,
    }} />);
  }
  else if (skipWrapper) {
    return generatePreview();
  }
  else {
    return (
      <div className="preview" style={style}>
        {generatePreview()}
      </div>
    );
  }
};
export default Preview;
