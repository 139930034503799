import React, {useEffect, useRef} from "react";
import {Link, useNavigate} from 'react-router-dom';
import {createSelectorHook, createDispatchHook, connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {List, Map} from "immutable";
import {mainStoreContext, pixelStoreContext} from "../contexts";
import ProductCard from "../components/ProductCard";
import Preview from "../../pixel-app/src/components/Preview";
import {fetchProducts} from '../store/products';
import {getImage, setPreview} from "../store/embroidery";
import { generatePixelDrawCss } from "../../pixel-app/src/utils/cssParse";
import {useParams, useSearchParams} from 'react-router-dom';
import SimpleSpinnerForever from "../../pixel-app/src/components/SimpleSpinnerForever";
import initialSetup from "../../pixel-app/src/utils/startup";
import { doubleGrid } from "../utils/doubleGrid";
import { DOUBLE_DENSITY_THRESHOLD_COLUMNS as DDTC, DOUBLE_DENSITY_THRESHOLD_ROWS as DDTR } from "../constants";
import * as actionCreators from '../../pixel-app/src/store/actions/actionCreators';
import { hexToRgb } from "../../pixel-app/src/components/ColorPicker";

const Embroider = (props) => {
    const navigate = useNavigate();
    const useSelector = createSelectorHook(mainStoreContext);
    const embroideryState = useSelector((state) => state.embroidery);
    const useDispatch = createDispatchHook(mainStoreContext);
    const usePixelDispatch = createDispatchHook(pixelStoreContext);
    const dispatch = useDispatch();
    const pixelDispatch = usePixelDispatch();
    const productState = useSelector((state) => state.products);
    const grid = embroideryState.embroideryGrid;
    const columns = embroideryState.columns;
    const rows = embroideryState.rows;
    const borderColor = embroideryState.borderColor;
    const previewId = embroideryState.previewId;
    const previewDataUrl = embroideryState.preview;
    const previewDataUrlDouble = embroideryState.previewDouble;
    const {id} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const querySku = searchParams.get('sku');
    const querySize = searchParams.get('s');
    const queryPosition = searchParams.get('p');
    const queryHightlight = searchParams.get('h');
    const canvasRef = useRef(null);
    const canvasDoubleRef = useRef(null);
    let doubledFrames = null;
    
    // is this a mobile device?
    const isMobile = window.innerWidth < 900;

    // this is how frames is read
    // frames.get(0).get('grid')
    const frames = List().push(Map().set('grid', grid).set('borderColor', borderColor).set('borderActive', borderColor ? true : false));

    if (grid && columns <= DDTC && rows <= DDTR) {
        doubledFrames = doubleGrid(frames, columns, rows, borderColor, borderColor ? true : false);
    }

    const getColors = (grid) => {
        const activeColors = grid.reduce((acc, color) => {
            if (!acc.includes(color) && color !== '' && color !== 'rgba(0,0,0,0)') {
                acc.push(color);
            }
            
            // also add the border color if it exists and isn't already in the list
            if (borderColor && !acc.includes(hexToRgb(borderColor))) {
                acc.push(hexToRgb(borderColor));
            }

            return acc;
        }, []);
        return activeColors.length;
    };

    const numberOfColors = grid ? getColors(grid) : 0;

    const setDrawingFromPreview = (frames, columns, rows) => {
        initialSetup(pixelDispatch, localStorage);
        props.actions.setDrawing(frames, [], 1, columns, rows);
    };

    useEffect(() => {
        if (!grid && !id) {
            navigate('/create');
        } else if (!grid && id) {
            const action = getImage(id);
            dispatch(action);
        } else if (grid && previewId && (id !== previewId)) {
            const action = getImage(id);
            dispatch(action);
        } else if (grid && id) {
            initialSetup(pixelDispatch, localStorage);
            props.actions.setDrawing(frames, [], 1, columns, rows);
            const svgString = generatePixelDrawCss(frames.get(0), columns, 1, 'fancySVG', 'cross-stitch');
            const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
            const URL = window.URL || window.webkitURL || window;
            const blobURL = URL.createObjectURL(svgBlob);

            const img = new Image();
            img.onload = function () {
                if (canvasRef.current) {
                    canvasRef.current.width = columns*40;
                    canvasRef.current.height = rows*40;
                    const ctx = canvasRef.current.getContext('2d');
                    ctx.drawImage(img, 0, 0, columns*40, rows*40);
                    const dataUrl = canvasRef.current.toDataURL();
                    const action = setPreview({data_url: dataUrl, id: id, data_url_double: false});
                    dispatch(action);
                }
                URL.revokeObjectURL(blobURL); // Clean up memory
            };
            img.src = blobURL;

            if (columns <= DDTC && rows <= DDTR) {

                const svgStringDouble = generatePixelDrawCss(doubledFrames, columns*2, 1, 'fancySVG', 'cross-stitch');
                const svgBlobDouble = new Blob([svgStringDouble], { type: 'image/svg+xml;charset=utf-8' });
                const blobURLDouble = URL.createObjectURL(svgBlobDouble);

                const imgDouble = new Image();
                imgDouble.onload = function () {
                    if (canvasDoubleRef.current) {
                        canvasDoubleRef.current.width = columns*40;
                        canvasDoubleRef.current.height = rows*40;
                        const ctx = canvasDoubleRef.current.getContext('2d');
                        ctx.drawImage(imgDouble, 0, 0, columns*40, rows*40);
                        const dataUrl = canvasDoubleRef.current.toDataURL();
                        const action = setPreview({data_url_double: dataUrl});
                        dispatch(action);
                    }
                    URL.revokeObjectURL(blobURLDouble); // Clean up memory
                };
                imgDouble.src = blobURLDouble;
            }
            
        }
    }, [grid, id, navigate]);

    useEffect(() => {
        // /embroider is the default route, so we need to redirect to the correct route
        // if we have the uuid
        if (embroideryState?.upload?.payload) {
            navigate('/embroider/' + embroideryState.upload.payload.uuid, {replace: true});
        }
    }, [embroideryState, navigate]);

    useEffect(() => {
        if (! productState.products && ! productState.isLoading) {
            const action = fetchProducts();
            dispatch(action);
        }
    }, [productState.products, dispatch]);

    useEffect(() => {
        document.title = "PixelStitch - Embroider"
     }, []);

    const removeQueryHightlight = () => {
        setSearchParams({
            ...searchParams,
            h: false,
        });
    };

    return(
        <div className="product-selection">
        {grid && (id == previewId) ? (<>
        <div className="container fade-in-slide-right">
            <div className="row">
                <div className="col-md-12">
                    <h1 className="text-left product-selection-header">Product Selection</h1>
                    {/* <p className="text-left product-selection-paragraph">
                        Explore our growing range of premium products! A great design can't make up for poor craftsmanship; that's why we go above and beyond to offer high-quality products and do all of our embroidery in the USA.
                    </p>
                    <p className="text-left product-selection-paragraph">
                        Tailor your Pixel Stitch creation with customizable design sizes, placement options, and a variety of product colors and sizes to match your unique style.
                    </p> */}
                    {/* <p className="text-left product-selection-paragraph">
                        Once you have completed your masterpiece, effortlessly showcase it using the dedicated <strong>Share</strong> button on each product card! <strong>Allow others to order or edit your design without affecting your original work by sharing the link</strong>.
                    </p> */}
                    <p className="text-left product-selection-paragraph">
                        Share your design so others can order or edit it without affecting your original work!
                    </p>
                </div>
            </div>
            <Link to="/create" className="edit-this-design">
            <Preview
                frames={frames}
                columns={columns}
                rows={rows}
                cellSize={isMobile ? 2 : 3}
                duration={0}
                activeFrameIndex={0}
                animate={false}
                animationName="n/a"
                type={'html'}
                stitchType={'fill'}
                />
                <h2>Edit this design</h2>
            </Link>
            {/* product rows that are dynamically sized, 4 per row on large screens, 2 per row on medium, on on small */}
            <div className={`row row-cols-1 ${queryHightlight === 'true' ? 'single-product-highlight' : 'row-cols-sm-2 row-cols-md-3'}`}> {/* iterate through each product */}
                {
                productState?.data?.payload && productState?.data?.payload.map((product, index) => {
                    return (<div className="col flex card-container"
                        key={index}>
                        <ProductCard product={product}
                            // frames={frames}
                            // doubledFrames={doubledFrames}
                            columns={columns}
                            rows={rows}
                            designId={id}
                            numberOfColors={numberOfColors}
                            queryParams={
                                {
                                    sku: querySku,
                                    size: querySize,
                                    position: queryPosition,
                                    highlight: queryHightlight,
                                }
                            }
                            borderColor={borderColor}
                            enableDoubleOptions={columns <= DDTC && rows <= DDTR}
                            previewDataUrl={id === previewId ? previewDataUrl : null}
                            previewDataUrlDouble={id === previewId ? previewDataUrlDouble : null}
                            addEditButton={true}
                            setDrawing={setDrawingFromPreview}
                            frames={frames}
                            />
                    </div>);
                })
            }
            {queryHightlight === 'true' && querySku &&(
                <button className="btn btn-outline-dark m-auto" onClick={removeQueryHightlight}>
                    See more products
                </button>
            )}
             </div>
            
                
        </div>
    </>) : (<div>
        {
            embroideryState?.image?.error ? (
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center pt-5">
                            <h1 className="text-center">Design not found</h1>
                            <p className="text-center">
                                It looks like the design you are looking for doesn't exist.
                            </p>
                            <Link to="/create">
                                <button className="dyo-button btn btn-outline-dark pixelate">Create a new design</button>
                            </Link>
                        </div>
                    </div>
                </div>
            ) : <SimpleSpinnerForever/>
        }
        
    </div>)}
    {/* hidden canvas */}
    <canvas ref={canvasRef} style={{display: 'none'}}/>
    <canvas ref={canvasDoubleRef} style={{display: 'none'}}/>
    </div>);
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

const EmbroiderContainer = connect(null, mapDispatchToProps, null, {context: pixelStoreContext})(Embroider);
export default EmbroiderContainer;
