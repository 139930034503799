import React, {useEffect} from 'react';
import { Provider, createDispatchHook, createSelectorHook } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import Home from './pages/Home';
import Embroider from './pages/Embroider'
import Header from './components/Header';
import PrivacyPolicy from './pages/PrivacyPolicy';
import IPPolicy from './pages/IPPolicy';
import Contact from './pages/Contact';
import Export from './pages/Export';
import NotFound from './pages/404';
import Fulfillment from './pages/Fulfillment';
import FeaturedArtContainer from './pages/FeaturedArt';
import FAQ from './pages/FAQ';
import configureStore from './store';
import SentryBoundary from './utils/SentryBoundary';
import { fetchWhoami } from './store/embroidery';
import configurePixelStore from '../pixel-app/src/store/configureStore';
import Root from '../pixel-app/src/components/Root';
import '../pixel-app/src/css/imports.css'; // Import PostCSS files

import { mainStoreContext, pixelStoreContext } from './contexts';

const devMode = process.env.NODE_ENV === 'development';
const pixelStore = configurePixelStore(devMode);

const store = configureStore({});

const InitializeApp = () => {
  const dispatch = createDispatchHook(mainStoreContext)();

  useEffect(() => {
    dispatch(fetchWhoami());
  }, [dispatch]);

  return null; // This component doesn't render anything
};

const App = () => {
return (
  <SentryBoundary>
      <Provider store={store} context={mainStoreContext}>
      <Provider store={pixelStore} context={pixelStoreContext}>
      <Router>
        <InitializeApp />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/create" element={<Root store={pixelStore} dispatch={pixelStore.dispatch} />} />
          <Route path="/embroider" element={<Embroider />} />
          <Route path="/embroider/:id" element={<Embroider />} />
          <Route path="/featured" element={<FeaturedArtContainer />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/ip" element={<IPPolicy />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/export" element={<Export/>} />
          <Route path="/fulfillment/:id" element={<Fulfillment/>} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        
        <div className="footer">
          <div className="footer-links">
              <Link to="/">
                <span className="">About</span>
              </Link>
              <Link to="/faq">
                <span className="">FAQs</span>
              </Link>
              <a href="/contact">
                <span className="">Contact</span>
              </a>
              <Link to="/privacy">
                <span className="">Privacy Policy</span>
              </Link>
              <Link to="/ip">
                <span className="">Intellectual Property Policy</span>
              </Link>
          </div>
          <span className="">© {new Date().getFullYear()} PixelStitch LLC | <a href="tel:+12025390776" className="footer-link">+1 (202) 539 0776</a> | <a className='footer-link' href="mailto:contact@pixel-stitch">contact@pixel-stitch.io</a></span>          
        </div>
      </Router>
      </Provider>
      </Provider>
  </SentryBoundary>
);
};

export default App;
