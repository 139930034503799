import React from 'react';

const styles = {
    chip: {
        display: 'inline-block',
        padding: '10px 20px',
        margin: '5px',
        borderRadius: '25px',
        cursor: 'pointer',
        border: '1px solid #ddd',
        transition: 'all 0.3s',
    },
    selected: {
        backgroundColor: '#007BFF',
        color: 'white',
        border: '1px solid #007BFF',
    },
    disabled: {
        backgroundColor: '#ddd',
        color: '#aaa',
        border: '1px solid #ddd',
        cursor: 'not-allowed',
    },
};

const ChoiceChips = ({ selectedChoice, onChoiceChange, choices }) => {

    const handleChoiceClick = (choice) => {
        onChoiceChange(choice);
    };

    return (
        <div>
            {choices.map((choice) => (
                <span
                    key={choice.key}
                    onClick={() => handleChoiceClick(choice.key)}
                    style={{
                        ...styles.chip,
                        ...(selectedChoice == choice.key ? styles.selected : {}),
                        ...(choice.disabled ? styles.disabled : {}),
                    }}
                >
                    {choice.key}
                </span>
            ))}
        </div>
    );
};

export default ChoiceChips;