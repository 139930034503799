import React from 'react';
import { useInView } from 'react-intersection-observer';
import { TiltEffect } from './Tilt';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import howTo1 from '../../assets/images/how_to_1.png';
import howTo2 from '../../assets/images/how_to_2.png';
import howTo3 from '../../assets/images/how_to_3.png';
import howTo4 from '../../assets/images/how_to_4.png';

const HowItWorksSection = ({ title, text, image, reverse, c2a }) => {
    const { ref, inView } = useInView({
      triggerOnce: true,
      threshold: 0.5,
    });
  
    const sectionClass = reverse ? 'row flex-row-reverse align-items-center' : 'row align-items-center';
  
    return (
      <div ref={ref} className={`how-it-works-section ${inView ? reverse ? 'fade-in-slide-left-how' : 'fade-in-slide-right-how' : 'how-hidden'}`}>
        <div className={sectionClass}>
          <div className="col-md-6 how-text-container">
            
            <h3 className="how-it-works-subheader">{title}</h3>
            <p>{text}</p>
            {c2a && (
                <div className="text-center">
                    <Link to="/create">
                        <Button variant="outline-light" className='dyo-button pixelate-light'>Create Now</Button>
                    </Link>
                </div>
            )
            }
          </div>
          <div className="col-md-6 how-img-padding">
            <TiltEffect>
                <img src={image} alt={title} className="img-fluid how-img" />
            </TiltEffect>
          </div>
        </div>
      </div>
    );
  };

const HowItWorks = () => {
  return (
    <div className="home-section how-it-works-container">
      <div className="container" id="how-it-works">
        <h2 className="how-it-works-header">How It Works</h2>
        <HowItWorksSection 
          title="Import or Create" 
          text="Upload your existing pixel art, convert your favorite photo into pixel format, or draw your own masterpiece using our intuitive design tools."
          image={howTo1}
          reverse={false}
        />
        <HowItWorksSection 
          title="Edit" 
          text="Modify your design with our easy-to-use editor. Tweak colors, add a border, and more."
          image={howTo2}
          reverse={true}
        />
        <HowItWorksSection 
          title="Customize" 
          text="Choose from a wide range of products and personalize them with your art. Select your size, color, and placement, and we'll take care of the rest."
          image={howTo3}
          reverse={false}
        />
        <HowItWorksSection 
          title="Stitch to Perfection" 
          text="We bring your pixel designs to life with stunning detail and vibrant colors."
          image={howTo4}
          reverse={true}
          c2a={true}
        />
      </div>
    </div>
  );
};

export default HowItWorks;