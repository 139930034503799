import { List, Map, fromJS } from 'immutable';
import shortid from 'shortid';
import * as types from '../actions/actionTypes';
import { GRID_INITIAL_COLOR } from './activeFrameReducer';
import { clamp } from 'lodash';

const getPositionFirstMatchInPalette = (grid, color) =>
  grid.findIndex(gridColor => gridColor.get('color') === color);

const isColorInPalette = (grid, color) =>
  getPositionFirstMatchInPalette(grid, color) !== -1;

// a can only be from 0 to 1, anything else will be clamped
const parseColorToString = colorData =>
  typeof colorData === 'string'
    ? colorData.replace(/\s/g, '')
    : `rgba(${colorData.r},${colorData.g},${colorData.b},${clamp(colorData.a, 0, 1)})`;

const disableColor = (palette, action) => {
  if (action.tool === 'ERASER' || action.tool === 'MOVE' || action.tool === 'BULK_ERASE') {
    return palette.set('position', -1);
  }
  return palette;
};

const addColorToLastGridCell = (palette, newColor) => {
  const grid = palette.get('grid');
  const lastPosition = grid.size - 1;
  return palette.merge({
    grid: grid.setIn([lastPosition, 'color'], parseColorToString(newColor)),
    position: lastPosition
  });
};

const createPaletteGrid = () =>
  List([
    'rgba(0,0,0,1)',
    'rgba(255,0,0,1)',
    'rgba(233,30,99,1)',
    'rgba(156,39,176,1)',
    'rgba(103,58,183,1)',
    'rgba(63,81,181,1)',
    'rgba(33,150,243,1)',
    'rgba(3,169,244,1)',
    'rgba(0,188,212,1)',
    'rgba(0,150,136,1)',
    'rgba(76,175,80,1)',
    'rgba(139,195,74,1)',
    'rgba(205,220,57,1)',
    'rgba(158,224,122,1)',
    'rgba(255,235,59,1)',
    'rgba(255,193,7,1)',
    'rgba(255,152,0,1)',
    'rgba(255,205,210,1)',
    'rgba(255,87,34,1)',
    'rgba(121,85,72,1)',
    'rgba(158,158,158,1)',
    'rgba(96,125,139,1)',
    'rgba(48,63,70,1)',
    'rgba(255,255,255,1)',
    'rgba(56,53,53,1)',
    'rgba(56,53,53,1)',
    'rgba(56,53,53,1)',
    'rgba(56,53,53,1)',
    'rgba(56,53,53,1)',
    'rgba(56,53,53,1)'
  ]).map(color => Map({ color, id: shortid.generate() }));

const isColorSelected = palette => palette.get('position') !== -1;

const resetSelectedColorState = palette => palette.set('position', 0);

const createPalette = () =>
  Map({
    grid: createPaletteGrid(),
    position: 0
  });

const getCellColor = ({ color }) => color || GRID_INITIAL_COLOR;

const eyedropColor = (palette, action) => {
  const cellColor = getCellColor(action);
  const grid = palette.get('grid');

  if (!isColorInPalette(grid, cellColor)) {
    return addColorToLastGridCell(palette, cellColor);
  }
  return palette.set(
    'position',
    getPositionFirstMatchInPalette(grid, cellColor)
  );
};

const preparePalette = palette => {
  if (!isColorSelected(palette)) {
    return resetSelectedColorState(palette);
  }
  return palette;
};

const selectPaletteColor = (palette, action) =>
  palette.set('position', action.position);

const setCustomColor = (palette, { customColor }) => {
  if (!isColorSelected(palette)) {
    return addColorToLastGridCell(palette, customColor);
  }
  const customColorRgba = parseColorToString(customColor);
  return palette.setIn(
    ['grid', palette.get('position'), 'color'],
    customColorRgba
  );
};

const setPalette = (palette, action) => {
  const defaultPalette = action.paletteGridData.length === 0;

  if (!defaultPalette) {
    // format the paletteGridData so that the rgba strings match rgba(r,g,b,a)
    
    action.paletteGridData = fromJS(action.paletteGridData);
    // action.paletteGridData is now a List of Maps with color and id
    // we need to convert the color to rgba(r,g,b,a) but keep the structure
    // of the List of Maps
    action.paletteGridData = action.paletteGridData.map(cell => {
      // get rid of all spaces, parens, and letters
      if (cell === '') {
        return 'rgba(0,0,0,0)';
      }
      const rgba = cell.get('color').replace(/[^\d,]/g, '').split(',');
      const r = rgba[0];
      const g = rgba[1];
      const b = rgba[2];

      let a = 1;
      // clamp alpha to 0-1
      if (rgba.length > 3) {
        a = clamp(rgba[3].split(')')[0], 0, 1);
      }
      return Map({ color: `rgba(${r},${g},${b},${a})`, id: cell.get('id') });
    });
  }
  return palette.set(
    'grid',
    fromJS(defaultPalette ? createPaletteGrid() : action.paletteGridData)
  );
};

const updateActivePalette = (palette, action) => {
  const activePalette = action.activePalette;
  const newPalettePosition = action.newPalettePosition;
  const grid = palette.get('grid');
  
  // Create a new array for the updated grid
  // needs to be a List
  let newGrid = List();

  // populate the new grid with the current colors
  // from the palette, but only add the first 30
  // colors to the new grid
  for (let i = 0; i < 30; i++) {
    newGrid = newGrid.push(grid.get(i));
  }

  // Add the active palette colors to the new grid
  activePalette.forEach(color => {
    newGrid = newGrid.push(Map({ color: color, id: shortid.generate() }));
  });

  if (activePalette.length === 0) {
    return palette.set('grid', newGrid).set('position', -1);
  }
  else if (newPalettePosition){
    return palette.set('grid', newGrid).set('position', newPalettePosition);
  }
  return palette.set('grid', newGrid);
}

export default function paletteReducer(palette = createPalette(), action) {
  switch (action.type) {
    case types.SET_INITIAL_STATE:
    case types.NEW_PROJECT:
      return createPalette();
    case types.APPLY_EYEDROPPER:
      return eyedropColor(palette, action);
    case types.APPLY_PENCIL:
    case types.APPLY_BUCKET:
      return preparePalette(palette);
    case types.SELECT_PALETTE_COLOR:
      return selectPaletteColor(palette, action);
    case types.UPDATE_ACTIVE_PALETTE:
      return updateActivePalette(palette, action);
    case types.SET_CUSTOM_COLOR:
      return setCustomColor(palette, action);
    // we don't want to disable the color when switching tools anymore
    // case types.SWITCH_TOOL:
    //   return disableColor(palette, action);
    case types.SET_DRAWING:
      return setPalette(palette, action);
    default:
      return palette;
  }
}
