import React, { useEffect } from "react";
import { createSelectorHook, createDispatchHook, connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { mainStoreContext, pixelStoreContext } from "../contexts";
import { fetchFeaturedDesigns } from '../store/products';
import * as actionCreators from '../../pixel-app/src/store/actions/actionCreators';
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";

const FeaturedArt = () => {
    const useSelector = createSelectorHook(mainStoreContext);
    const useDispatch = createDispatchHook(mainStoreContext);
    const dispatch = useDispatch();
    const productState = useSelector((state) => state.products);
    const featuredState = useSelector((state) => state.featured);
    const location = useLocation();
    const navigate = useNavigate();
  
    const queryParams = new URLSearchParams(location.search);
    const currentPage = parseInt(queryParams.get('page') || '1', 10);
    const ITEMS_PER_PAGE = 40;

    useEffect(() => {
        if (!featuredState.featuredDesigns && !featuredState.isLoading) {
          const action = fetchFeaturedDesigns();
          dispatch(action);
        }
      }, [featuredState.featuredDesigns, dispatch]);

    useEffect(() => {
        document.title = "PixelStitch - Featured";
      }, []);

    const handlePageChange = (page) => {
        navigate(`/featured?page=${page}`);
    };

    const totalPages = Math.ceil(featuredState?.data?.payload?.length / ITEMS_PER_PAGE) || 1;
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const currentFeaturedDesigns = featuredState?.data?.payload?.slice(startIndex, endIndex);

    return (
        <div className="product-selection fade-in-slide-right">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1 className="text-left product-selection-header">Featured Designs</h1>
                <p className="text-left product-selection-paragraph">
                  Check out some of our featured products below. Order, Edit, or Share!
                </p>
              </div>
            </div>
            </div>
            <div className={`pattern-grid`}>
              {!productState?.data?.payload && !featuredState?.data?.payload ? (
                <p>Loading...</p>
              ) : (
                <>
                  {currentFeaturedDesigns?.map((featuredDesign, index) => (
                    <Link className="pattern-entry text-center mx-2" to={`/embroider/${featuredDesign.pixel_art_uuid}`} key={`${featuredDesign.pixel_art_uuid}-${currentPage}`}>
                        <div className="card pattern-card" key={`${featuredDesign.pixel_art_uuid}-${currentPage}`}>
                            <div className="pattern-img-container">
                              <img src={featuredDesign.data_url} alt={featuredDesign.name} className="card-img-top pattern-img" style={{ filter: featuredDesign.border_color && `drop-shadow(1.5px 0 0 ${featuredDesign.border_color}) drop-shadow(-1.5px 0 0 ${featuredDesign.border_color}) drop-shadow(0 1.5px 0 ${featuredDesign.border_color}) drop-shadow(0 -1.5px 0 ${featuredDesign.border_color})` }} />
                            </div>
                            <div className="card-body pattern-card-body text-center p-2">
                              <h3 className="card-title m-0 text-sm">PixelStitch Staff</h3>
                            </div>
                        </div>
                        <div className="title pt-2">
                            <h5 className="pattern-title">{featuredDesign.name}</h5>
                        </div>
                        <div className="overlay"></div>
                    </Link>
                  ))}
                </>
              )}
            </div>
            <div className="container">
            <nav aria-label="Page navigation comments" className="mt-4 pattern-pagination">
              <ReactPaginate
                pageCount={totalPages}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                previousLabel={'<'}
                nextLabel={'>'}
                activeClassName="active"
                onPageChange={({ selected }) => handlePageChange(selected + 1)}
              />
            </nav>
            </div>
          </div>
      );
    };
    
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

const FeaturedArtContainer = connect(null, mapDispatchToProps, null, { context: pixelStoreContext })(FeaturedArt);
export default FeaturedArtContainer;