import React, {useEffect, useRef} from "react";
import {useNavigate} from 'react-router-dom';
import {createSelectorHook, createDispatchHook, connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {List, Map} from "immutable";
import {mainStoreContext, pixelStoreContext} from "../contexts";
import Preview from "../../pixel-app/src/components/Preview";
import {getImage, setPreview} from "../store/embroidery";
import { generatePixelDrawCss } from "../../pixel-app/src/utils/cssParse";
import {useParams} from 'react-router-dom';
import initialSetup from "../../pixel-app/src/utils/startup";
import { doubleGrid } from "../utils/doubleGrid";
import { threads } from "../../pixel-app/src/utils/threads";
import { DOUBLE_DENSITY_THRESHOLD_COLUMNS as DDTC, DOUBLE_DENSITY_THRESHOLD_ROWS as DDTR } from "../constants";
import * as actionCreators from '../../pixel-app/src/store/actions/actionCreators';

const Fulfillment = (props) => {
    const navigate = useNavigate();
    const useSelector = createSelectorHook(mainStoreContext);
    const embroideryState = useSelector((state) => state.embroidery);
    const useDispatch = createDispatchHook(mainStoreContext);
    const usePixelDispatch = createDispatchHook(pixelStoreContext);
    const dispatch = useDispatch();
    const pixelDispatch = usePixelDispatch();
    const grid = embroideryState.embroideryGrid;
    const columns = embroideryState.columns;
    const rows = embroideryState.rows;
    const borderColor = embroideryState.borderColor;
    const previewId = embroideryState.previewId;
    const previewDataUrl = embroideryState.preview;
    const previewDataUrlDouble = embroideryState.previewDouble;
    const {id} = useParams();
    const canvasRef = useRef(null);
    const canvasDoubleRef = useRef(null);
    let doubledFrames = null;

    // this is how frames is read
    // frames.get(0).get('grid')
    const frames = List().push(Map().set('grid', grid).set('borderColor', borderColor).set('borderActive', borderColor ? true : false));

    if (grid && columns <= DDTC && rows <= DDTR) {
        doubledFrames = doubleGrid(frames, columns, rows);
    }

    const getClosestThread = (color) => {
        const colorArray = color.replace('rgba(', '').replace('rgb(', '').replace(')', '').split(',');
        const r = parseInt(colorArray[0]);
        const g = parseInt(colorArray[1]);
        const b = parseInt(colorArray[2]);
        const colorObj = {
            r: r,
            g: g,
            b: b,
        };
        const closestThread = threads.reduce((acc, thread) => {
            const threadColorArray = thread[0].replace('rgba(', '').replace('rgb(', '').replace(')', '').split(',');
            const threadR = parseInt(threadColorArray[0]);
            const threadG = parseInt(threadColorArray[1]);
            const threadB = parseInt(threadColorArray[2]);
            const threadColorObj = {
                r: threadR,
                g: threadG,
                b: threadB,
            };
            const distance = Math.sqrt(Math.pow(colorObj.r - threadColorObj.r, 2) + Math.pow(colorObj.g - threadColorObj.g, 2) + Math.pow(colorObj.b - threadColorObj.b, 2));
            if (distance < acc.distance) {
                acc.distance = distance;
                acc.thread = thread;
            }
            return acc;
        }, {distance: 1000, thread: null});
        return closestThread.thread;
    };

    const getColors = (grid) => {
        const activeColors = grid.reduce((acc, color) => {
            if (!acc.includes(color) && color !== '' && color !== 'rgba(0,0,0,0)') {
                acc.push(color);
            }
            return acc;
        }, []);
        
        // return activeColor number of divs with the color as the background
        const designColors = activeColors.map((color, index) => {
            return (
                    <div key={index} className="color" style={{backgroundColor: color}}></div>
            )
        });

        // <div key={index+"thread"} className="color" style={{backgroundColor: closestThread}}>{closestThread}</div>
        const closestThreads = activeColors.map((color, index) => {
            const closestThread = getClosestThread(color);

            return (
                    <div key={index+"thread"} className="color" style={{
                        backgroundColor: closestThread[0]
                    }}>{closestThread[2] + ": " + closestThread[1]}</div>
            )
        });

        return (
            <div className="colors-wrapper">
                <div className="colors">
                    {designColors}
                </div>
                <div className="colors">
                    {closestThreads}
                </div>
            </div>
        )
    };

    useEffect(() => {
        if (! grid && !id) {
            navigate('/create');
        } else if (! grid && id) {
            const action = getImage(id);
            dispatch(action);
        } else if (grid && id) {
            initialSetup(pixelDispatch, localStorage);
            props.actions.setDrawing(frames, [], 1, columns, rows);
            const svgString = generatePixelDrawCss(frames.get(0), columns, 1, 'fancySVG', 'cross-stitch');
            const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
            const URL = window.URL || window.webkitURL || window;
            const blobURL = URL.createObjectURL(svgBlob);

            const img = new Image();
            img.onload = function () {
                if (canvasRef.current) {
                    canvasRef.current.width = columns*40;
                    canvasRef.current.height = rows*40;
                    const ctx = canvasRef.current.getContext('2d');
                    ctx.drawImage(img, 0, 0, columns*40, rows*40);
                    const dataUrl = canvasRef.current.toDataURL();
                    const action = setPreview({data_url: dataUrl, id: id, data_url_double: false});
                    dispatch(action);
                }
                URL.revokeObjectURL(blobURL); // Clean up memory
            };
            img.src = blobURL;

            if (columns <= DDTC && rows <= DDTR) {

                const svgStringDouble = generatePixelDrawCss(doubledFrames, columns*2, 1, 'fancySVG', 'cross-stitch');
                const svgBlobDouble = new Blob([svgStringDouble], { type: 'image/svg+xml;charset=utf-8' });
                const blobURLDouble = URL.createObjectURL(svgBlobDouble);

                const imgDouble = new Image();
                imgDouble.onload = function () {
                    if (canvasDoubleRef.current) {
                        canvasDoubleRef.current.width = columns*40;
                        canvasDoubleRef.current.height = rows*40;
                        const ctx = canvasDoubleRef.current.getContext('2d');
                        ctx.drawImage(imgDouble, 0, 0, columns*40, rows*40);
                        const dataUrl = canvasDoubleRef.current.toDataURL();
                        const action = setPreview({data_url_double: dataUrl});
                        dispatch(action);
                    }
                    URL.revokeObjectURL(blobURLDouble); // Clean up memory
                };
                imgDouble.src = blobURLDouble;
            }
            
        }
    }, [grid, id, navigate]);

    return(
        <div className="fulfillment-wrapper">
            <div className="container">
                {grid  &&
                (   
                    <div>
                        <Preview
                            frames={frames}
                            columns={columns}
                            rows={rows}
                            cellSize={5}
                            duration={0}
                            activeFrameIndex={0}
                            animate={false}
                            animationName="n/a"
                            type={'html'}
                            stitchType={'fill'}
                        />
                        {getColors(grid)}
                    </div>
                )}
                
            </div>
        </div>
    )};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

const FulfillmentContainer = connect(null, mapDispatchToProps, null, {context: pixelStoreContext})(Fulfillment);
export default FulfillmentContainer;
    