import React, { useRef, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  overflow: hidden;
  display: block;
`;

const Image = styled.img.attrs((props) => ({
  src: props.source
}))``;

const TargetDiv = styled.div`
    position: absolute;
    left: ${(props) => props.offset.left}px;
    top: ${(props) => props.offset.top}px;
    opacity: ${(props) => props.opacity};
`;
  

export default function ZoomHoverImg({ imgSource, imgPreview }) {
  const sourceRef = useRef(null);
  const targetRef = useRef(null);
  const containerRef = useRef(null);

  const [opacity, setOpacity] = useState(0);
  const [offset, setOffset] = useState({ left: 0, top: 0 });

  const handleMouseEnter = () => {
    setOpacity(1);
  };

  const handleMouseLeave = () => {
    setOpacity(0);
  };

  const handleMouseMove = (e) => {
    const targetRect = targetRef.current.getBoundingClientRect();
    const sourceRect = sourceRef.current.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();

    const xRatio = (targetRect.width - containerRect.width) / sourceRect.width;
    const yRatio = (targetRect.height - containerRect.height) / sourceRect.height;

    // Account for the scroll position
    const left = Math.max(
      Math.min(e.pageX - sourceRect.left - window.scrollX, sourceRect.width),
      0
    );
    const top = Math.max(
      Math.min(e.pageY - sourceRect.top - window.scrollY, sourceRect.height),
      0
    );

    setOffset({
      left: left * -xRatio,
      top: top * -yRatio
    });
};

  return (
    <div className="zoom-container">
      <Container
        ref={containerRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
      >
        <div>
            <Image ref={sourceRef} className="w-100 img-border-radius"  alt="source" source={imgSource} />
            {!opacity && imgPreview}
            </div>
        <TargetDiv
            opacity={opacity}
            offset={offset}
        >
            <Image 
                ref={targetRef}
                alt="target"
                source={imgSource}
                className="img-border-radius"
                />
            {opacity && imgPreview}
        </TargetDiv>
      </Container>
    </div>
  );
}
