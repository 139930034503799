// 404

import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    useEffect(() => {
        document.title = "PixelStitch - 404"
     }, []);

     return (
        <div className='not-found-wrapper'>
            <div className="container  text-center">
                <div className="row">
                    <div className="col-12">
                        <h1>404</h1>
                        <p>Sorry, we couldn't find that page.</p>
                        <Link to="/">
                            <button
                                className='dyo-button btn btn-outline-dark pixelate'
                            >Go back home</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}



export default NotFound;