import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectPaletteColor, updateActivePalette, setBorderColor, setBorderActive } from '../store/actions/actionCreators';
import PaletteColor from './PaletteColor';
import { debounce } from 'lodash';
import { pixelStoreContext } from '../../../js/contexts';
import ActivePaletteControls from './ActivePaletteControls';
import { rgbToHex, hexToRgb } from './ColorPicker';

const ActivePaletteGrid = props => {
  const { grid, fullPalette, showHelp } = props;

  // State to hold the active colors calculated by getColors()
  const [activeColors, setActiveColors] = useState([]);
  const [borderColorMode, setBorderColorMode] = useState('existing');
  const fullPaletteJS = fullPalette.grid.toJS();
  const currentActiveColor = fullPalette.position >= 30 ? fullPaletteJS[fullPalette.position]?.color : null;

  // Create a debounced function for setting border color
  const debouncedSetBorderColor = useCallback(
    debounce((color) => {
      props.setBorderColor(color);
    }, 250),
    []
  );

  const changeBorderColorMode = (mode) => {
    setBorderColorMode(mode);
    if (mode === 'existing'){
      props.setBorderColor(rgbToHex(fullPaletteJS[30].color));
    }   
  }

  // Modify the onChange handler for the color picker
  const handleBorderColorChange = (e) => {
    debouncedSetBorderColor(e.target.value);
  };

  const handleBorderCheckboxChange = (e) => {
    props.setBorderActive(e.target.checked);
    // set the 31st color to the border color (most common active color should be in the 31st color)
    props.setBorderColor(props.borderColor ? props.borderColor : rgbToHex(fullPaletteJS[30].color));
  }

  // Debounce logic
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setActiveColors(getColors());  // Update the activeColors state
    }, 300);

    return () => clearTimeout(debounceTimeout);
  }, [grid, props.borderColor, props.borderActive]);  // Dependence on grid

  const getColors = () => {
    const activeColors = grid.reduce((acc, color) => {
      if (!acc.includes(color) && color !== '' && color !== 'rgba(0,0,0,0)' && color !== 'rgba(0, 0, 0, 0)') {
        acc.push(color);
      }
      return acc;
    }, []);

    // if there are no active colors, set the border color to null
    if (activeColors.length == 0){
      props.setBorderActive(false);
      props.setBorderColor(null);
      setBorderColorMode('existing');
    }

    // also add the border color if it is active and not already in the active colors
    if (props.borderActive && props.borderColor && !activeColors.includes(hexToRgb(props.borderColor))){
      setBorderColorMode('custom');
      activeColors.push(hexToRgb(props.borderColor));
    }

    // if the palette colors after #30 are different from the active colors,
    // update the palette
    if (activeColors.length == 0){
      props.updateActivePalette([]);
    }
    else{
      const paletteColors = fullPalette.grid.toJS();
      const position = fullPalette.position;
      let newPalettePosition = null;
      // need to handle the case where the active colors move to a different position in the active palette
      if (position >= 30){
        const activeIndex = activeColors.findIndex((color) => color === paletteColors[position]?.color);
        newPalettePosition = activeIndex + 30;
      }
      props.updateActivePalette(activeColors, newPalettePosition);
    }
    
    return activeColors;
  }

  const renderColors = (fullPalette, position) => {
    // only want to render the colors after the first 30
    const width = 100 / 6;
    return fullPalette.map((color, index) => {
      if (index >= 30){
        return <PaletteColor
          key={color.id}
          positionInPalette={index}
          width={width}
          color={color.color}
          selected={position === index}
          selectPaletteColor={props.selectPaletteColor}
        />
      }
      else {
        return null;
      }
    }
        
    );
  }

  return (
    <>
      <div className='color-cost-info'>
        <p>The first 6 colors are included with no extra charge.</p>
        <p>Each additional color is <span>$2.00</span>. </p>
        <p>12 colors maximum.</p>
      </div>
      {fullPalette.grid.size > 30 && (
          <div className="active-palette-grid" id="active-palette">
            <div className="active-palette">
              <p>Active Colors</p>
              <ActivePaletteControls activePaletteColor={currentActiveColor} showHelp={showHelp}/>
              {renderColors(fullPalette.grid.toJS(), fullPalette.position)}
            </div>
            {/* checkbox to add a border */}
            <div className='border-checkbox'>
              <input type="checkbox" id="border-checkbox" name="border-checkbox" value="border" checked={props.borderActive} onChange={handleBorderCheckboxChange}/>
              <label htmlFor="border-checkbox">Add Border</label>
            </div>
            {/* color picker for border */}
            {props.borderActive && (
              // radio buttons to select border color mode
              <>
              <div className='border-color-mode'>
                <div>
                  <input type="radio" id="existing" name="border-color-mode" value="existing" checked={borderColorMode === 'existing'} onChange={() => changeBorderColorMode('existing')} />
                  <label htmlFor="existing" className={borderColorMode === 'existing' ? 'active' : 'not-active'}>Existing Color</label>
                </div>
                <div>
                  <input type="radio" id="custom" name="border-color-mode" value="custom" checked={borderColorMode === 'custom'} onChange={() => changeBorderColorMode('custom')} />
                  <label htmlFor="custom" className={borderColorMode === 'custom' ? 'active' : 'not-active'}>Custom Color</label>
                  </div>
              </div>
              {
                borderColorMode === 'existing' ? (
                  // select from existing colors (show the active palette)
                  <div className='border-color-picker'>
                    <div className='d-flex flex-row pb-2'>
                      {activeColors.map((color, index) => {
                          return <div key={index} className='border-color-select color-div' style={{
                            backgroundColor: color,
                            border: color === hexToRgb(props.borderColor) ? '2px solid white' : 'none'
                          }} onClick={() => props.setBorderColor(rgbToHex(color))}></div>
                      })}
                    </div>
                  </div>
                ) : (
                  <div className='border-color-picker'>
                    <input
                      type="color"
                      id="border-color-picker"
                      name="border-color-picker"
                      value={props.borderColor}
                      onChange={handleBorderColorChange}
                    />
                  </div>
                )
              }
              
              </>
            )}
          </div>
      )}
      {activeColors.length > 6 && <div className='additional-cost-notice'>
        {activeColors.length > 12 ? (
          <p className='warning-text'>Too many colors!</p>
        ) : (<p >Color Fee: <span>${(activeColors.length - 6) * 2}.00</span></p>)}
      </div>}
    </>
  );
};

const mapStateToProps = state => {
    const frames = state.present.get('frames');
    const fullPalette = state.present.get('palette').toObject()

    return {
      grid: frames.getIn(['list', 0, 'grid']),
      borderActive: frames.getIn(['list', 0, 'borderActive']),
      borderColor: frames.getIn(['list', 0, 'borderColor']),
      fullPalette
    };
  };

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      selectPaletteColor,
      updateActivePalette,
      setBorderColor,
      setBorderActive
    },
    dispatch
  );

const ActivePaletteGridContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { context: pixelStoreContext }
)(ActivePaletteGrid);
export default ActivePaletteGridContainer;
