// ip policy page
import React, {useEffect} from 'react';

const IPPolicy = () => {

    // scroll to top of page on load
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div className='privacy-policy-page'>
            <div className='container'>
                <div className='row privacy-policy'>
                    <h1>Pixel Stitch LLC Intellectual Property Policy</h1>
                    <p>This document contains the Intellectual Property Policy (the “Policy”) of Pixel Stitch LLC (“Pixel Stitch”, “Sponsor”, “we”, “our”, or “us”). This document establishes the policies and guidelines for your submission of materials and content to www.pixel-stitch.io (the “Site”, or “Website). Pixel Stitch takes the intellectual property rights of you and others seriously.  Please read this Policy carefully. By accessing or using the Website you agree to be bound by this Policy and to any additional guidelines, restrictions, or rules that may be posted in connection with specific sections or services of the Site. Pixel Stitch reserves the right to change the Site and this Policy at any time without prior notice.</p>
                    <h2>Your Content and Material</h2>
                    <p>Any content and material submitted to, or created by you on Pixel Stitch via the Site, including, without limitation, photographs, pixel art, images, text, graphics, and other material (collectively, "Designs") are subject to the terms and conditions of this Policy. By using the Site, you agree that everything you upload, save, purchase, or use on the Sites complies with this Policy and the User Agreement, and you accept liability for any Designs that do not comply with Pixel Stitch’s Policy.</p>
                    <p>You will retain ownership of any created and/or submitted Designs. You grant Pixel Stitch a worldwide, non-exclusive, transferable, royalty-free, perpetual, irrevocable right and license, with right of sublicense through multiple tiers, to use, reproduce, distribute through multiple tiers, create derivative works of and publicly display, and archive, such Designs solely in connection with the production or provision of any product or service you request or to show you how your Designs would appear in and/or on Pixel Stitch’s products or services.</p>
                    <p>For example, when you place an order for custom apparel or provide your Designs for sale on a physical product, Pixel Stitch will prepare, manipulate (if necessary), and transmit the Designs for sale on merchandise, production, packaging, and shipment. In addition, you grant a nonexclusive, worldwide, transferable, royalty-free, sublicensable right to use, reproduce, publicly display, sell, and distribute the Designs in advertising, marketing, samples, and promotional materials to promote the Site and facilitate the sale of your product, in any media formats and through any media channels. Without limitation, this promotion, marketing, or advertising may consist of (i) display of your products; (ii) promotional "streams" of audio/video/photographic content on the Sites, (iii) Design placement in magazines, television shows, movies, and other media; and (iv) the promotion of the Sites through third-party feeds. </p>
                    <p>You also grant each user of the Site a non-exclusive license to access your Designs through the Site, and to use, reproduce, distribute, and display such Designs as permitted through the functionality of the Site under this Policy.</p>
                    <p>You represent and warrant that you own or otherwise possess all necessary rights concerning the use of the Designs, and that the Designs do not and will not:</p>
                    <ul>
                        <li>Infringe, misappropriate, use, or disclose without authorization or otherwise violate any copyright,
                            trademark, service mark, trade secret right, moral rights, or other intellectual property or other
                            property right of any third party</li>
                        <li>Invade or otherwise violate the privacy and/or publicity rights of any individual </li>
                        <li>Violate any laws, or possess or transmit fraudulent, threatening, abusive, libelous, defamatory,
                            obscene or otherwise objectionable materials, goods, services, schemes, or promotions, and the
                            content and material do not include contain malicious code, including but not limited to viruses,
                            trojan horses, worms, time bombs, cancelbots, or any other computer programming routines that
                            may damage, interfere with, surreptitiously intercept, or expropriate any system, program, data,
                            or personal information.</li>
                    </ul>
                    <p>You also represent that there are no outstanding disputes in connection with the property rights, intellectual property rights, or other rights in the Designs or any part of the Designs. </p>
                    <p>All Designs that you upload are automatically stored by Pixel Stitch. Pixel Stitch does not review any of your content and material before you upload them to the Site. As such, uploading your Designs to the Site does not mean that such Designs comply with this Policy and Content Guidelines. By uploading Designs to the Site, you assume full responsibility for all such Designs and compliance with this Policy.</p>
                    <p>You agree that any Designs submitted as part of an order for services may be reviewed for compliance with this Policy and Content Guidelines. Pixel Stitch may refuse to use any Designs and may suspend processing and shipping of any order relating to the Designs. Pixel Stitch and its designees reserve the right to demand confirmation from you in writing of all rights, authorizations, licenses, permissions, and consents owned or obtained by you (if any) concerning any Designs you upload to or transmit through the Sites. If you fail to provide Pixel Stitch with satisfactory confirmation upon request, Pixel Stitch reserves the right to remove or deny access to any or all of your Designs available via the Site and to suspend or terminate your account. In addition, you agree that Pixel Stitch may disclose any Designs and the circumstances surrounding their submission and use to any third party to provide the requested services, enforce this Policy, or comply with any legal obligations or governmental requests.</p>
                    <p>You consent to the use of your name, image, and likeness, and you have obtained the written consent, release, and/or permission of every identifiable individual who appears in any submitted Designs to use such individual's name, image, and likeness, for purposes of using and otherwise exploiting the Designs in the manner contemplated by this Policy, or, if any such identifiable individual is under the age of eighteen (18), you have obtained such written consent, release, and/or permission from such individual's parent or guardian (and you agree to provide Pixel Stitch with a copy of any such consents, releases, and/or permissions upon Pixel Stitch’s request).</p>
                    <p>You agree to indemnify and hold Pixel Stitch, our directors, officers, shareholders, employees, contractors, agents, representatives, affiliates, and third-party users harmless from and against any claims, causes of action, liabilities, damages, losses, expenses, and costs (including, without limitation, attorneys' fees) that arise directly or indirectly out of or from:</p>
                    <ul>
                        <li>Your violation of this Policy, or any other agreement with Pixel Stitch, any representation or warranty contained herein or therein or any applicable law; </li>
                        <li>Your Designs; </li>
                        <li>Your activities in connection with obtaining any products or services from Pixel Stitch; and/or</li>
                        <li>Any activity related to access to or use of your account by you or any other person.</li>
                    </ul>
                    <p>Designs that violate this Policy may be removed from the Site. Pixel Stitch is not responsible for and will have no liability for, the removal, non-removal, or loss of any Designs from its Site. Pixel Stitch recommends that you keep backup copies of your Designs in your personal storage system.</p>
                    <h2>Pixel Stitch Content</h2>
                    <p>Pixel Stitch’s Site contains graphics, photographs, images, document layouts, templates, artwork, text, fonts, software tools, and other information (referred to herein as “Pixel Stitch Content”).  The Site and all Pixel Stitch Content are the copyrighted property of Pixel
                        Stitch and/or its subsidiaries or the copyrighted
                        property of parties from whom Pixel Stitch has licensed such property.  All rights in the Sites and the Pixel Stitch Content are reserved worldwide. It is strictly prohibited to retain, copy, distribute, publish, or use any portion of the Pixel Stitch Content except as expressly allowed in this Policy.  Pixel Stitch reserves the right to add to, delete from, or modify any part of the Pixel Stitch Content at any time without prior notice. Any modifications to the Pixel Stitch Content remain the property of Pixel Stitch and its licensors.</p>
                    <p>The trade names, trademarks, and service marks owned by Pixel Stitch, whether registered or unregistered, may not be used in connection with any product or service that is not Pixel Stitch’s or in any manner that is likely to confuse. Nothing contained on the Site should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any of Pixel Stitch’s trade names, trademarks, or service marks without Pixel Stitch’s express prior written consent.  All trade names, trademarks, and service marks on the Sites not owned by Pixel Stitch are the property of their respective owners.</p>
                    <p>If you have any questions about this Policy, please contact us via the form located at www.pixel-stitch.io/contact or email us at <a href='mailto:contact@pixel-stitch.io'>contact@pixel-stitch.io</a>.</p>
                    <h2>Reporting Claims of Trademark and Copyright
                        Infringement</h2>
                    <p>Pixel Stitch takes claims of trademark and
                        infringement seriously.</p>
                    <h3>Trademark Infringement</h3>
                    <p>Pixel Stitch will respond to notices of alleged trademark infringement. If you believe that any materials accessible on or from the Sites infringe your trademark, you may request the removal of those materials from the Site by submitting a written notification to us via the contact form located on www.pixel-stitch.io/contact or sending an email to <a href='mailto:contact@pixel-stitch.io'>contact@pixel-stitch.io</a>. The written notice must include the following information:</p>
                    <ul>
                        <li>Your physical or electronic signature.</li>
                        <li>Identification of the trademark or trademarks you believe to have been infringed and information providing evidence of your ownership of the trademark.</li>
                        <li>Identification of the material you believe the infringing trademark appears in a sufficiently precise manner to allow RushOrderTees to locate that material.</li>
                        <li>Adequate information by which RushOrderTees can contact you (including your name, postal address, telephone number, and, if available, email address).</li>
                        <li>A statement that you have a good faith belief that use of the trademark is not authorized and information providing evidence of your ownership of the trademark.</li>
                        <li>A statement, is applicable, that you are authorized to act on behalf of the trademark owner.</li>
                    </ul>
                    <h3>Copyright
                        Infringement</h3>
                    <p>Pixel Stitch will respond to notices of alleged
                        infringement that comply with applicable law. If you believe that any materials accessible on or from the Site infringes your copyright,
                        you may request removal of those materials (or access to them) from the Site by submitting written notification to the contact form located on www.pixel-stitch.io/contact or sending an email to <a href='mailto:contact@pixel-stitch.io'>contact@pixel-stitch.io</a>.</p>
                    <p>Per the Online Copyright
                        Infringement Liability Limitation Act of the Digital Millennium
                        (17 U.S.C. § 512) ("DMCA"), the written notice (the "DMCA Notice") must include substantially the following:</p>
                    <ul>
                        <li>Your physical or electronic signature.</li>
                        <li>An identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access disabled.</li>
                        <li>Adequate information by which RushOrderTees can contact you (including your name, postal address, telephone number, and, if available, email address).</li>
                        <li>A statement under penalty of perjury by you that you have a good faith belief that the material identified above was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled.</li>
                        <li>A statement that you will consent to the jurisdiction of the Federal District Court for the judicial district in which your address is located (or if you reside outside the United States for any judicial district in which the Website may be found) and that you will accept service from the person (or an agent of that person) who provided the Sites with the complaint at issue.</li>
                    </ul>
                    <p>You must comply with all of the requirements of Section 512(c)(3) of the DMCA to ensure your DMCA Notice is effective.</p>
                    <p>Please be aware that if you knowingly materially misrepresent that material or activity on the Sites was removed or disabled by mistake or misidentification, you may be held liable for damages (including costs and attorneys' fees) under Section 512(f) of the DMCA.</p>
                    <h3>Counter Notification Procedures</h3>
                    <p>If you believe that material you posted on the Sites was removed or access to it was disabled by mistake or misidentification, you may file a counter notification with Pixel Stitch (a "Counter Notice") by submitting written notification to the contact form located on www.pixel-stitch.io/contact or sending an email to <a href='mailto:contact@pixel-stitch.io'>contact@pixel-stitch.io</a>. Under the DMCA, the Counter Notice must include substantially the following:</p>
                    <ul>
                        <li>Your physical or electronic signature.</li>
                        <li>An identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access disabled.</li>
                        <li>Adequate information by which we can contact you (including your name, postal address, telephone number, and, if available, email address).</li>
                        <li>A statement under penalty of perjury by you that you have a good faith belief that the material identified above was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled.</li>
                        <li>A statement that you will consent to the jurisdiction of the Federal District Court for the judicial district in which your address is located (or if you reside outside the United States for any judicial district in which the Website may be found) and that you will accept service from the person (or an agent of that person) who provided the Sites with the complaint at issue.</li>
                    </ul>
                    <p>The DMCA allows Pixel Stitch to restore the removed content if the party filing the original DMCA Notice does not file a court action against you within ten business days of receiving the copy of your Counter Notice.</p>
                    <p>Please be aware that if you knowingly and materially misrepresent that material or activity on the Sites was removed or disabled by mistake or misidentification, you may be held liable for damages (including costs and attorneys' fees) under Section 512(f) of the DMCA.</p>

                </div>
            </div>
        </div>
    );
}

export default IPPolicy;