import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import api from './api';

// Thunks
export const fetchProducts = createAsyncThunk('products/fetch', async () => {
  const res = await api.get('/products-info/');
  return res.data;
});

// Reducer
export const productsReducer = createSlice({
  name: 'products',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.pending, (state) => {
      state.data = {
        isLoading: false,
      };
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.data = {
        isLoading: false,
        payload: action.payload,
      };
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.data = {
        isLoading: false,
        error: action.error,
      };
    });
  },
}).reducer;

// same thing except for featured-designs
export const fetchFeaturedDesigns = createAsyncThunk('featured-designs/fetch', async () => {
  const res = await api.get('/featured-designs/');
  return res.data;
});

export const featuredDesignsReducer = createSlice({
  name: 'featuredDesigns',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFeaturedDesigns.pending, (state) => {
      state.data = {
        isLoading: false,
      };
    });
    builder.addCase(fetchFeaturedDesigns.fulfilled, (state, action) => {
      state.data = {
        isLoading: false,
        payload: action.payload,
      };
    });
    builder.addCase(fetchFeaturedDesigns.rejected, (state, action) => {
      state.data = {
        isLoading: false,
        error: action.error,
      };
    });
  },
}).reducer;