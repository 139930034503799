import React from 'react';
import Preview from "../../pixel-app/src/components/Preview";
import Mosaic from './Mosaic';
import { doubleGrid } from '../utils/doubleGrid';
import { List } from 'immutable';

const ExportRender = ({ product, frames, borderColor, columns, rows, img_url, add_flavor, mmPerStitch=2, svgClass }) => {

    function designPercentage(pixelsPerMm, mmPerCrossStitch, crossStitches, hatWidthPx = 1100) {
        const designWidthPx = pixelsPerMm * mmPerCrossStitch * crossStitches;
        const percentage = (designWidthPx / hatWidthPx) * 100;
        return percentage;
    }

    if (mmPerStitch > 2.5) {
        frames = List().push(doubleGrid(frames, columns, rows, borderColor, borderColor ? true : false));
        columns = columns * 2;
        rows = rows * 2;
    }
    
    // Example
    const widthPercentage = designPercentage(product.px_per_mm, mmPerStitch > 2.5 ? mmPerStitch / 2 : mmPerStitch, columns, product.img_width);

    return (
        <div className="position-relative">
            
            <img 
                className="card-img-top"
                src={img_url} />
            <Preview frames={frames}
                columns={columns}
                rows={rows}
                cellSize={1}
                duration={0}
                activeFrameIndex={0}
                animate={false}
                animationName=""
                type={'fancySVG'}
                stitchType={'cross-stitch'}
                skipWrapper
                svgClassName={`${svgClass} emb-drop-shadow`}
                width={`${widthPercentage}%`}/>
                {add_flavor && (
                    <div className='position-absolute'
                    style={{ zIndex: 1, color: 'black', fontSize: '4rem', right: '80px', bottom: '80px', borderRadius: '20px', overflow: 'hidden', border: '20px solid black'}}
                    ><Mosaic/></div>
                )
                    }
                
        </div>
    );
};

export default ExportRender;