import { combineReducers } from '@reduxjs/toolkit';

import { restCheckReducer as restCheck, embroideryReducer as embroidery } from './embroidery';
import { productsReducer as products, featuredDesignsReducer as featured } from './products';

export const rootReducer = combineReducers({
  restCheck,
  embroidery,
  products,
  featured
});
