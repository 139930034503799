import { saveAs } from 'file-saver';
import randomString from './random';

function fillCanvasWithFrame(canvas, frameInfo) {
  const { frame, cols, frameHeight, frameIdx } = frameInfo;
  const ctx = canvas;
  frame.get('grid').forEach((fillStyle, pixelIdx) => {
    if (!fillStyle) {
      return;
    }
    ctx.fillStyle = fillStyle;

    const col = pixelIdx % cols;
    const row = Math.floor(pixelIdx / cols);
    ctx.fillRect(
      col,
      row + frameHeight * frameIdx,
      1,
      1
    );
  });
  return ctx;
}

function renderImageToCanvas(type, canvasInfo, currentFrameInfo, frames) {
  const { canvas, canvasHeight, canvasWidth } = canvasInfo;
  const { frame, frameHeight, frameWidth } = currentFrameInfo;
  const cols = frameWidth;
  let ctx = canvas.getContext('2d');
  ctx.canvas.width = canvasWidth;
  ctx.canvas.height = canvasHeight;
  switch (type) {
    case 'spritesheet':
      frames.forEach((currentFrame, frameIdx) => {
        ctx = fillCanvasWithFrame(ctx, {
          frame: currentFrame,
          cols,
          frameHeight,
          frameIdx
        });
      });
      break;
    default:
      ctx = fillCanvasWithFrame(ctx, {
        frame,
        cols,
        frameHeight,
        frameIdx: 0
      });
      break;
  }
  return ctx.getImageData(0, 0, canvasWidth, canvasHeight).data;
}

const saveCanvasToDisk = (blob) => {
  saveAs(blob, `${randomString()}.png`);
};

function renderFrames(settings) {
  const {
    type,
    frames,
    duration,
    activeFrame,
    rows,
    columns,
    skipDownload=false
  } = settings;

  const frameWidth = columns;
  const frameHeight = rows;
  const canvasWidth = frameWidth;
  const canvasHeight =
    type === 'spritesheet' ? frameHeight * frames.size : frameHeight;

  const canvas = document.createElement('canvas');

  renderImageToCanvas(
    type,
    {
      canvas,
      canvasHeight,
      canvasWidth
    },
    {
      frame: activeFrame,
      frameHeight,
      frameWidth
    },
    frames
  );
  if (!skipDownload){
    canvas.toBlob(function(blob) {
      saveCanvasToDisk(blob);
    });
  }
  return canvas.toDataURL();
}

export default renderFrames;
