import React, {useState, useEffect} from "react";
import {useNavigate} from 'react-router-dom';
import {createSelectorHook, createDispatchHook} from 'react-redux';
import {Map} from "immutable";
import {mainStoreContext} from "../contexts";
import ExportRender from "../components/ExportRender";
import {fetchProducts} from '../store/products';

const Export = () => {
    const useSelector = createSelectorHook(mainStoreContext);
    const embroideryState = useSelector((state) => state.embroidery);
    const useDispatch = createDispatchHook(mainStoreContext);
    const dispatch = useDispatch();
    const productState = useSelector((state) => state.products);
    const [selectedProduct, setSelectedProduct] = useState("Hoodie");
    const grid = embroideryState.embroideryGrid;
    const borderColor = embroideryState.borderColor;
    const columns = embroideryState.columns;
    const rows = embroideryState.rows;
    const [flavor, setFlavor] = useState("showFlavor");
    // number of hoodie skus

    const [mmperStitch, setMmperStitch] = useState(2);
    // const [position, setPosition] = useState('left');

    useEffect(() => {
        if (! productState.products && ! productState.isLoading) {
            const action = fetchProducts();
            dispatch(action);
        }
    }, [productState.products, dispatch]);

    useEffect(() => {
        document.title = "PixelStitch - Export"
     }, []);

    const frames = Map().set(0, Map().set('grid', grid).set('borderColor', borderColor).set('borderActive', borderColor ? true : false));

    const productMap = {
        "Crewneck": "crewneck",
        "Hoodie": "hoodie",
        "Dad Hat": "hat",
        "Beanie": "beanie",
        "Trucker Hat": "trucker-hat",
        "Five Panel Hat": "five-panel",
    }

    return (
        <div className="fade-in-slide-right">
            {/* dropdown that sets mm per stitch to 1.5, 2, or 2.5 */}
            <select className="form-select" aria-label="Default select example"
                onChange={
                    (e) => setMmperStitch(e.target.value)
            }>
                <option value="1.5">1.5mm per stitch</option>
                <option value="2" selected>2mm per stitch</option>
                <option value="2.5">2.5mm per stitch</option>
                <option value="3">1.5mm per stitch x 2</option>
                <option value="4">2mm per stitch x 2</option>
                <option value="5">2.5mm per stitch x 2</option>
            </select>
            {/* dropdown that selects the product from productMap*/}
            <select className="form-select" aria-label="Default select example"
                onChange={
                    (e) => setSelectedProduct(e.target.value)
            }>
                <option value="Hoodie" selected>Hoodie</option>
                <option value="Crewneck">Crewneck</option>
                <option value="Dad Hat">Dad Hat</option>
                <option value="Beanie">Beanie</option>
                <option value="Trucker Hat">Trucker Hat</option>
                <option value="Five Panel Hat">Five Panel Hat</option>
            </select>
            {/* dropdown that sets position to left or center */}
            {/* <select className="form-select" aria-label="Default select example" onChange={(e) => setPosition(e.target.value)}>
            <option value="left" selected>Left</option>
            <option value="center">Center</option>
        </select> */}
            {/* dropdown that sets flavor text based on number of hoodie skus */}
            <select className="form-select"
                onChange={(e) => setFlavor(e.target.value)}
            >
                <option value="showFlavor" selected>Flavor</option>
                <option value="noFlavor">No Flavor</option>
            </select>
            <div className="container m-auto w-75">
                {
                grid && (productState ?. data ?. payload && productState ?. data ?. payload.map((product, index) => {
                    if (product.product_name == selectedProduct) {
                        const renders = [];
                        renders.push(product.colors.map((color, index) => {
                            return (
                                <ExportRender product={product}
                                    frames={frames}
                                    borderColor={borderColor}
                                    columns={columns}
                                    rows={rows}
                                    img_url={
                                        color.image_url
                                    }
                                    add_flavor={flavor === 'showFlavor' ? true : false}
                                    mmPerStitch={mmperStitch}
                                    svgClass={`${productMap[selectedProduct]}-svg`}
                                    key={index}
                                />
                            )
                        }))
                        // if the product is hoodie or crewneck, add a second render for center position
                        if (selectedProduct === "Hoodie" || selectedProduct === "Crewneck"){
                            renders.push(product.colors.map((color, index) => {
                                return (
                                    <ExportRender product={product}
                                        frames={frames}
                                        borderColor={borderColor}
                                        columns={columns}
                                        rows={rows}
                                        img_url={
                                            color.image_url
                                        }
                                        add_flavor={flavor === 'showFlavor' ? true : false}
                                        mmPerStitch={mmperStitch}
                                        svgClass={`${productMap[selectedProduct]}-center-svg`}
                                        key={index}
                                    />
                                )
                            }))
                        }
                        

                        // return renders
                        return renders.map((render, index) => {

                            return render

                        })

                    } else {
                        return null;
                    }

                }))
            } </div>
        </div>
    );
}

export default Export;
