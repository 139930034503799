import React from 'react';
import { connect, useSelector, createSelectorHook } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import Picker from './Picker';
import * as actionCreators from '../store/actions/actionCreators';
import { pixelStoreContext, mainStoreContext } from '../../../js/contexts';
import { MAX_GRID_COLUMNS, MAX_GRID_ROWS, ADMIN_GRID_ROWS, ADMIN_GRID_COLUMNS } from '../../../js/constants';

const PickerWrapperCol = styled.div`
  margin-bottom: 0.5rem;
`;

const PickerWrapperRow = styled.div`
  margin-bottom: 0rem;
`;

const Dimensions = props => {
  const useSelector = createSelectorHook(mainStoreContext);
  const isStaff = useSelector((state) => state.restCheck?.whoami?.payload?.is_staff);

  const changeDimensions = (gridProperty, behaviour) => {
    props.actions.changeDimensions(gridProperty, behaviour);
  };

  const { columns, rows } = props;

  return (
    <div className="dimensions">
      <PickerWrapperCol>
        <Picker type="columns" value={columns} action={changeDimensions} max={isStaff ? ADMIN_GRID_COLUMNS : MAX_GRID_COLUMNS} min={8}/>
      </PickerWrapperCol>
      <PickerWrapperRow>
        <Picker type="rows" value={rows} action={changeDimensions} max={isStaff ? ADMIN_GRID_ROWS : MAX_GRID_ROWS} min={8}/>
      </PickerWrapperRow>
    </div>
  );
};

const mapStateToProps = state => ({
  columns: state.present.getIn(['frames', 'columns']),
  rows: state.present.getIn(['frames', 'rows'])
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch)
});

const DimensionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { context: pixelStoreContext}
)(Dimensions);
export default DimensionsContainer;
